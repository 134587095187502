export const isMiles = (erpCode) => {
  return /^MILES(\D)+/i.test(erpCode);
};

export const isMilesAU = (erpCode) => {
  return /^MILESAU$/i.test(erpCode);
};

export const isMilesNZ = (erpCode) => {
  return /^MILESNZ$/i.test(erpCode);
};
