<template>
  <div class="row no-wrap items-center">
    <QIcon class="q-mr-xs icon" :name="informationIcon" size="xs" outline round color="red" />
    <p class="col-shrink q-mb-none text-body3 text-red error-text">
      {{ caption }}
    </p>
  </div>
</template>

<script>
import { QIcon, Icons } from 'eclipx.ui';

/**
 * This component displays a caption with an information icon
 */
export default {
  name: 'ErrorCaption',
  props: {
    caption: {
      type: String,
      required: true
    }
  },
  created() {
    this.informationIcon = Icons.evaInfo;
  },
  components: {
    QIcon
  }
};
</script>

<style lang="sass" scoped>
.icon
  flex-shrink: 0
</style>
