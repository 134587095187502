<template>
  <QDialog v-model="show" transition-show="scale" transition-hide="scale">
    <QCard>
      <QCardSection class="column items-center q-pa-lg">
        <div class="row justify-between items-center full-width no-wrap">
          <!-- <h6 class="q-ma-none text-secondary col-shrink">Oops! There seems to be an issue</h6> -->
          <h6 class="q-ma-none text-primary col-shrink">Oops! An error has occurred.</h6>
          <QBtn flat outline round color="grey-9" class="self-start" :icon="close" v-close-popup />
        </div>

        <!-- <p class="q-mb-none q-mt-md text-grey-9">
          An unexpected error has occurred, please try again and if the error persists, please contact support at
          <a class="text-secondary" :href="`mailto: ${contactEmail}`">
            {{ contactEmail }}
          </a>
          with details of the issue you are experiencing.
        </p> -->

        <p v-if="isMilesNZ" class="q-mb-none q-mt-md text-grey-9">
          Please try again and if the error continues contact our support team:
          <a class="text-primary" :href="`mailto: ${contactEmail}`">
            {{ contactEmail }}
          </a>
          or 0800 438 435 option 4.
        </p>
        <p v-else class="q-mb-none q-mt-md text-grey-9">
          <!-- <span v-if="message">{{message}}.</span>  -->
          Please try again and if the error persists, please email
          <a class="text-primary" :href="`mailto: ${contactEmail}`">
            {{ contactEmail }}
          </a>
        </p>
        
      </QCardSection>
      <QCardActions class="q-pb-lg q-pt-none q-px-lg" v-if="action">
        <QBtn
          outline
          class="q-px-sm q-py-xs text-body3"
          color="grey-7"
          label="Try Again"
          @click="tryAgain"
          :loading="actionLoading"
          :disabled="actionLoading"
        />
      </QCardActions>
      <QCardSection class="q-py-sm q-px-lg bg-grey-7">
        <p class="text-caption q-mb-none text-white">{{ timestamp }}</p>
      </QCardSection>
    </QCard>
  </QDialog>
</template>

<script>
import { QDialog, QCard, QCardSection, QCardActions, QBtn, Icons, ClosePopup } from 'eclipx.ui';
import { mapState } from 'vuex';
import { isMilesNZ } from '@/utils/is-miles';
export const GLOBAL_ERROR = 'GLOBAL_ERROR';

/**
 * This component displays a global error message as a Dialog which provides the ability to re try an action.
 * To invoke the UI, call:
 * @code this.$euiNotifications.emit({
            notification: GLOBAL_ERROR,
            action: () => {
              return;
            }
          });
 * passing an action param in the emit payload is optional and if omitted, will not display the action button.
 */
export default {
  name: 'GlobalError',
  data() {
    return {
      show: false,
      action: null,
      actionLoading: false,
      errorStack: 0,
      message: ''
    };
  },
  computed: {
    ...mapState({
      erpquote: (state) => state.quote.quote.erpquote,
      user: (state) => state.auth.user
    }),
    isMilesNZ() {
      const erpCode = this.selected?.erps[0]?.code || this.user?.organisation?.erps[0]?.code;
      return isMilesNZ(erpCode);
    },
    contactEmail: function () {
      return this.isMilesNZ ? 'online@fleetpartners.co.nz' : process.env.VUE_APP_SUPPORT_CONTACT;
    },
    timestamp: function(){
      return new Date().toISOString();
    }
  },
  watch: {
    show: function (value) {
      if (!value) {
        this.action = null;
      }
    }
  },
  methods: {
    tryAgain: async function () {
      // Perform the action
      this.actionLoading = true;
      await this.action();
      // Resolve the action loading state
      this.actionLoading = false;
      this.errorStack--;
      // Do not close the popup If there was an error emitted while the action was in progress
      if (!this.errorStack) {
        this.show = false;
      }
    }
  },
  created() {
    this.close = Icons.evaClose;
  },
  beforeCreate() {
    this.$euiNotifications.EventBus.$on(GLOBAL_ERROR, (params) => {
      this.$nextTick(function () {
        this.errorStack++;
        this.action = params.action;
        this.show = true;
        this.message = params.message;
      });
    });
  },
  directives: {
    ClosePopup
  },
  components: {
    QDialog,
    QCard,
    QCardSection,
    QCardActions,
    QBtn
  }
};
</script>
