<template>
  <QCardSection class="bg-grey-9 text-grey-1" :style="{ backgroundColor: quoteColour ? quoteColour + '!important' : '#424242' }">
    <div v-if="loading" class="flex justify-center q-pa-md">
      <QSpinner size="md" color="grey-3" />
    </div>
    <div v-else>
      <QCardSection
        v-if="
          (hasPrice && !pricingConfiguration.isItemisedPricingEnabled) ||
          (pricingConfiguration.isItemisedPricingEnabled && isQuoteReviewPage)
        "
        class="column justify-center text-center"
      >
        <span>Quote Ref: {{ quoteRef }}</span>
        <div>
          <h5 class="q-mt-md q-mb-xs column inline">
            {{ price.monthlyRentalNet | currency }}
          </h5>
          <span class="text-body3 text-grey-5"> (inc gst)</span>
        </div>
        <span class="text-body3 text-grey-5">{{ isMiles ? 'Terms' : 'Per Month' }}</span>
        <span class="text-body3 text-grey-5"> {{ erpquote.leaseTerm }} months - {{ isMiles ? totalKms : erpquote.kmsPerYear }}km </span>
      </QCardSection>
      <div v-else>
        <div class="row items-center q-pb-sm justify-between">
          <span class="text-bold text-body3">Terms</span>
          <span class="text-body3 text-grey-5" v-if="erpquote.leaseTerm && erpquote.kmsPerYear">
            {{ erpquote.leaseTerm }} months - {{ isMiles ? totalKms : erpquote.kmsPerYear }}km
          </span>
        </div>
        <div class="row items-center q-pb-sm justify-between">
          <div>
            <span class="text-bold text-body3">Estimated Cost</span>
            <span class="text-bold text-body4"> (inc gst)</span>
          </div>
          <div class="text-body3 text-grey-5">
            <span>{{
              (!pricingConfiguration.isItemisedPricingEnabled || (pricingConfiguration.isItemisedPricingEnabled && isQuoteReviewPage)) &&
              monthlyRentalNet | currency
            }}</span>
            <span v-if="monthlyRentalNet">/mo</span>
          </div>
        </div>
      </div>
      <QSeparator dark spaced />
      <QCardSection
        v-if="
          (hasPrice && !pricingConfiguration.isItemisedPricingEnabled) ||
          (pricingConfiguration.isItemisedPricingEnabled && isQuoteReviewPage)
        "
        class="column justify-center"
      >
        <span class="text-bold text-center">Your Driveaway Price</span>
        <div class="text-center">
          <span class="text-grey-5 text-subtitle1">
            {{ erpquote.itemisedPricing.calculatedDriveAwayPrice | currency }}
          </span>
        </div>
      </QCardSection>
      <div v-else class="row items-center q-pt-sm justify-between">
        <div>
          <span class="text-bold text-body3">Your Driveaway Price</span>
        </div>
        <span class="text-body3 text-grey-5">
          {{
            (!pricingConfiguration.isItemisedPricingEnabled || (pricingConfiguration.isItemisedPricingEnabled && isQuoteReviewPage)) &&
            onRoadCost | currency
          }}
        </span>
      </div>
      <div class="row items-center q-mt-xs justify-between">
        <span class="text-body4"> Comparable Weekly Estimate (inc gst) </span>
        <span class="text-body4 text-grey-5">
          {{
            (!pricingConfiguration.isItemisedPricingEnabled || (pricingConfiguration.isItemisedPricingEnabled && isQuoteReviewPage)) &&
            weeklyRentalNet | currency
          }}
        </span>
      </div>
    </div>
  </QCardSection>
</template>

<script>
import { isMiles } from '@/utils/is-miles';
import { QCardSection, QSeparator, QSpinner } from 'eclipx.ui';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'SummaryCardLease',
  props: {
    loading: Boolean,
    hasPrice: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      erpquote: (state) => state.quote.quote.erpquote,
      price: (state) => state.quote.quote.erpquote.price,
      selectedOrg: (state) => state.org.selectedOrg,
      user: (state) => state.auth.user
    }),
    ...mapGetters({
      dragonball: 'orgProfile/dragonball',
      pricingConfiguration: 'orgProfile/pricingConfiguration'
    }),
    quoteColour() {
      if (this.dragonball) return this.dragonball.quoteColour;
      else return null;
    },
    onRoadCost() {
      return this.price?.onRoadCost || null;
    },
    weeklyRentalNet() {
      return this.price?.weeklyRentalNet || null;
    },
    monthlyRentalNet() {
      return this.price?.monthlyRentalNet || null;
    },
    quoteRef() {
      return this.erpquote?.quoteRef?.milesQuoteRef || this.erpquote.erpQuoteRef;
    },
    isQuoteReviewPage() {
      return this.$route.name === 'quoteReview';
    },
    isMiles() {
      const erpCode = this.selectedOrg?.erps[0]?.code || this.user.organisation.erps[0].code;
      return isMiles(erpCode);
    },
    totalKms() {
      return this.erpquote?.kmsPerYear >= 0
        ? ((parseInt(this.erpquote?.leaseTerm) * parseInt(this.erpquote?.kmsPerYear)) / 12).toLocaleString()
        : '';
    }
  },
  components: {
    QSeparator,
    QCardSection,
    QSpinner
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped></style>
