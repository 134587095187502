<template>
  <QTabs
    align="justify"
    active-color="secondary"
    class="bg-white q-my-md rounded-borders text-grey-8 q-tabs--inset-indicator q-tabs--no-focus-helper"
  >
    <QRouteTab
      class="q-py-sm"
      :ripple="false"
      label="Log in Page"
      :to="{ name: 'white-label-auth0' }"
      v-if="$store.getters['auth/whiteLabelEnabled']"
    />
    <QRouteTab
      class="q-py-sm"
      :ripple="false"
      label="Quoting Portal"
      :to="{ name: 'white-label-dragonball-content' }"
      v-if="$store.getters['auth/whiteLabelEnabled']"
    />
    <QRouteTab
      class="q-py-sm"
      :ripple="false"
      label="Quote Calculator"
      :to="{ name: 'white-label-calculator-content' }"
      v-if="$store.getters['auth/whiteLabelEnabled']"
    />
    <QRouteTab
      class="q-py-sm"
      :ripple="false"
      label="Quote Estimate"
      :to="{ name: 'white-label-quote-content' }"
      v-if="$store.getters['auth/whiteLabelEnabled']"
    />
    <QRouteTab
      class="q-py-sm"
      :ripple="false"
      label="Credit Portal"
      :to="{ name: 'white-label-scoreboard-content' }"
      v-if="$store.getters['auth/whiteLabelEnabled']"
    />
    <QRouteTab class="q-py-sm" :ripple="false" label="Email Templates" :to="{ name: 'white-label-email-templates' }" />
  </QTabs>
</template>

<script>
import { QTabs, QRouteTab } from 'eclipx.ui';
import { mapState } from 'vuex';

export default {
  name: 'WhiteLabelTabs',
  props: {
    disabled: Boolean
  },
  computed: {
    features() {
      return this.$store.getters['auth/feature'];
    },
    dbManageAdmins() {
      return this.features && this.features.indexOf('DB::ManageAdmins') > -1;
    }
  },
  components: {
    QTabs,
    QRouteTab
  }
};
</script>
