<template>
  <div class="bg-white q-pa-lg" v-if="$store.getters['auth/whiteLabelEnabled']">
    <div class="bg-white q-pa-lg">
      <div class="row">
        <div class="col-xs-12 col-sm-3 col-md-4" style="padding: 25px">
          <h5>Customer Email Templates</h5>
          <br />
          <p>Select a template to customise your communication to customers.</p>
          <div>
            <div
              v-for="(item, index) in scoreboardItems"
              :key="index"
              @click="onSubjectClick('scoreboard', item.name)"
              style="padding: 10px; background-color: #e8e8e8; margin-bottom: 5px; cursor: pointer"
              :style="item.name === 'follow-up-primary1' ? { display: 'none' } : ''"
            >
              <span :class="selected == item.name ? 'text-secondary' : ''" :style="selected == item.name ? { fontWeight: 700 } : ''">
                {{ getTitle('scoreboard', item.name, item.subject) }}
              </span>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-9 col-md-8" style="padding: 25px">
          <h6>Template: {{ scoreboardTitle && scoreboardTitle.length ? scoreboardTitle[0].title : subject }}</h6>
          <p>
            Description:
            <span
              v-html="scoreboardDesc && scoreboardDesc.length ? scoreboardDesc[0].desc : 'Customise the email template details below.'"
            ></span>
          </p>

          <hr style="border-top: 0.5px solid lightgray; margin: 30px 0px" />
          <div class="row" style="width: 100%">
            <ImagePicker
              caption="Banner Logo"
              field="creditPortalBannerImage"
              info="Upload an image. This will sit across the top of the emails that are sent out to the customer.<br/><br/>Recommended size 520(w) x 217(h) px<br/>File format: PNG, JPG, GIF<br/>Max file size: 160KB"
              :imageProp="creditPortalBannerImage"
              @onImageChangeHandler="onImageChangeHandler"
            />

            <!-- <ImagePicker
              caption="Primary Image"
              field="creditPortalPrimaryImage"
              info="Upload an image. This will be positioned below the Banner Logo and across all emails.<br/><br/>Recommended aspect ratio 9:6 <br/> Recommended size 1200(w) x 800(h) px<br/>File format: PNG, JPG, GIF<br/>Max file size: 160KB"
              :imageProp="creditPortalPrimaryImage"
              @onImageChangeHandler="onImageChangeHandler"
            /> -->
          </div>

          <hr style="border-top: 0.5px solid lightgray; margin: 30px 0px" />

          <div class="row" style="width: 100%">
            <div class="q-pa-xs col-12 col-sm-12">
              <br />
              <label style="font-size: 12px">Subject Line:</label>
              <QInput
                v-model.lazy="subject"
                filled
                dense
                placeholder="Subject Line"
                @input="onInputChangeHandler"
                :disable="!this.hasFeature('DB::ManageAdmins')"
              />
              <br />
              <label style="font-size: 12px">Email Body:</label>
              <QEditor
                v-model.lazy="message"
                placeholder="Email Message"
                min-height="200px"
                :content-style="{ backgroundColor: '#0000000d' }"
                ref="editor_ref"
                @paste.native="(evt) => pasteCapture(evt)"
                :toolbar="[
                  [
                    {
                      label: $q.lang.editor.align,
                      icon: $q.iconSet.editor.align,
                      fixedLabel: true,
                      list: 'only-icons',
                      options: ['left', 'center', 'right', 'justify']
                    },
                    {
                      label: $q.lang.editor.align,
                      icon: $q.iconSet.editor.align,
                      fixedLabel: true,
                      options: ['left', 'center', 'right', 'justify']
                    }
                  ],
                  ['bold', 'italic', 'strike', 'underline', 'subscript', 'superscript'],
                  ['token', 'hr', 'link', 'custom_btn'],
                  ['print', 'fullscreen'],
                  [
                    {
                      label: $q.lang.editor.formatting,
                      icon: $q.iconSet.editor.formatting,
                      list: 'no-icons',
                      options: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'code']
                    },
                    {
                      label: $q.lang.editor.fontSize,
                      icon: $q.iconSet.editor.fontSize,
                      fixedLabel: true,
                      fixedIcon: true,
                      list: 'no-icons',
                      options: ['size-1', 'size-2', 'size-3', 'size-4', 'size-5', 'size-6', 'size-7']
                    },
                    {
                      label: $q.lang.editor.defaultFont,
                      icon: $q.iconSet.editor.font,
                      fixedIcon: true,
                      list: 'no-icons',
                      options: [
                        'default_font',
                        'arial',
                        'arial_black',
                        'comic_sans',
                        'courier_new',
                        'impact',
                        'lucida_grande',
                        'times_new_roman',
                        'verdana'
                      ]
                    },
                    'removeFormat'
                  ],
                  ['quote', 'unordered', 'ordered', 'outdent', 'indent'],

                  ['undo', 'redo'],
                  ['viewsource']
                ]"
                :fonts="{
                  arial: 'Arial',
                  arial_black: 'Arial Black',
                  comic_sans: 'Comic Sans MS',
                  courier_new: 'Courier New',
                  impact: 'Impact',
                  lucida_grande: 'Lucida Grande',
                  times_new_roman: 'Times New Roman',
                  verdana: 'Verdana'
                }"
                @input="onEditorChangeHandler"
                :disable="!this.hasFeature('DB::ManageAdmins')"
              />
              <br />
              <p style="font-size: 12px">
                Please use reference the following references to customise the fields: [RecipientName], [SenderName], [CompanyName], [Link],
                [QuoteNumber], [CreditLimit]
              </p>
            </div>
          </div>
        </div>
      </div>

      <hr style="border-top: 0.5px solid lightgray; margin: 30px 0px" />

      <div class="row">
        <div class="col-xs-12 col-sm-3 col-md-4" style="padding: 25px">
          <h5>System Emails</h5>
          <br />
          <p>These are system email templates sent for your quoting account.</p>
          <div>
            <div
              v-for="(item, index) in dragonballItems"
              :key="index"
              @click="onSubjectClick('dragonball', item.name)"
              style="padding: 10px; background-color: #e8e8e8; margin-bottom: 5px; cursor: pointer"
            >
              <span :class="selected == item.name ? 'text-secondary' : ''" :style="selected == item.name ? { fontWeight: 700 } : ''">
                {{ getTitle('dragonball', item.name, item.subject) }}
              </span>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-9 col-md-8" style="padding: 25px">
          <h6>Template: {{ dragonballTitle && dragonballTitle.length ? dragonballTitle[0].title : subject2 }}</h6>
          <p>
            Description:
            <span
              v-html="dragonballDesc && dragonballDesc.length ? dragonballDesc[0].desc : 'Customise the email template details below.'"
            ></span>
          </p>

          <hr style="border-top: 0.5px solid lightgray; margin: 30px 0px" />
          <div class="row" style="width: 100%">
            <ImagePicker
              caption="Banner Logo"
              field="quotePortalBannerImage"
              info="Upload an image. This will sit across the top of the emails that are sent out to the customer.<br/><br/>Recommended size 520(w) x 217(h) px<br/>File format: PNG, JPG, GIF<br/>Max file size: 160KB"
              :imageProp="quotePortalBannerImage"
              @onImageChangeHandler="onImageChangeHandler"
            />

            <!-- <ImagePicker
              caption="Primary Image"
              field="quotePortalPrimaryImage"
              info="Upload an image. This will be positioned below the Banner Logo and across all emails.<br/><br/>Recommended aspect ratio 9:6 <br/> Recommended size 1200(w) x 800(h) px<br/>File format: PNG, JPG, GIF<br/>Max file size: 160KB"
              :imageProp="quotePortalPrimaryImage"
              @onImageChangeHandler="onImageChangeHandler"
            /> -->
          </div>
          <hr style="border-top: 0.5px solid lightgray; margin: 30px 0px" />

          <div class="row" style="width: 100%">
            <div class="q-pa-xs col-12 col-sm-12">
              <br />
              <label style="font-size: 12px">Subject Line:</label>
              <QInput
                v-model.lazy="subject2"
                filled
                dense
                placeholder="Subject Line"
                @input="onInputChangeHandler"
                :disable="!this.hasFeature('DB::ManageAdmins')"
              />
              <br />
              <label style="font-size: 12px">Email Body:</label>
              <QEditor
                v-model.lazy="message2"
                placeholder="Email Message"
                min-height="200px"
                :content-style="{ backgroundColor: '#0000000d' }"
                ref="editor_ref2"
                @paste.native="(evt) => pasteCapture2(evt)"
                :toolbar="[
                  [
                    {
                      label: $q.lang.editor.align,
                      icon: $q.iconSet.editor.align,
                      fixedLabel: true,
                      list: 'only-icons',
                      options: ['left', 'center', 'right', 'justify']
                    },
                    {
                      label: $q.lang.editor.align,
                      icon: $q.iconSet.editor.align,
                      fixedLabel: true,
                      options: ['left', 'center', 'right', 'justify']
                    }
                  ],
                  ['bold', 'italic', 'strike', 'underline', 'subscript', 'superscript'],
                  ['token', 'hr', 'link', 'custom_btn'],
                  ['print', 'fullscreen'],
                  [
                    {
                      label: $q.lang.editor.formatting,
                      icon: $q.iconSet.editor.formatting,
                      list: 'no-icons',
                      options: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'code']
                    },
                    {
                      label: $q.lang.editor.fontSize,
                      icon: $q.iconSet.editor.fontSize,
                      fixedLabel: true,
                      fixedIcon: true,
                      list: 'no-icons',
                      options: ['size-1', 'size-2', 'size-3', 'size-4', 'size-5', 'size-6', 'size-7']
                    },
                    {
                      label: $q.lang.editor.defaultFont,
                      icon: $q.iconSet.editor.font,
                      fixedIcon: true,
                      list: 'no-icons',
                      options: [
                        'default_font',
                        'arial',
                        'arial_black',
                        'comic_sans',
                        'courier_new',
                        'impact',
                        'lucida_grande',
                        'times_new_roman',
                        'verdana'
                      ]
                    },
                    'removeFormat'
                  ],
                  ['quote', 'unordered', 'ordered', 'outdent', 'indent'],

                  ['undo', 'redo'],
                  ['viewsource']
                ]"
                :fonts="{
                  arial: 'Arial',
                  arial_black: 'Arial Black',
                  comic_sans: 'Comic Sans MS',
                  courier_new: 'Courier New',
                  impact: 'Impact',
                  lucida_grande: 'Lucida Grande',
                  times_new_roman: 'Times New Roman',
                  verdana: 'Verdana'
                }"
                @input="onEditorChangeHandler"
                :disable="!this.hasFeature('DB::ManageAdmins')"
              />
              <br />
              <p style="font-size: 12px">
                Please use reference the following references to customise the fields: [RecipientName], [SenderName], [CompanyName], [Link],
                [QuoteNumber]
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { QInput, QEditor } from 'eclipx.ui';
import ImagePicker from './Modules/ImagePicker.vue';

export default {
  name: 'EmailTemplates',
  data() {
    return {
      items: {},
      selected: '',
      selectedType: '',
      subject: '',
      message: '',
      subject2: '',
      message2: '',
      scoreboardItemSortOrder: [
        'review-quote',
        'reassign-quote',
        'review-quote-secondary',
        'refer-introducer',
        'approved-introducer',
        'approved-primary',
        'approved-secondary',
        'approved-secondary-wet',
        'wet-signature',
        'completed-mla-introducer',
        'completed-mla-officers',
        'reset-email',
        'expired-introducer',
        'expired-primary',
        'follow-up-primary',
        'calculator-invitation'
      ],
      dragonballItemSortOrder: [
        'welcome',
        'access-revoked',
        'welcome-reinstated',
        'password-reset',
        'white-label-enabled',
        'white-label-save',
        'white-label-deploy',
        'white-label-disabled'
      ],
      scoreboardItems: [],
      dragonballItems: [],
      scoreboardArr: [
        {
          name: 'review-quote',
          title: '1. Customer - Invite to Credit Portal',
          desc: 'To invite a customer to complete their lease application, an email is dispatched with the below content.'
        },
        {
          name: 'reassign-quote',
          title: '2. Authorised Officer (1) - Invite to complete lease application',
          desc: 'In instances where a user transfers control of the lease application to an Authorised Officer, an email invite is dispatched with the below content.'
        },
        {
          name: 'review-quote-secondary',
          title: '3. Authorised Officer (2) - Invite to complete ID verification',
          desc: 'To  invite Authorised Officer (2) for ID verification, an email is dispatched with the below content.'
        },
        {
          name: 'refer-introducer',
          title: '4. Introducer - Notify of referred credit assessment',
          desc: 'Where a customer`s credit application is referred to the credit team for manual assessment, the relevant introducer is notified via an email with the below content. '
        },
        {
          name: 'approved-introducer',
          title: '5. Introducer - Notify of approved credit assessment',
          desc: 'If a customer`s credit application is approved, the relevant introducer is notified via an email with the below content. '
        },
        {
          name: 'approved-primary',
          title: '6. Authorised Officer (1) - Invite  to sign lease documentation',
          desc: 'Once a customer`s credit application is approved, Authorised Officer (1) is invited to sign the lease documentation via an email with the content below.'
        },
        {
          name: 'approved-secondary',
          title: '7. Authorised Officer (2) - Invite  to sign lease documentation (Digital Signature) ',
          desc: 'Once a customer`s credit application is approved, Authorised Officer (2) is invited to sign the lease documentation via an email with the content below.'
        },
        {
          name: 'approved-secondary-wet',
          title: '8. Authorised Officer (2) - Notify of credit approval (Wet Signature)',
          desc: 'Once a customer`s credit application is approved, Authorised Officer (2) is notified via an email with the content below. '
        },
        {
          name: 'wet-signature',
          title: '9. Authorised Officer (1) - Email delivery of lease documents for signing',
          desc: 'Where Authorised Officer (1) elects to email all/individual lease documents for signing, it is dispatched with the below content.'
        },
        {
          name: 'completed-mla-introducer',
          title: '10. Introducer - Notify that lease application is complete',
          desc: 'Once a customer`s lease application is complete, the relevant introducer is notified via an email with the below content.'
        },
        {
          name: 'completed-mla-officers',
          title: '11. Authorised Officer (1 & 2) - Notify that lease application is complete',
          desc: 'Once a customer`s lease application is complete, Authorised Officer (1) and (2) are notified via an email with the below content. '
        },
        {
          name: 'reset-email',
          title: '12. Any user - Reset password',
          desc: 'Where a user elects to reset their password, an email is dispatched with the below content.'
        },
        {
          name: 'expired-introducer',
          title: '15. Introducer - Notify of lease application closure',
          desc: 'Where a lease application isn`t complete within 60 days, the customer`s Credit Portal access is automatically removed and the relevant introducer is notified via an email with the below content. '
        },
        {
          name: 'expired-primary',
          title: '16. Customer - Notify of lease application closure',
          desc: 'Where a lease application isn`t complete within 60 days, the customer`s Credit Portal access is automatically removed and they`re notified via an email with the below content. '
        },
        {
          name: 'follow-up-primary',
          title: '17. Customer - Reminder to complete lease application',
          desc: 'The customer will receive an email reminder (with the below content) to complete their lease application:<br/>- when they haven`t logged into the Credit Portal 1 day after receiving the inital invite<br/>- for every 3 days of inactivity.'
        },
        {
          name: 'calculator-invitation',
          title: '18.  Customer - Invite to secure Calculator site',
          desc: 'To invite a customer to their calculator, an email is dispatched with the below content.'
        }
      ],
      dragonballArr: [
        {
          name: 'welcome',
          title: '1. Any user - Invite to Quoting Portal',
          desc: 'To invite a new user to the Quoting Portal, an email is dispatched with the below content.'
        },
        {
          name: 'access-revoked',
          title: '2. Any user - Notify of access removal',
          desc: 'If Quoting Portal access has been removed for a user, they`re notified via an email with the below content. '
        },
        {
          name: 'welcome-reinstated',
          title: '3. Any user - Notify reinstated access',
          desc: 'If Quoting Portal access has been reinstated for a user, they`re notified via an email with the below content. '
        },
        {
          name: 'password-reset',
          title: '4. Any user - Reset password',
          desc: 'Where an Eclipx or Introducer Admin triggers a password reset for a Quoting Portal user,  an email is dispatched to that user with the below content.'
        },
        {
          name: 'white-label-enabled',
          title: '5. Introducer Admin - Invite to Brand Config',
          desc: 'Once White Label Experience is enabled for an Introducer, an email is dispatched with the below content to invite the nominated Introducer Admin to configure their company`s branding.'
        },
        {
          name: 'white-label-save',
          title: '6. Eclipx - Notify of pending configuration approval',
          desc: 'Once an Introducer Admin has submitted their company`s branding configuration or later updates to it, an email is dispatched with the below content to the Eclipx distribution list notifying of its pending approval.'
        },
        {
          name: 'white-label-deploy',
          title: '7. Introducer Admin - Notify of approved configuration',
          desc: 'Once the branding configuration or updates to it are approved by Eclipx, the nominated Introducer Admin is notified via email with the below content.'
        },
        {
          name: 'white-label-disabled',
          title: '8. Introducer Admin - Notify of disabled white label experience',
          desc: 'Once White Label Experience is disabled for an Introducer, the nominated Introducer Admin is notified via email with the below content.'
        }
      ],
      scoreBoardDefaultValues: [
        {
          name: 'calculator-invitation',
          subject: 'Welcome to the Calculator',
          message: `Hi [RecipientName] </br> </br>
                          Welcome to your online account where you can search for more vehicles and apply for credit.</br> </br>
                          Link: [Link] </br> </br>
                          Regards </br> </br>
                          [CompanyName]`
        }
      ],
      scoreboardTitle: '',
      scoreboardDesc: '',
      dragonballTitle: '',
      dragonballDesc: '',
      creditPortalBannerImage: null,
      quotPortalBannerImage: null
      /*
      creditPortalPrimaryImage: null,
      quotePortalPrimaryImage: null,
      */
    };
  },
  computed: {
    ...mapGetters({
      email: 'whiteLabel/email',
      hasFeature: 'auth/hasFeature'
    })
  },
  created() {
    this.items = this.email;

    for (var i = 0; i < this.scoreboardItemSortOrder.length; i++) {
      this.scoreboardItems.push({ ...this.items['scoreboard'][this.scoreboardItemSortOrder[i]], name: this.scoreboardItemSortOrder[i] });
    }

    for (var i = 0; i < this.dragonballItemSortOrder.length; i++) {
      this.dragonballItems.push({ ...this.items['dragonball'][this.dragonballItemSortOrder[i]], name: this.dragonballItemSortOrder[i] });
    }
    this.creditPortalBannerImage = this.email.scoreboard.creditPortalBannerImage;
    this.quotePortalBannerImage = this.email.dragonball.quotePortalBannerImage;

    /*this.creditPortalPrimaryImage = this.email.scoreboard.creditPortalPrimaryImage;
    this.quotePortalPrimaryImage = this.email.dragonball.quotePortalPrimaryImage;*/
  },
  mounted() {},
  methods: {
    ...mapMutations({
      updateValueStatusEmail: 'whiteLabel/updateValueStatusEmail',
      updateEmailBannerImage: 'whiteLabel/updateEmailBannerImage'
    }),

    pasteCapture(evt) {
      // Let inputs do their thing, so we don't break pasting of links.
      if (evt.target.nodeName === 'INPUT') return;
      let text, onPasteStripFormattingIEPaste;
      evt.preventDefault();
      if (evt.originalEvent && evt.originalEvent.clipboardData.getData) {
        text = evt.originalEvent.clipboardData.getData('text/plain');
        this.$refs.editor_ref.runCmd('insertText', text);
      } else if (evt.clipboardData && evt.clipboardData.getData) {
        text = evt.clipboardData.getData('text/plain');
        this.$refs.editor_ref.runCmd('insertText', text);
      } else if (window.clipboardData && window.clipboardData.getData) {
        if (!onPasteStripFormattingIEPaste) {
          onPasteStripFormattingIEPaste = true;
          this.$refs.editor_ref.runCmd('ms-pasteTextOnly', text);
        }
        onPasteStripFormattingIEPaste = false;
      }
    },
    pasteCapture2(evt) {
      // Let inputs do their thing, so we don't break pasting of links.
      if (evt.target.nodeName === 'INPUT') return;
      let text, onPasteStripFormattingIEPaste;
      evt.preventDefault();
      if (evt.originalEvent && evt.originalEvent.clipboardData.getData) {
        text = evt.originalEvent.clipboardData.getData('text/plain');
        this.$refs.editor_ref2.runCmd('insertText', text);
      } else if (evt.clipboardData && evt.clipboardData.getData) {
        text = evt.clipboardData.getData('text/plain');
        this.$refs.editor_ref2.runCmd('insertText', text);
      } else if (window.clipboardData && window.clipboardData.getData) {
        if (!onPasteStripFormattingIEPaste) {
          onPasteStripFormattingIEPaste = true;
          this.$refs.editor_ref2.runCmd('ms-pasteTextOnly', text);
        }
        onPasteStripFormattingIEPaste = false;
      }
    },
    onSubjectClick: function (type, name) {
      this.selected = name;
      this.selectedType = type;
      if (type === 'scoreboard') {
        this.subject = this.items[type][name]?.subject || this.getDefaultValue(type, name, 'subject');
        this.message = this.items[type][name]?.message || this.getDefaultValue(type, name, 'message');
        this.scoreboardTitle = this.scoreboardArr.filter((item) => item.name === name);
        this.scoreboardDesc = this.scoreboardArr.filter((item) => item.name === name);
      } else {
        this.subject2 = this.items[type][name].subject;
        this.message2 = this.items[type][name].message;
        this.dragonballTitle = this.dragonballArr.filter((item) => item.name === name);
        this.dragonballDesc = this.dragonballArr.filter((item) => item.name === name);
      }
    },
    getDefaultValue: function (type, name, key) {
      let defaultValues = {};
      if (type === 'scoreboard') {
        defaultValues = this.scoreBoardDefaultValues;
      }
      const item = this.scoreBoardDefaultValues.find((item) => item.name === name);
      if (item === undefined || item[key] === undefined) {
        return '';
      }
      return item[key];
    },
    onInputChangeHandler: function () {
      this.updateValueStatusEmail({
        object: 'email',
        type: this.selectedType,
        type2: this.selected,
        variable: 'subject',
        value: this.selectedType === 'scoreboard' ? this.subject : this.subject2
      });
    },
    onEditorChangeHandler: function (value) {
      this.updateValueStatusEmail({
        object: 'email',
        type: this.selectedType,
        type2: this.selected,
        variable: 'message',
        value: value
      });
    },
    getTitle: function (type, name, defaultVal) {
      var item;
      if (type === 'scoreboard') item = this.scoreboardArr.filter((item) => item.name === name);
      else item = this.dragonballArr.filter((item) => item.name === name);

      return item && item.length ? item[0].title : defaultVal;
    },
    onImageChangeHandler: function (image, field) {
      let type;
      if (field == 'creditPortalBannerImage') {
        type = 'scoreboard';
        this[field] = image;
      } else {
        type = 'dragonball';
        this[field] = image;
      }
      this.updateEmailBannerImage({ object: 'email', type, field, value: image });
    }
  },
  components: {
    QInput,
    QEditor,
    ImagePicker
  }
};
</script>
