<template>
  <div class="row">
    <div class="q-pa-xs col-12 col-sm-3">
      <QInput
        :type="'text'"
        @input="onInput"
        v-model="menuTitle"
        label-slot filled>
        <template v-slot:label>
            <div class="row items-center all-pointer-events">
              Menu Title
            </div>
          </template>
      </QInput>
    </div>
    <div class="q-pa-xs col-12 col-sm-9">
      <QInput
        :type="'url'"
        @input="onInput"
        v-model="menuUrl"
        label-slot filled>
        <template v-slot:label>
            <div class="row items-center all-pointer-events">
              Menu URL
            </div>
          </template>
      </QInput>
    </div>
  </div>
</template>

<script>
import { QInput } from 'quasar';
export default {
  name: 'Menu',
  props:['menu'],
  data: function(){
    return {
      menuTitle: '',
      menuUrl: ''
    }
  },
  mounted(){
    if(this.menu !== undefined){
      const {menuTitle, menuUrl} = this.menu;
      this.menuTitle = menuTitle;
      this.menuUrl = menuUrl;
    }
  },
  methods:{
    onInput: function (value) {
      return this.$emit('menuInput',{
        menuTitle: this.menuTitle,
        menuUrl: this.menuUrl
      });
    }
  
  },
  components: {
    QInput
  }
};
</script>

<style lang="sass" scoped>
.gif-loading
  padding-bottom: 100px
  margin-top: -30px
</style>
