<template>
  <div>
    <h6 class="q-mt-none q-mb-md text-grey-9">Summary</h6>
    <ReviewQuoteCar
      :image="variant.imageUrl"
      :trimImage="variant.trimImageUrl"
      :baseImage="variant.baseImageUrl"
      :title="`${make.name} ${model.name} ${variant.trim && variant.trim.length > 1 ? variant.trim : ''}`"
      :description="variant.derivative"
      :fuel-type="variant.fuelType"
      :transmission="variant.transmission"
      :engine="variant.engineDescription"
      :driveTrain="variant.driveTrain"
      :seats="variant.seats"
    />
    <QSeparator class="q-my-md" />
    <ReviewQuoteColourAndExtras :disabled="isExpired || hasRequestedPricing" />
    <QSeparator class="q-my-md" />
    <ReviewQuoteOrderDetails :disabled="isExpired" />
    <QSeparator class="q-my-md" />
    <div class="row q-col-gutter-md q-mt-md">
      <div class="col-6" v-if="getInclusionsExclusions.inclusions.length">
        <p class="q-mb-md text-weight-bold text-grey-9">Inclusions</p>
        <div class="row no-wrap" v-for="inclusion in getInclusionsExclusions.inclusions" :key="inclusion">
          <QIcon class="q-mr-sm icon-spacer" :name="checkmark" size="1rem" color="green-8" />
          <span class="text-body3 text-grey-7">{{ inclusion }}</span>
        </div>
      </div>
      <div class="col-6" v-if="getInclusionsExclusions.exclusions.length">
        <p class="q-mb-md text-weight-bold text-grey-9">Exclusions</p>
        <div class="row no-wrap" v-for="exclusion in getInclusionsExclusions.exclusions" :key="exclusion">
          <QIcon class="q-mr-sm icon-spacer" :name="close" size="1rem" color="grey-7" />
          <span class="text-body3 text-grey-7">{{ exclusion }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QSeparator, Icons, QIcon } from 'eclipx.ui';
import { mapState, mapGetters } from 'vuex';
import ReviewQuoteCar from '@/components/ReviewQuote/ReviewQuoteCar';
import ReviewQuoteColourAndExtras from '@/components/ReviewQuote/ReviewQuoteColourAndExtras';
import ReviewQuoteOrderDetails from '@/components/ReviewQuote/ReviewQuoteOrderDetails';

export default {
  name: 'Review',
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapState({
      make: (state) => state.quote.quote.erpquote.make,
      variant: (state) => state.quote.quote.erpquote.variant,
      model: (state) => state.quote.quote.erpquote.model
    }),
    ...mapGetters({
      isExpired: 'quote/isExpired',
      hasRequestedPricing: 'quote/hasRequestedPricing',
      getInclusionsExclusions: 'quote/getInclusionsExclusions'
    })
  },
  created() {
    // If the prior page is invalid, go to the first incomplete page
    if (!this.$store.getters['quote/quoteCustomerDetailsValid']) {
      this.$router.replace({
        name: this.$store.getters['quote/firstIncompletePage'],
        params: { quoteId: this.$route.params.quoteId }
      });
    }

    this.checkmark = Icons.evaCheckmark;
    this.close = Icons.evaClose;
  },
  components: {
    QSeparator,
    QIcon,
    ReviewQuoteCar,
    ReviewQuoteColourAndExtras,
    ReviewQuoteOrderDetails
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
.icon-spacer
    margin-top: 2px
</style>
