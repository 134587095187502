<template>
  <main class="q-page column q-pa-lg">
    <h6 class="q-mt-none q-mb-md text-weight-bold text-grey-9">Edit Organisation</h6>
    <OrganisationTabs :orgId="routeId" />
    <div v-if="loading || this.$apollo.queries.getOrgById.loading" class="flex justify-center q-pa-md">
      <QSpinner size="md" color="grey-7" />
    </div>
    <div v-else class="row q-gutter-md">
      <div class="col q-pa-lg self-start q-card q-card--bordered">
        <div style="max-width: 400px">
          <QForm>
            <h6 class="q-mt-none q-mb-none text-grey-8">Organisation Details</h6>
            <div class="q-mt-md">
              <label class="text-body3" for="name">Name:</label><br />
              <QInput v-model="getOrgById.name" disable filled maxlength="50" color="secondary" dense hide-bottom-space clearable />
            </div>
            <div class="q-mt-md">
              <label class="text-body3" for="abn">{{ isMilesNZ ? 'NZBN:' : 'ABN:' }}</label
              ><br />
              <QInput v-model="getOrgById.abn" disable filled maxlength="50" color="secondary" dense hide-bottom-space clearable />
            </div>
            <!-- <div class="q-mt-md">
              <label class="text-body3" for="image">Image: </label><br />
              <QFile color="teal" filled @input="upload" label="Upload Logo" accept=".jpg, image/*" />
              <QImg
                :src="organisationImageLoaded"
                alt="Org Image"
                label="Upload Logo"
                spinner-color="white"
                style="height: 140px; max-width: 150px"
              />
            </div> -->
            <div class="q-mt-lg">
              <QBtn
                class="q-mr-sm"
                :label="'Save'"
                color="secondary"
                :loading="saving"
                :disable="!uploadedFileUUID"
                :ripple="false"
                @click="save"
              />
              <QBtn class="q-mr-sm" :label="'Cancel'" color="secondary" :ripple="false" @click="cancel" />
            </div>
          </QForm>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { QBtn, QForm, QInput, QSpinner } from 'eclipx.ui';
import { required } from 'vuelidate/lib/validators';
import { mapState } from 'vuex';
import { GLOBAL_ERROR } from '../../components/GlobalError/GlobalError';
import UpdateOrgImage from '../../graphql/mutations/MutateOrgImage';
import UploadImage from '../../graphql/mutations/UploadOrgImage';
import QueryImageByUUID from '../../graphql/queries/QueryImageByUUID';
import QueryOrgById from '../../graphql/queries/QueryOrganisationById';
import appConfig from '../../utils/app-config';
import OrganisationTabs from './OrganisationTabs';

export default {
  name: 'EditOrganisation',
  data() {
    return {
      saving: false,
      loading: false,
      uploadedFileUUID: null,
      getOrgById: {},
      getFileByUUID: {}
    };
  },
  validations: {
    name: { required },
    abn: { required }
  },
  computed: {
    ...mapState({
      user: (state) => {
        return state.auth.user;
      }
    }),
    isDBEclipxAdmin() {
      return this.hasRole('DB Eclipx Admin') || this.hasRole('DB Eclipx User');
    },
    isMilesNZ() {
      return this.getOrgById?.erps[0]?.code === 'MILESNZ';
    },
    hasRole() {
      return this.$store.getters['auth/hasRole'];
    },
    organisationImageLoaded() {
      return this.uploadedFileUUID ? `${appConfig.IMAGE_ASSETS_URI}/${this.uploadedFileUUID}` : this.getFileByUUID?.signedUrl;
    },
    routeId() {
      return this.$route.params.orgId;
    }
  },
  apollo: {
    getOrgById: {
      query: QueryOrgById,
      fetchPolicy: 'network-only',
      variables() {
        return {
          id: this.routeId
        };
      },
      error: function (error) {
        if (error) {
          // eslint-disable-next-line
          console.log(error);
          this.$euiNotifications.emit({
            notification: GLOBAL_ERROR,
            action: () => {
              return this.$apollo.queries.getOrgById.refresh();
            }
          });
        }
      }
    },
    getFileByUUID: {
      query: QueryImageByUUID,
      fetchPolicy: 'network-only',
      variables() {
        return {
          id: this.routeId
        };
      }
    }
  },
  methods: {
    uploadImage: async function (file, signedUrl, uuid) {
      // post form data
      const xhr = new XMLHttpRequest();
      // create and send the reqeust
      xhr.open('PUT', signedUrl);
      xhr.setRequestHeader('Content-Type', file.type);
      xhr.send(file);
      let self = this;
      xhr.onload = function () {
        self.uploadedFileUUID = uuid;
      };
    },
    upload: async function (file) {
      this.loading = true;
      await this.$apollo
        .mutate({
          mutation: UploadImage,
          variables: {
            file: file
          }
        })
        .then((data) => {
          this.uploadImage(file, data.data.upload.uploadOrgImage.signedUrl, data.data.upload.uploadOrgImage.uuid);
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          let gqlError = error.graphQLErrors[0];
          this.error = gqlError ? gqlError.message : 'Error';
        });
    },
    save: async function () {
      this.loading = true;
      await this.$apollo
        .mutate({
          mutation: UpdateOrgImage,
          variables: {
            orgId: this.routeId,
            UUID: this.uploadedFileUUID
          }
        })
        .then((data) => {
          this.loading = false;
          this.$router.push({
            name: 'organisations'
          });
        })
        .catch((error) => {
          this.loading = false;
          let gqlError = error.graphQLErrors[0];
          this.error = gqlError ? gqlError.message : 'Error';
        });
    },
    cancel: function () {
      this.$router.push({ name: 'organisations' });
    }
  },
  components: {
    OrganisationTabs,
    QForm,
    QInput,
    QBtn,
    QSpinner
    /*QFile,
    QImg*/
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped></style>
