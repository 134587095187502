<template>
  <MilesReview v-if="isMiles" />
  <DriveReview v-else />
</template>

<script>
import { mapState } from 'vuex';
import MilesReview from '@/components/MilesQuote/MilesReview/MilesReview';
import DriveReview from '@/components/DriveQuote/DriveReview/DriveReview';
import { isMiles } from '@/utils/is-miles';

export default {
  name: 'Review',
  computed: {
    ...mapState({
      selectedOrg: (state) => state.org.selectedOrg,
      user: (state) => state.auth.user
    }),
    isMiles() {
      const erpCode = this.selectedOrg?.erps[0]?.code || this.user.organisation.erps[0].code;
      return isMiles(erpCode);
    }
  },
  components: {
    MilesReview,
    DriveReview
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped></style>
