var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('QToolbar',[_c('QToolbarTitle',{staticClass:"flex"},[_c('router-link',{staticClass:"text-none text-grey-8 text-weight-bold flex",attrs:{"to":"/"}},[_c('img',{staticClass:"brand-logo",attrs:{"src":_vm.organisationImage}})]),_c('div',{staticClass:"nav row"},[(_vm.dbViewQuotes)?_c('QBtn',{staticClass:"q-mr-sm",attrs:{"flat":_vm.routeName.toLowerCase().indexOf('quote') == -1,"to":{ name: 'quotes' },"color":"primary","label":"Quotes"}}):_vm._e(),(_vm.dbManageUsers || _vm.dbManageDealers)?_c('QBtn',{staticClass:"q-mr-sm",attrs:{"flat":!['users', 'dealers'].includes(_vm.routeName.toLowerCase()),"to":{ name: _vm.dbManageUsers ? 'users' : 'dealers' },"color":"primary","label":"Administration"}}):_vm._e(),(_vm.dbManageAdmins || _vm.dbManageOrganisations)?_c('QBtn',{staticClass:"q-mr-sm",attrs:{"flat":!['admins', 'organisations', 'signaturetype'].includes(_vm.routeName.toLowerCase()),"to":{ name: _vm.dbManageAdmins ? 'admins' : 'organisations' },"color":"primary","label":"Site Config"}}):_vm._e(),(_vm.$store.getters['auth/whiteLabelEnabled'] && (_vm.hasRole('DB Eclipx Admin') || _vm.hasRole('DB Broker Admin')))?_c('QBtn',{staticClass:"q-mr-sm",attrs:{"flat":![
            'white-label-auth0',
            'white-label-dragonball-content',
            'white-label-calculator-content',
            'white-label-quote-content',
            'white-label-scoreboard-content',
            'white-label-email-templates'
          ].includes(_vm.routeName.toLowerCase()),"to":{ name: 'white-label-auth0' },"color":"primary","label":"Brand Config"}}):_vm._e(),(_vm.dbManageOrganisations)?_c('QSelect',{staticClass:"dropdown",staticStyle:{"max-width":"100%","overflow":"hidden","text-overflow":"ellipsis"},attrs:{"options":_vm.organisations,"option-value":"id","option-label":"name","map-options":"","color":"primary","label":"Act as Organisation"},on:{"input":function($event){return _vm.setOrg($event)}},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e()],1)],1),_c('div',{staticClass:"row items-center"},[(_vm.dbCreateQuotes && this.$router.currentRoute.fullPath === '/quotes')?_c('QBtn',{staticClass:"q-mr-sm",attrs:{"to":{ name: 'quote', params: { quoteId: this.NewQuoteRouteParam } },"color":"secondary","label":"New Quote"},on:{"click":_vm.createNewQuote}}):_vm._e(),_c('EUIProfileMenu',{attrs:{"title":_vm.user ? _vm.user.firstName + ' ' + _vm.user.lastName : this.$auth.user.name}},[_c('template',{slot:"menu"},[_c('QList',[_c('QItem',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"clickable":""}},[_c('QItemSection',{on:{"click":this.logout}},[_vm._v("Logout")])],1)],1)],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }