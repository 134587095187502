<template>
  <div>
    <div class="row justify-between items-center q-mb-md">
      <p class="q-mb-none text-weight-bold text-grey-9">Customer Details</p>
      <QBtn
        flat
        size="sm"
        v-if="$store.getters['quote/canCreate'] && !disabled"
        :disable="!$store.getters['quote/quoteColourAndExtrasValid']"
        :to="{
          name: 'quoteCustomerDetails',
          params: { quoteId: $route.params.quoteId }
        }"
      >
        <QIcon :name="editIcon" color="grey-9" class="q-mr-xs" />
        <span class="text-grey-9 text-weight-medium">Edit</span>
      </QBtn>
    </div>
    <div class="row q-mb-xs">
      <span class="col-5 col-md-3 col-lg-2 text-grey-7 text-body3 text-weight-medium"> Company Name: </span>
      <span class="text-body3 col text-grey-7">
        {{ erpquote.customer.companyName }}
      </span>
    </div>
    <div class="row q-mb-xs">
      <span
        v-if="erpCode === 'MILESNZ' && erpquote.customer.haveNZBN"
        class="col-5 col-md-3 col-lg-2 text-grey-7 text-body3 text-weight-medium"
      >
        NZBN:
      </span>
      <span
        v-else-if="erpCode === 'MILESNZ' && !erpquote.customer.haveNZBN"
        class="col-5 col-md-3 col-lg-2 text-grey-7 text-body3 text-weight-medium"
      >
        Legal Name:
      </span>
      <span v-else class="col-5 col-md-3 col-lg-2 text-grey-7 text-body3 text-weight-medium"> ABN / ACN: </span>
      <span class="text-body3 col text-grey-7">
        {{ erpquote.customer.companyAbn }}
      </span>
    </div>
    <div class="row q-mb-xs" v-if="erpquote.customer.dealership || !this.isOrgDealer">
      <span class="col-5 col-md-3 col-lg-2 text-grey-7 text-body3 text-weight-medium"> Dealership: </span>
      <span class="text-body3 col text-grey-7">
        {{ erpquote.customer.dealership }}
      </span>
    </div>
    <div class="row q-mb-xs" v-if="!isMiles">
      <span class="col-5 col-md-3 col-lg-2 text-grey-7 text-body3 text-weight-medium"> Registered Postcode: </span>
      <span class="text-body3 col text-grey-7">
        {{ erpquote.customer.registrationPostcode }}
      </span>
    </div>
    <div class="row q-mb-xs" v-if="address">
      <span class="col-5 col-md-3 col-lg-2 text-grey-7 text-body3 text-weight-medium"> Delivery Address: </span>
      <span class="text-body3 col text-grey-7">
        {{ address }}
      </span>
    </div>
    <div class="row q-mb-xs" v-if="!isMiles">
      <span class="col-5 col-md-3 col-lg-2 text-grey-7 text-body3 text-weight-medium"> Delivery Date: </span>
      <span class="text-body3 col text-grey-7">
        {{ deliveryDate }}
      </span>
    </div>
    <div class="row q-mb-xs">
      <span class="col-5 col-md-3 col-lg-2 text-grey-7 text-body3 text-weight-medium"> Contact Name: </span>
      <span class="text-body3 col text-grey-7">
        {{ erpquote.customer.customerName }}
      </span>
    </div>
    <div class="row q-mb-xs">
      <span class="col-5 col-md-3 col-lg-2 text-grey-7 text-body3 text-weight-medium"> Contact Number: </span>
      <span class="text-body3 col text-grey-7">
        {{ erpquote.customer.customerContactNumber }}
      </span>
    </div>
    <div class="row q-mb-xs">
      <span class="col-5 col-md-3 col-lg-2 text-grey-7 text-body3 text-weight-medium"> Customer Email: </span>
      <span class="text-body3 col text-grey-7">
        {{ erpquote.customer.customerContactEmail }}
      </span>
    </div>
  </div>
</template>

<script>
import { QBtn, QIcon, Icons, date, EnGbLang } from 'eclipx.ui';
import { mapState, mapGetters } from 'vuex';
import { isMiles } from '@/utils/is-miles';

export default {
  name: 'ReviewQuoteOrderDetails',
  props: {
    disabled: Boolean
  },
  computed: {
    ...mapState({
      erpquote: (state) => state.quote.quote.erpquote,
      selected: (state) => state.org.selectedOrg,
      user: (state) => state.auth.user
    }),
    ...mapGetters({
      isOrgDealer: 'org/isOrgDealer'
    }),
    address: function () {
      let customer = this.erpquote.customer;
      let str = '';
      str += customer.deliveryAddressLine1 ? `${customer.deliveryAddressLine1} ` : '';
      str += customer.deliveryAddressLine2 ? `${customer.deliveryAddressLine2}, ` : '';
      str += customer.deliverySuburb ? `${customer.deliverySuburb}, ` : '';
      str += customer.deliveryState ? `${customer.deliveryState}, ` : '';
      str += customer.deliveryPostcode || '';
      return str;
    },
    deliveryDate: function () {
      return date.formatDate(
        date.extractDate(date.formatDate(this.erpquote.customer.deliveryDate, 'DD/MM/YYYY'), 'DD/MM/YYYY', EnGbLang.date),
        'DD/MM/YYYY',
        EnGbLang.date
      );
    },
    erpCode() {
      return this.selectedOrg?.erps[0]?.code || this.user.organisation.erps[0].code;
    },
    isMiles() {
      return isMiles(this.erpCode);
    }
  },
  created() {
    this.editIcon = Icons.evaEdit;
  },
  components: {
    QBtn,
    QIcon
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped></style>
