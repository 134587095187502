export default {
  namespaced: true,
  state: {
    dragonball: {},
    email: {},
    whiteLabel: {
      region: null,
      introducerName: null,
      enableWhiteLabel: null,
      domain: null
    },
    pricingConfiguration: {}
  },
  getters: {
    dragonball(state) {
      return state.dragonball;
    },
    whiteLabel(state) {
      return state.whiteLabel;
    },
    pricingConfiguration(state) {
      return state.pricingConfiguration;
    }
  },
  mutations: {
    setOrgProfile(state, orgProfile) {
      state.dragonball = orgProfile.dragonball;
      state.email = orgProfile.email;
      state.whiteLabel = orgProfile.whiteLabel;
    },
    clearOrgProfile(state) {
      state.dragonball = {};
      state.email = {};
      state.whiteLabel = {};
    },
    setPricingConfiguration(state, pricingConfiguration = {}) {
      state.pricingConfiguration = pricingConfiguration;
    },
    clearPricingConfiguration(state) {
      state.pricingConfiguration = {};
    }
  }
};
