export default {
  saveCarDetails({ commit, state }, { product, make, bodyType }) {
    if (bodyType.id !== state.quote.erpquote?.bodyType?.id) {
      commit('clearCarVariant');
      commit('clearColourAndExtras');
    }
    // commit('clearLeaseDetails');
    // commit('clearCustomerDetails');
    commit('setErpQuoteFields', { product, make, bodyType });
  },
  saveCarVariant({ commit, state }, { model, variant }) {
    if (variant.id !== state.quote.erpquote?.variant?.id) {
      commit('clearColourAndExtras');
    }
    // commit('clearLeaseDetails');
    //commit('clearCustomerDetails');
    commit('setErpQuoteFields', { model, variant });
  },
  saveLeaseDetails(
    { commit, state },
    { leaseTerm, availableOptions, kmsPerYear, leaseStart, leaseEnd, dbQuoteId, dbStatus, erpQuoteRef, erpStatus, price }
  ) {
    commit('setDbQuoteFields', {
      id: dbQuoteId,
      quote_status_code: dbStatus
    });
    commit('setErpQuoteFields', {
      leaseTerm,
      availableOptions,
      kmsPerYear,
      leaseStart,
      leaseEnd,
      erpQuoteRef,
      status: erpStatus,
      price
    });
  },
  saveColourAndExtras(
    { commit, state },
    {
      exteriorColour,
      interiorColour,
      optionalEquipment,
      adhocEquipment,
      erpQuoteRef,
      erpStatus,
      price,
      quoteRef,
      dbQuoteId,
      dbStatus,
      inclusions,
      exclusions
    }
  ) {
    commit('setDbQuoteFields', {
      id: dbQuoteId,
      quote_status_code: dbStatus
    });
    commit('setErpQuoteFields', {
      exteriorColour,
      interiorColour,
      optionalEquipment,
      adhocEquipment,
      erpQuoteRef,
      status: erpStatus,
      price,
      quoteRef,
      inclusions,
      exclusions
    });
    commit('setErpQuotePriceFields', price);
  },
  saveCustomerDetails({ commit, state }, { customer, dbQuoteId, dbStatus, erpQuoteRef, erpStatus, price }) {
    commit('setDbQuoteFields', {
      id: dbQuoteId,
      quote_status_code: dbStatus
    });
    commit('setErpQuoteFields', {
      customer,
      erpQuoteRef,
      status: erpStatus,
      price
    });
  },
  saveItemisedPricing({ commit, state }, { itemisedPricing, availableOptions }) {
    commit('setErpQuoteFields', {
      itemisedPricing,
      availableOptions
    });
  },
  saveInitialQuoteDetails({ commit, state }, { dbQuoteId, dbStatus, erpQuoteRef, erpStatus, price, inclusions, exclusions }) {
    commit('setDbQuoteFields', {
      id: dbQuoteId,
      quote_status_code: dbStatus
    });
    commit('setErpQuoteFields', {
      erpQuoteRef,
      status: erpStatus,
      price,
      inclusions,
      exclusions
    });
  },
  saveQuoteDetails(
    { commit, state },
    { leaseTerm, kmsPerYear, availableOptions, erpQuoteRef, erpStatus, price, dbQuoteId, dbStatus, itemisedPricing }
  ) {
    commit('setDbQuoteFields', {
      id: dbQuoteId,
      quote_status_code: dbStatus
    });
    commit('setErpQuoteFields', {
      leaseTerm,
      kmsPerYear,
      availableOptions,
      erpQuoteRef,
      status: erpStatus,
      price,
      itemisedPricing
    });
    commit('setErpQuotePriceFields', price);
  },
  updateQuoteReference({ commit, state }, { erpQuoteRef, quoteRef }) {
    commit('setErpQuoteFields', {
      erpQuoteRef,
      quoteRef
    });
  }
};
