<template>
  <MilesAUQuoteSummaryCard v-if="isMilesAU" />
  <MilesNZQuoteSummaryCard v-else />
</template>

<script>
import MilesAUQuoteSummaryCard from '../MilesQuote/MilesAUQuoteSummaryCard/MilesAUQuoteSummaryCard';
import MilesNZQuoteSummaryCard from '../MilesQuote/MilesNZQuoteSummaryCard/MilesNZQuoteSummaryCard';
import { mapState } from 'vuex';
import { isMilesAU } from '@/utils/is-miles';

export default {
  name: 'QuoteSummaryCard',
  computed: {
    ...mapState({
      selected: (state) => state.org.selectedOrg,
      user: (state) => state.auth.user
    }),
    isMilesAU() {
      const erpCode = this.selected?.erps[0]?.code || this.user.organisation.erps[0].code;
      return isMilesAU(erpCode);
    }
  },
  components: {
    MilesAUQuoteSummaryCard,
    MilesNZQuoteSummaryCard
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped></style>
