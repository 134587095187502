<template>
  <MilesCustomerDetails v-if="isMilesNZ" />
  <DriveCustomerDetails v-else />
</template>

<script>
import { mapState } from 'vuex';
import MilesCustomerDetails from '@/components/MilesQuote/MilesCustomerDetails/MilesCustomerDetails';
import DriveCustomerDetails from '@/components/DriveQuote/DriveCustomerDetails/DriveCustomerDetails';
import { isMilesNZ } from '@/utils/is-miles';

export default {
  name: 'CustomerDetails',
  computed: {
    ...mapState({
      selectedOrg: (state) => state.org.selectedOrg,
      user: (state) => state.auth.user
    }),
    isMilesNZ() {
      const erpCode = this.selectedOrg?.erps[0]?.code || this.user.organisation.erps[0].code;
      return isMilesNZ(erpCode);
    }
  },
  components: {
    MilesCustomerDetails,
    DriveCustomerDetails
  }
};
</script>
