<template>
  <QDialog v-model="showDialog" style="max-width: 800px; width: 100%">
    <div class="container full-width row" style="background: white">
      <div class="col-12 q-py-md q-pa-sm">
        <p class="q-pa-none q-mb-sm">Popup hyperlink</p>
        <QInput outlined dense v-model="popupLabel" label="Insert label for popup hyperlink" />
      </div>
      <div class="col-12">
        <QEditor
          ref="editorRef"
          v-model.lazy="popupText"
          placeholder="Popup content"
          min-height="200px"
          :content-style="{ backgroundColor: '#0000000d' }"
          :toolbar="[
            ['bold', 'italic', 'strike', 'underline', 'subscript', 'superscript'],
            ['hr', 'link'],
            [
              {
                label: $q.lang.editor.formatting,
                icon: $q.iconSet.editor.formatting,
                list: 'no-icons',
                options: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'code']
              },
              {
                label: $q.lang.editor.fontSize,
                icon: $q.iconSet.editor.fontSize,
                fixedLabel: true,
                fixedIcon: true,
                list: 'no-icons',
                options: ['size-1', 'size-2', 'size-3', 'size-4', 'size-5', 'size-6', 'size-7']
              },
              'removeFormat'
            ],
            ['quote', 'unordered', 'ordered', 'outdent', 'indent'],
            ['undo', 'redo']
          ]"
        >
        </QEditor>
      </div>
      <div class="col-12 q-pa-sm" style="display: flex; justify-content: end">
        <QBtn @click="handleSubmit">Submit</QBtn>
      </div>
    </div>
  </QDialog>
</template>
<script>
import { QEditor, QDialog, QBtn, QInput } from 'quasar';

export default {
  name: 'DialogEditor',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    popupValue: {
      type: String,
      default: ''
    },
    labelValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showDialog: this.value,
      popupText: this.popupValue,
      popupLabel: this.labelValue
    };
  },
  watch: {
    value(newValue) {
      this.showDialog = newValue;
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('popupValue', this.popupText);
      this.$emit('labelValue', this.popupLabel);
      this.$emit('onSubmit', { popupText: this.popupText, popupLabel: this.popupLabel });
      this.showDialog = false;

      this.$emit('value', this.showDialog);
    },
    onPaste(evt) {
      const editorRef = this.$refs.editorRef;

      // Let inputs do their thing, so we don't break pasting of links.
      if (evt.target.nodeName === 'INPUT') return;
      let text, onPasteStripFormattingIEPaste;
      evt.preventDefault();
      evt.stopPropagation();
      if (evt.originalEvent && evt.originalEvent.clipboardData.getData) {
        text = evt.originalEvent.clipboardData.getData('text/plain');
        editorRef.runCmd('insertText', text);
      } else if (evt.clipboardData && evt.clipboardData.getData) {
        text = evt.clipboardData.getData('text/plain');
        editorRef.runCmd('insertText', text);
      } else if (window.clipboardData && window.clipboardData.getData) {
        if (!onPasteStripFormattingIEPaste) {
          onPasteStripFormattingIEPaste = true;
          editorRef.runCmd('ms-pasteTextOnly', text);
        }
        onPasteStripFormattingIEPaste = false;
      }
    }
  },
  components: {
    QDialog,
    QEditor,
    QBtn,
    QInput
  }
};
</script>

<style lang="sass"></style>
