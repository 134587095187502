<template>
  <MilesAUQuoteDetails v-if="isMilesAU" />
  <MilesNZQuoteDetails v-else />
</template>

<script>
import { mapState } from 'vuex';
import MilesAUQuoteDetails from '@/components/MilesQuote/MilesQuoteDetails/MilesAUQuoteDetails';
import MilesNZQuoteDetails from '@/components/MilesQuote/MilesQuoteDetails/MilesNZQuoteDetails';
import { isMilesAU } from '@/utils/is-miles';

export default {
  name: 'QuoteDetails',
  computed: {
    ...mapState({
      selected: (state) => state.org.selectedOrg,
      user: (state) => state.auth.user
    }),
    isMilesAU() {
      const erpCode = this.selected?.erps[0]?.code || this.user.organisation.erps[0].code;
      return isMilesAU(erpCode);
    }
  },
  components: {
    MilesAUQuoteDetails,
    MilesNZQuoteDetails
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass"></style>
