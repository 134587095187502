<template>
  <QTabs
    align="justify"
    active-color="secondary"
    class="bg-white q-my-md rounded-borders text-grey-8 q-tabs--inset-indicator q-tabs--no-focus-helper"
  >
    <QRouteTab class="q-py-sm" :ripple="false" label="Organisation Details" :to="{ name: 'organisationEdit', params: { orgId: orgId } }" />
    <QRouteTab
      v-if="$store.getters['auth/hasFeature']('DB::ManageAdmins')"
      class="q-py-sm"
      :ripple="false"
      label="White Label Settings"
      :to="{ name: 'wetSignature', params: { orgId: orgId } }"
    />
    <QRouteTab
      v-if="showTabForMiles"
      class="q-py-sm"
      :ripple="false"
      label="Pricing Configuration"
      :to="{ name: 'pricingConfiguration', params: { orgId: orgId } }"
    />
  </QTabs>
</template>

<script>
import { QTabs, QRouteTab } from 'eclipx.ui';
import { mapState } from 'vuex';
import { isMiles } from '@/utils/is-miles';

export default {
  name: 'OrganisationTabs',
  props: {
    disabled: Boolean,
    orgId: String
  },
  components: {
    QTabs,
    QRouteTab
  },
  computed: {
    ...mapState({ selected: (state) => state.org.selectedOrg, user: (state) => state.auth.user }),
    getSelectedOrgErpCode() {
      return this.$store.getters['org/getOrgErpCode'](this.orgId);
    },
    showTabForMiles() {
      return isMiles(this.getSelectedOrgErpCode);
    }
  }
};
</script>
