<template>
  <main>
    <QForm class="row q-gutter-md" @submit="submit">
      <QCard class="col q-pa-lg self-start" bordered>
        <LoadingVehicle v-if="saving" />
        <div v-else>
          <h6 class="q-mb-lg text-grey-8">Quote Details</h6>
          <QCard
            class="row q-pa-md bg-grey-9 q-mb-lg"
            :style="{
              backgroundColor: quoteColour ? quoteColour + '!important' : '#424242',
              borderRadius: '1rem'
            }"
          >
            <QItem class="col-12">
              <QItemLabel class="text-grey-5 text-body3">
                Quote Ref: {{ quoteRef }} | {{ capitalizeFirstLetter(currentErpQuote.model.name) }}</QItemLabel
              >
            </QItem>
            <QItem class="col text-center items-start">
              <QItemSection>
                <QItemLabel caption class="text-white q-mb-sm"> Monthly Total </QItemLabel>
                <h5 class="text-white q-mb-md flex justify-center">
                  {{ currentErpQuote.price.monthlyRentalNet | currency }}
                  <span class="q-ml-md text-grey-5 text-body3">(inc gst)</span>
                </h5>
                <QItemLabel class="text-grey-5 text-body3"
                  >Lease Term - {{ currentErpQuote.leaseTerm }} months<br />
                  Total Km's - {{ totalKms }}
                </QItemLabel>
              </QItemSection>
            </QItem>
            <QSeparator vertical inset class="bg-grey-5" />
            <QItem class="col text-center items-start">
              <QItemSection>
                <QItemLabel caption class="text-white q-mb-sm"> Comparable Weekly Estimate </QItemLabel>
                <h5 class="text-white flex justify-center">
                  {{ currentErpQuote.price.weeklyRentalNet | currency }}
                  <span class="q-ml-md text-grey-5 text-body3">(inc gst)</span>
                </h5>
              </QItemSection>
            </QItem>
            <QSeparator v-if="isItemisedDriveawayPriceEnabled || showToEclipxAdmin" vertical inset class="bg-grey-6" />
            <QItem v-if="isItemisedDriveawayPriceEnabled || showToEclipxAdmin" class="col text-center items-start">
              <QItemSection>
                <QItemLabel caption class="text-white q-mb-sm"> Driveaway Price </QItemLabel>
                <h5 class="text-white q-mb-md flex justify-center">
                  {{ currentErpQuote.itemisedPricing.calculatedDriveAwayPrice | currency }}
                  <span class="q-ml-md text-grey-5 text-body3">(inc gst)</span>
                </h5>
              </QItemSection>
            </QItem>
          </QCard>

          <div class="row">
            <h6 class="col-12 q-mb-lg text-grey-8 text-weight-bold">Itemised Vehicle Pricing</h6>
            <QTable
              class="col-12 vehicle-table"
              flat
              :data="vehicleRows1"
              :columns="vehicleColumns1"
              row-key="name"
              hide-bottom
              :pagination.sync="pagination"
            />
          </div>
          <div class="row q-mt-lg">
            <h6 class="col-12 q-mb-lg text-grey-8 text-weight-bold">Statutory & OnRoad Cost</h6>
            <QTable
              class="col-12 vehicle-table"
              flat
              :data="vehicleRows2"
              :columns="vehicleColumns2"
              row-key="name"
              hide-bottom
              :pagination.sync="pagination"
            />
          </div>
          <QSeparator class="bg-grey-4 q-mb-xl" />
          <div class="row">
            <MilesQuoteNavigationActions className="col-12 flex q-mt-lg justify-between" hideBackIcon back-to="quoteCustomerDetails">
              <template slot="next">
                <QBtn
                  outline
                  color="secondary"
                  icon="img:/assets/images/copy.png"
                  unelevated
                  label="Copy"
                  @click="handleCopyItemisedPricingQuote"
                  :disable="isDisabled"
                />
              </template>
            </MilesQuoteNavigationActions>
          </div>
        </div>
      </QCard>
      <div class="row col-xs-4 col-sm-5 col-md-4 col-lg-3 self-start">
        <QCard class="col-12 q-pa-lg q-mb-md" bordered>
          <h6 class="q-mb-lg text-grey-8 text-weight-bold">Customise</h6>
          <div v-if="leaseOptions" class="column q-mb-md">
            <QItemLabel class="text-body1 text-grey-8 q-mb-xs text-weight-bold">Duration of Lease</QItemLabel>
            <LeaseDetailsSlider
              sliderType="month"
              :sliderValue.sync="leaseTerm"
              :minimumValue="leaseOptions.leaseTerms[0].months"
              @sliderValueChanged="handleLeaseTermChanged"
              :step="1"
              :maximumValue="leaseOptions.leaseTerms[leaseOptions.leaseTerms.length - 1].months"
              :isDisabled="isDisabled"
              :error="$v.leaseTerm.$error"
            />
          </div>
          <div class="column q-mb-md">
            <QItemLabel class="text-body1 text-grey-8 q-mb-xs text-weight-bold">KM's Driven Per Year</QItemLabel>
            <LeaseDetailsSlider
              sliderType="kilometer"
              :sliderValue.sync="kmsPerYear"
              @sliderValueChanged="handleKmsPerYearChanged"
              :isDisabled="isDisabled"
              :error="$v.kmsPerYear.$error"
            />
          </div>
          <div v-if="(isCommissionEnabled && commissionStatus.toLowerCase() !== 'hide') || showToEclipxAdmin">
            <QSeparator class="bg-grey-4 q-my-lg" />
            <QItemLabel class="text-body1 text-grey-8 q-mb-lg text-weight-bold">Adjust Pricing</QItemLabel>
            <div class="col-12 flex q-mb-xs justify-between items-end">
              <QItemLabel class="text-body1 text-grey-8 q-mb-xs">Commission</QItemLabel>
              <div class="content-right">
                <QBtn
                  v-if="!invalidCommission && (commissionStatus.toLowerCase() === 'edit' || showToEclipxAdmin)"
                  dense
                  color="secondary"
                  :icon="editIcon"
                  outline
                  style="border-radius: 22.5px; width: 45px"
                  @click="
                    () => {
                      enableEditCommission = !enableEditCommission;
                    }
                  "
                  :disable="isDisabled || invalidCommission"
                />
              </div>
            </div>
            <ErrorCaption v-if="commissionErrorMessage !== ''" class="q-mb-sm" :caption="commissionErrorMessage" />
            <QItemLabel v-if="invalidCommission" class="text-body2 text-grey-8 q-mb-xs">N/A</QItemLabel>
            <CurrencyInputField
              v-model="commission"
              v-else
              className="col-12 q-pb-none"
              :isDisabled="
                (commissionStatus.toLowerCase() === 'show' && !showToEclipxAdmin) ||
                ((commissionStatus.toLowerCase() === 'edit' || showToEclipxAdmin) && enableEditCommission) ||
                isDisabled
              "
              :enableEdit="enableEditCommission"
              @input="checkFormValuesChanges"
            />
          </div>
          <QSeparator class="bg-grey-4 q-my-lg" />
          <QItemLabel class="text-body1 text-grey-8 q-mb-lg text-weight-bold">Vehicle</QItemLabel>
          <div class="col-12 flex q-mb-xs justify-between items-end">
            <QItemLabel class="text-body1 text-grey-8 q-mb-xs">Dealer Delivery Fee</QItemLabel>
            <div class="content-right">
              <QBtn
                dense
                color="secondary"
                :icon="editIcon"
                outline
                style="border-radius: 22.5px; width: 45px"
                @click="
                  () => {
                    enableEditDeliveryCost = !enableEditDeliveryCost;
                  }
                "
                :disable="isDisabled"
              />
            </div>
          </div>
          <CurrencyInputField
            v-model="dealerDeliveryCost"
            class="col-12 q-pb-none"
            :isDisabled="isDisabled || enableEditDeliveryCost"
            :enableEdit="enableEditDeliveryCost"
            @input="checkFormValuesChanges"
          />
          <QSeparator class="bg-grey-4 q-my-lg" />
          <QItemLabel class="text-body1 text-grey-8 q-mb-lg text-weight-bold">Services</QItemLabel>
          <div class="col-12 flex q-mb-xs justify-between items-end">
            <QItemLabel class="text-body1 text-grey-8 q-mb-xs">Replacement Tyres</QItemLabel>
            <div class="content-right">
              <QBtn
                dense
                color="secondary"
                :icon="editIcon"
                outline
                style="border-radius: 22.5px; width: 45px"
                @click="
                  () => {
                    enableEditTyres = !enableEditTyres;
                  }
                "
                :disable="isDisabled"
              />
            </div>
          </div>
          <QSelect
            color="secondary"
            v-model="tyres"
            :options="tyresOption"
            :error="$v.tyres.$error"
            filled
            outlined
            dense
            clearable
            :disable="isDisabled || enableEditTyres"
            @input="checkFormValuesChanges"
          />
          <div v-if="isDealerDiscountsEnabled || isFleetDiscountsEnabled || showToEclipxAdmin">
            <QSeparator class="bg-grey-4 q-my-lg" />
            <QItemLabel class="text-body1 text-grey-8 q-mb-lg text-weight-bold">Discounts</QItemLabel>
            <div v-if="isFleetDiscountsEnabled || showToEclipxAdmin" class="row q-mb-md">
              <QItemLabel class="col-12 text-body1 text-grey-8 q-mb-xs">Fleet Discount</QItemLabel>
              <QInput
                class="col q-mr-sm"
                v-model="formattedFleetDiscount"
                input-class="hide-input-arrows"
                filled
                color="secondary"
                dense
                clearable
                type="text"
                prefix="$"
                disable
                @input="handleFleetDiscountInput(formattedFleetDiscount)"
              />
              <QInput
                class="col-4"
                v-model="fleetDiscountPercentage"
                input-class="hide-input-arrows"
                filled
                color="secondary"
                dense
                disable
                type="number"
                suffix="%"
                min="0"
                max="100"
                step="0.01"
                @input="handleFleetDiscountPercentage()"
              />
            </div>
            <div v-if="isDealerDiscountsEnabled || showToEclipxAdmin" class="col-12 flex q-mb-xs justify-between items-end">
              <QItemLabel class="text-body1 text-grey-8 q-mb-xs">Dealer Discount</QItemLabel>
              <div class="content-right">
                <QBtn
                  dense
                  color="secondary"
                  :icon="editIcon"
                  outline
                  style="border-radius: 22.5px; width: 45px"
                  @click="
                    () => {
                      enableEditDealerDiscount = !enableEditDealerDiscount;
                    }
                  "
                  :disable="isDisabled"
                />
              </div>
            </div>
            <div v-if="isDealerDiscountsEnabled || showToEclipxAdmin" class="row q-mb-xs">
              <CurrencyInputField
                v-model="dealerDiscount"
                className="col q-mr-sm"
                :isDisabled="enableEditDealerDiscount || isDisabled"
                :enableEdit="enableEditDealerDiscount"
                @input="handleDealerDiscountInput(dealerDiscount)"
              />
              <QInput
                class="col-4"
                v-model="dealerDiscountPercentage"
                input-class="hide-input-arrows"
                filled
                color="secondary"
                dense
                type="number"
                suffix="%"
                min="0"
                max="100"
                step="0.01"
                :disable="enableEditDealerDiscount"
                @input="handleDealerDiscountPercentage()"
              />
            </div>
            <div v-if="isFleetDiscountsEnabled || showToEclipxAdmin" class="row q-mb-md">
              <QItemLabel class="col-12 text-body1 text-grey-8 q-mb-xs">Fleet Bonus</QItemLabel>
              <QInput
                class="col q-mr-sm"
                v-model="formattedFleetBonus"
                input-class="hide-input-arrows"
                filled
                color="secondary"
                dense
                clearable
                type="text"
                prefix="$"
                disable
                @input="handleFleetBonusInput(formattedFleetBonus)"
              />
              <QInput
                class="col-4"
                v-model="fleetBonusPercentage"
                input-class="hide-input-arrows"
                filled
                color="secondary"
                dense
                disable
                type="number"
                suffix="%"
                min="0"
                max="100"
                step="0.01"
                @input="handleFleetBonusPercentage()"
              />
            </div>
            <div v-if="(isDealerDiscountsEnabled && isFleetDiscountsEnabled) || showToEclipxAdmin" class="row q-mb-md">
              <QItemLabel class="col-12 text-body1 text-grey-8 q-mb-xs">Total Discount</QItemLabel>
              <QInput
                class="col q-mr-sm"
                v-model="formattedTotalDiscount"
                input-class="hide-input-arrows"
                filled
                dense
                type="text"
                prefix="$"
                disable
              />
              <QInput
                class="col-4"
                v-model="totalDiscountPercentage"
                input-class="hide-input-arrows"
                filled
                color="secondary"
                dense
                disable
                type="text"
                suffix="%"
                step="0.01"
              />
            </div>
          </div>
        </QCard>
        <div v-if="showDiscardAndRecalculateButton" class="col-12 row">
          <QBtn class="col q-mr-md" label="Discard Changes" outline :icon="undoIcon" @click="handleDiscardChanges" :disable="isDisabled" />
          <QBtn class="col" color="secondary" label="Re-calculate" outline @click="handleRecalculateQuote" :disable="isDisabled" />
        </div>
        <div class="col-12">
          <QBtn
            type="submit"
            class="q-mt-sm full-width"
            color="primary"
            label="Save & Continue"
            :disable="isDisabled || disableSaveButton"
          />
        </div>
        <FormError :show="$v.$error && $v.$dirty" class="q-my-md" />
      </div>
    </QForm>
  </main>
</template>

<script>
import { QBtn, QCard, QForm, QInput, QItem, QItemLabel, QItemSection, QSelect, QSeparator, QTable, Icons } from 'eclipx.ui';
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { GLOBAL_ERROR } from '@/components/GlobalError/GlobalError';
import { GlobalDialog } from '@/components/Dialogs/GlobalDialog';
import FormError from '@/components/FormError/FormError';
import LeaseDetailsSlider from '@/components/LeaseDetails/LeaseDetailsSlider';
import LoadingVehicle from '@/components/LoadingVehicle/LoadingVehicle';
import MilesQuoteNavigationActions from '@/components/MilesQuote/MilesQuoteNavigationActions';
import QueryLeaseOptions from '@/graphql/queries/QueryLeaseOptions';
import QueryVehicleOptions from '@/graphql/queries/QueryVehicleOptions';
import UpdateMilesQuote from '@/graphql/mutations/UpdateMilesQuote';
import MutateQuote from '@/graphql/mutations/MutateQuote';
import CurrencyInputField from '@/components/CurrencyInputField/CurrencyInputField';
import validateTotalDistance from '@/utils/validate-total-distance';
import ErrorCaption from '@/components/InformationCaption/ErrorCaption';

const DEFAULT_ZERO_VALUE = '0.00';
const DEFAULT_ZERO_PERCENTAGE_VALUE = '00.00';
const INVALID_COMMISSION = -1;

export default {
  name: 'QuoteDetails',
  data: function () {
    return {
      saving: false,
      tyres: 0,
      leaseTerm: 24,
      kmsPerYear: 10000,
      commission: DEFAULT_ZERO_VALUE,
      overrideCommission: false,
      dealerDeliveryCost: DEFAULT_ZERO_VALUE,
      isCommissionEnabled: false,
      commissionStatus: '',
      fleetDiscount: DEFAULT_ZERO_VALUE,
      fleetBonus: DEFAULT_ZERO_VALUE,
      fleetDiscountPercentage: DEFAULT_ZERO_PERCENTAGE_VALUE,
      fleetBonusPercentage: DEFAULT_ZERO_PERCENTAGE_VALUE,
      isDealerDiscountsEnabled: false,
      dealerDiscount: DEFAULT_ZERO_VALUE,
      dealerDiscountPercentage: DEFAULT_ZERO_PERCENTAGE_VALUE,
      isFleetDiscountsEnabled: false,
      isItemisedDriveawayPriceEnabled: false,
      totalDiscount: DEFAULT_ZERO_VALUE,
      totalDiscountPercentage: DEFAULT_ZERO_PERCENTAGE_VALUE,
      enableEditDealerDiscount: true,
      enableEditCommission: true,
      enableEditDeliveryCost: true,
      enableEditTyres: true,
      previousErpQuote: {},
      currentErpQuote: {},
      invalidCommission: false,
      commissionErrorMessage: '',
      disableSaveButton: false,
      showDiscardAndRecalculateButton: false,
      vehicleColumns1: [
        {
          name: 'name',
          label: 'Vehicle',
          align: 'left',
          field: (row) => row.name,
          align: 'left',
          style: 'width: 20%;min-width:150px;',
          headerClasses: 'vehicle-header text-grey-8 text-weight-bold'
        },
        {
          name: 'value',
          align: 'left',
          label: 'Excluding GST',
          field: 'value',
          format: (val) => `$ ${val}`,
          headerClasses: 'vehicle-header text-grey-8 '
        }
      ],
      vehicleColumns2: [
        {
          name: 'name',
          label: 'Line Item',
          align: 'left',
          field: (row) => row.name,
          align: 'left',
          style: 'width: 20%;min-width:150px;',
          headerClasses: 'vehicle-header text-grey-8 text-weight-bold'
        },
        {
          name: 'value',
          align: 'left',
          label: 'Excluding GST',
          field: 'value',
          format: (val) => `$ ${val}`,
          headerClasses: 'vehicle-header text-grey-8 '
        }
      ],
      vehicleRows1: [
        {
          name: 'RRP',
          value: DEFAULT_ZERO_VALUE
        },
        {
          name: 'Options',
          value: DEFAULT_ZERO_VALUE
        },
        {
          name: 'Accessories',
          value: DEFAULT_ZERO_VALUE
        },
        {
          name: 'Total Discount',
          value: DEFAULT_ZERO_VALUE
        },
        {
          name: 'Dealer Delivery Fee',
          value: DEFAULT_ZERO_VALUE
        },
        {
          name: 'Sub Total',
          value: DEFAULT_ZERO_VALUE
        }
      ],
      vehicleRows2: [
        {
          name: 'Total GST',
          value: DEFAULT_ZERO_VALUE
        },
        {
          name: 'Registration',
          value: DEFAULT_ZERO_VALUE
        },
        {
          name: 'Non Reclaimable GST',
          value: DEFAULT_ZERO_VALUE
        },
        {
          name: 'Luxury Car Tax',
          value: DEFAULT_ZERO_VALUE
        },
        {
          name: 'CTP Premium',
          value: DEFAULT_ZERO_VALUE
        },
        {
          name: 'CTP Other',
          value: DEFAULT_ZERO_VALUE
        },
        {
          name: 'Plates',
          value: DEFAULT_ZERO_VALUE
        },
        {
          name: 'Stamp Duty',
          value: DEFAULT_ZERO_VALUE
        },
        {
          name: 'Sub Total',
          value: DEFAULT_ZERO_VALUE
        }
      ]
    };
  },
  computed: {
    ...mapState({
      dbquote: (state) => state.quote.quote.dbquote,

      erpquote: (state) => state.quote.quote.erpquote
    }),
    ...mapGetters({
      user: 'auth/user',
      isExpired: 'quote/isExpired',
      hasRequestedPricing: 'quote/hasRequestedPricing',
      dragonball: 'orgProfile/dragonball',
      saveQuoteFields: 'quote/saveQuoteFields',
      pricingConfiguration: 'orgProfile/pricingConfiguration'
    }),
    quoteColour() {
      if (this.dragonball) return this.dragonball.quoteColour;
      else return null;
    },
    isDisabled: function () {
      return this.isExpired || this.hasRequestedPricing || this.saving;
    },
    tyresOption: function () {
      const tyres = [];

      for (let i = 0; i <= 16; i += 2) {
        tyres.push(i);
      }

      return tyres;
    },
    quoteRef() {
      return this.erpquote?.quoteRef?.milesQuoteRef || this.erpquote.erpQuoteRef;
    },
    hasRole() {
      return this.$store.getters['auth/hasRole'];
    },
    showToEclipxAdmin() {
      return this.hasRole('DB Eclipx Admin') || this.hasRole('DB Eclipx User');
    },

    formattedFleetDiscount: {
      get() {
        return this.fleetDiscount.toLocaleString();
      },
      set(value) {
        this.fleetDiscount = this.formatIntoFloat(value);
      }
    },
    formattedFleetBonus: {
      get() {
        return this.fleetBonus.toLocaleString();
      },
      set(value) {
        this.fleetBonus = this.formatIntoFloat(value);
      }
    },
    formattedTotalDiscount: {
      get() {
        if (!this.totalDiscount) {
          return DEFAULT_ZERO_VALUE;
        }

        return this.totalDiscount.toLocaleString();
      },
      set(value) {
        this.totalDiscount = this.formatIntoFloat(value);
      }
    },
    totalVehiclePrice() {
      return (
        this.formatIntoFloat(this.currentErpQuote?.itemisedPricing?.catalogPrice) +
        this.formatIntoFloat(this.currentErpQuote?.itemisedPricing?.accessoriesPrice) +
        this.formatIntoFloat(this.currentErpQuote?.itemisedPricing?.optionsPrice) -
        this.formatIntoFloat(this.currentErpQuote?.itemisedPricing?.defaultDiscountAmount) +
        this.formatIntoFloat(this.currentErpQuote?.itemisedPricing?.dealerDeliveryCost)
      );
    },
    totalStatutoryOnRoadCost() {
      return (
        this.formatIntoFloat(this.currentErpQuote?.itemisedPricing?.registrationPrice) +
        this.formatIntoFloat(this.currentErpQuote?.itemisedPricing?.luxuryCarTax) +
        this.formatIntoFloat(this.currentErpQuote?.itemisedPricing?.initialCtpPremium) +
        this.formatIntoFloat(this.currentErpQuote?.itemisedPricing?.initialCtpStampDuty) +
        this.formatIntoFloat(this.currentErpQuote?.itemisedPricing?.plateFee) +
        this.formatIntoFloat(this.currentErpQuote?.itemisedPricing?.purchaseStampDuty) +
        this.formatIntoFloat(this.currentErpQuote?.itemisedPricing?.catalogVat) +
        this.formatIntoFloat(this.currentErpQuote?.itemisedPricing?.optionsVat) +
        this.formatIntoFloat(this.currentErpQuote?.itemisedPricing?.accessoriesVat) +
        this.formatIntoFloat(this.currentErpQuote?.itemisedPricing?.dealerDeliveryCostVat) +
        this.formatIntoFloat(this.currentErpQuote?.itemisedPricing?.gstOnCtpPremium)
      );
    },
    totalGstCost() {
      return (
        this.formatIntoFloat(this.currentErpQuote?.itemisedPricing?.catalogVat) +
        this.formatIntoFloat(this.currentErpQuote?.itemisedPricing?.optionsVat) +
        this.formatIntoFloat(this.currentErpQuote?.itemisedPricing?.accessoriesVat) +
        this.formatIntoFloat(this.currentErpQuote?.itemisedPricing?.dealerDeliveryCostVat) +
        this.formatIntoFloat(this.currentErpQuote?.itemisedPricing?.gstOnCtpPremium)
      );
    },
    pagination: {
      get: function () {
        return {
          rowsPerPage: 15
        };
      },
      set: function (newValue) {}
    },
    totalKms() {
      return ((parseInt(this.currentErpQuote.leaseTerm) * parseInt(this.currentErpQuote.kmsPerYear)) / 12).toLocaleString();
    }
  },
  apollo: {
    leaseOptions: {
      query: QueryLeaseOptions,
      variables() {
        return {
          productId: this.erpquote.product.id.toString()
        };
      },
      skip() {
        return !this.$store.getters['quote/quoteVariantValid'];
      },
      error: function ({ graphQLErrors }) {
        const errMessage = graphQLErrors.map((err) => {
          return err.message;
        });
        /*Extract error message */
        let message = errMessage.join().replace(/Error:|{}/gi, '');
        if (message && message.startsWith('{"extensions":') && message.includes('"message"') && message.endsWith('}}}}}')) {
          message = message
            .substring(message.indexOf('"message'), message.indexOf('"}}}}}'))
            .replace(/message|:|"/gi, '')
            .trim();
        }
        if (message && message.trim().endsWith('.')) {
          message = message.slice(0, -1);
        }
        if (/:|{|}|"|\?|%/.test(message)) {
          message = '';
        }
        this.$euiNotifications.emit({
          notification: GLOBAL_ERROR,
          action: () => {
            return this.$apollo.queries.leaseOptions.refresh();
          },
          message
        });
      }
    }
  },
  validations() {
    return {
      leaseTerm: { required },
      kmsPerYear: { required },
      tyres: { required }
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    ...mapActions({
      saveQuoteDetails: 'quote/saveQuoteDetails',
      updateQuoteReference: 'quote/updateQuoteReference'
    }),
    ...mapMutations({
      copyItemisedPricingQuote: 'quote/copyItemisedPricingQuote'
    }),
    formatCurrency: function (value) {
      value = parseFloat(value);

      if (!value) {
        return DEFAULT_ZERO_VALUE;
      }

      return value.toLocaleString('en-AU', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    },
    formatPercentage: function (value) {
      value = parseFloat(value);
      if (value <= 0) {
        return DEFAULT_ZERO_PERCENTAGE_VALUE;
      }

      return value;
    },
    formatIntoFloat: function (value) {
      if (value === DEFAULT_ZERO_VALUE || value === DEFAULT_ZERO_PERCENTAGE_VALUE) {
        return 0;
      }

      if (typeof value === 'string') {
        value = parseFloat(value.replace(/,/g, ''), 10);
      } else {
        value = parseFloat(value);
      }

      return !value ? 0 : value;
    },
    calculateDiscountPercentageInput: function (discountValue) {
      if (discountValue == '') {
        return discountValue;
      }

      return parseFloat(((discountValue / this.currentErpQuote?.variant?.listPrice) * 100).toFixed(2));
    },
    calculateDiscountCurrencyInput: function (percentageValue) {
      if (percentageValue == '') {
        return percentageValue;
      }

      return (percentageValue / 100) * this.currentErpQuote?.variant?.listPrice;
    },
    calculateTotalDiscount: function () {
      return this.formatIntoFloat(this.currentErpQuote?.itemisedPricing?.defaultDiscountAmount);
    },
    calculateTotalDiscountPercentage: function () {
      const totalDiscountPercentage =
        this.formatIntoFloat(this.fleetDiscountPercentage) +
        // this.formatIntoFloat(this.currentErpQuote?.itemisedPricing?.cleanCarDiscount) +
        this.formatIntoFloat(this.dealerDiscountPercentage);

      return this.formatPercentage(totalDiscountPercentage);
    },
    capitalizeFirstLetter: function (value) {
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    },
    handleFleetDiscountInput: function () {
      this.fleetDiscountPercentage = this.calculateDiscountPercentageInput(this.fleetDiscount);
      this.totalDiscount = this.calculateTotalDiscount();
      this.totalDiscountPercentage = this.calculateTotalDiscountPercentage();
      this.checkFormValuesChanges();
    },
    handleFleetDiscountPercentage: function () {
      this.fleetDiscount = this.calculateDiscountCurrencyInput(this.fleetDiscountPercentage);
      this.totalDiscount = this.calculateTotalDiscount();
      this.totalDiscountPercentage = this.calculateTotalDiscountPercentage();
      this.checkFormValuesChanges();
    },
    handleFleetBonusInput: function () {
      this.fleetBonusPercentage = this.calculateDiscountPercentageInput(this.fleetBonus);
      this.totalDiscount = this.calculateTotalDiscount();
      this.totalDiscountPercentage = this.calculateTotalDiscountPercentage();
      this.checkFormValuesChanges();
    },
    handleFleetBonusPercentage: function () {
      this.fleetBonus = this.calculateDiscountCurrencyInput(this.fleetBonusPercentage);
      this.totalDiscount = this.calculateTotalDiscount();
      this.totalDiscountPercentage = this.calculateTotalDiscountPercentage();
      this.checkFormValuesChanges();
    },
    handleDealerDiscountInput: function () {
      const dealerDiscount = parseFloat(this.formatIntoFloat(this.dealerDiscount.toString()));
      this.dealerDiscountPercentage = this.calculateDiscountPercentageInput(dealerDiscount);
      this.totalDiscount = this.calculateTotalDiscount();
      this.totalDiscountPercentage = this.calculateTotalDiscountPercentage();
      this.checkFormValuesChanges();
    },
    handleDealerDiscountPercentage: function () {
      this.dealerDiscount = this.calculateDiscountCurrencyInput(this.dealerDiscountPercentage);
      this.totalDiscount = this.calculateTotalDiscount();
      this.totalDiscountPercentage = this.calculateTotalDiscountPercentage();
      this.checkFormValuesChanges();
    },
    prepareUpdatedValues: function () {
      let leaseUpdatedValues = {
        leaseTerm: this.leaseTerm,
        kmsPerYear: this.kmsPerYear,
        availableOptions: {
          ...this.currentErpQuote.availableOptions,
          tyres: this.tyres === 'NONE' ? 0 : this.tyres
        }
      };

      let itemisedPricingUpdatedvalues = {
        commission: this.overrideCommission ? this.formatIntoFloat(this.commission) : -1,
        fleetDiscount: this.formatIntoFloat(this.fleetDiscount),
        fleetDiscountPercentage: this.formatIntoFloat(this.fleetDiscountPercentage),
        dealerDiscount: this.formatIntoFloat(this.dealerDiscount),
        dealerDiscountPercentage: this.formatIntoFloat(this.dealerDiscountPercentage),
        totalDiscount: this.formatIntoFloat(this.totalDiscount),
        dealerDeliveryCost: this.formatIntoFloat(this.dealerDeliveryCost)
      };

      const updatedValues = {
        ...leaseUpdatedValues,
        itemisedPricing: itemisedPricingUpdatedvalues
      };

      return updatedValues;
    },
    handleRecalculateQuote: async function () {
      window.scrollTo(0, 0);
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      if (this.validateTotalDistance()) {
        return;
      }
      this.commissionErrorMessage = '';

      const updatedValues = this.prepareUpdatedValues();
      this.saving = true;
      await this.$apollo
        .mutate({
          mutation: this.dbquote.id ? UpdateMilesQuote : MutateQuote,
          variables: {
            params: {
              ...(this.dbquote.id ? { dbQuoteId: this.dbquote.id, milesQuoteRef: this.quoteRef } : this.saveQuoteFields),
              erpQuoteData: {
                ...this.currentErpQuote,
                ...updatedValues,
                ...{
                  price: {
                    onRoadCost: null,
                    monthlyRentalNet: null,
                    weeklyRentalNet: null
                  }
                }
              }
            }
          },
          update: (store, response) => {
            if (response.data?.updateMilesQuote || response.data?.saveQuote) {
              this.currentErpQuote = {
                ...this.currentErpQuote,
                ...updatedValues,
                itemisedPricing: {
                  ...updatedValues.itemisedPricing,
                  ...(response.data?.updateMilesQuote?.response?.itemisedPricing || response.data?.saveQuote?.response?.itemisedPricing)
                },
                price: response.data?.updateMilesQuote?.response?.price || response.data?.saveQuote?.response?.price,
                dbQuoteId: response.data?.updateMilesQuote?.response?.dbQuoteId,
                dbStatus: response.data?.updateMilesQuote?.response?.dbStatus
              };

              this.bindItemisedPricing();

              this.disableSaveButton = false;

              this.commissionErrorMessage = '';
            }
          }
        })
        .catch((exception) => {
          const { name, message = '' } = exception;
          const [_, errorMessage] = message.split(':');

          this.commissionErrorMessage = errorMessage.includes('Commission') ? errorMessage : '';

          if (!this.commissionErrorMessage) {
            this.$euiNotifications.emit({
              notification: GLOBAL_ERROR,
              action: () => {
                return this.handleRecalculateQuote();
              }
            });
          }
        })
        .finally(() => {
          this.saving = false;
          this.$v.$reset();
        });
    },
    handleDiscardChanges: async function () {
      window.scrollTo(0, 0);

      this.dealerDiscount = this.formatIntoFloat(this.previousErpQuote.itemisedPricing.dealerDiscount);
      this.fleetDiscount = this.formatIntoFloat(this.previousErpQuote.itemisedPricing.fleetDiscount);
      this.fleetBonus = this.formatIntoFloat(this.previousErpQuote.itemisedPricing.fleetBonus);
      this.commission = this.formatIntoFloat(this.previousErpQuote.itemisedPricing.commission);
      this.dealerDeliveryCost = this.formatIntoFloat(this.previousErpQuote.itemisedPricing.dealerDeliveryCost);
      this.tyres = this.formatIntoFloat(this.previousErpQuote.availableOptions.tyres);
      this.leaseTerm = this.formatIntoFloat(this.previousErpQuote.leaseTerm);
      this.kmsPerYear = this.formatIntoFloat(this.previousErpQuote.kmsPerYear);
      this.fleetDiscountPercentage = this.formatPercentage(this.calculateDiscountPercentageInput(this.fleetDiscount));
      this.fleetBonusPercentage = this.formatPercentage(this.calculateDiscountPercentageInput(this.fleetBonus));
      this.dealerDiscountPercentage = this.formatPercentage(this.calculateDiscountPercentageInput(this.dealerDiscount));
      this.totalDiscount = this.formatCurrency(this.calculateTotalDiscount());
      this.totalDiscountPercentage = this.calculateTotalDiscountPercentage();
      this.currentErpQuote = this.previousErpQuote;
      this.bindItemisedPricing();

      if (
        this.currentErpQuote.price.onRoadCost != this.previousErpQuote.price.onRoadCost ||
        this.currentErpQuote.price.monthlyRentalNet != this.previousErpQuote.price.monthlyRentalNet
      ) {
        await this.handleRecalculateQuote();
      }

      this.showDiscardAndRecalculateButton = false;
      this.disableSaveButton = false;
    },
    handleCopyItemisedPricingQuote: async function () {
      this.saving = true;
      const variantId = this.erpquote.variant.id.toString();
      const templateId = this.erpquote.product.id.toString();

      const response = await this.$apollo
        .query({
          query: QueryVehicleOptions,
          variables: {
            variantId,
            templateId
          },
          fetchPolicy: 'network-only'
        })
        .catch(({ graphQLErrors }) => {
          let errMessage = graphQLErrors.map((err) => {
            return err.message;
          });
          /*Extract error message */
          let message = errMessage.join().replace(/Error:|{}/gi, '');
          if (message && message.startsWith('{"extensions":') && message.includes('"message"') && message.endsWith('}}}}}')) {
            message = message
              .substring(message.indexOf('"message'), message.indexOf('"}}}}}'))
              .replace(/message|:|"/gi, '')
              .trim();
          }
          if (message && message.trim().endsWith('.')) {
            message = message.slice(0, -1);
          }
          if (/:|{|}|"|\?|%/.test(message)) {
            message = '';
          }
          this.$euiNotifications.emit({
            notification: GLOBAL_ERROR,
            action: () => {
              return this.handleCopyItemisedPricingQuote();
            },
            message
          });
        })
        .finally(() => {
          this.saving = false;
        });

      if (response.data.vehicleOptions) {
        this.copyItemisedPricingQuote();
        this.updateQuoteReference({
          erpQuoteRef: response.data.vehicleOptions.quoteRef.quoteId,
          quoteRef: response.data.vehicleOptions.quoteRef
        });

        this.currentErpQuote = this.erpquote;

        const submitResponse = await this.submit({ isCopyingQuote: true });

        this.$router.push({
          name: 'quoteDetails',
          params: {
            quoteId: submitResponse?.data?.saveQuote?.response?.dbQuoteId || 'new-quote'
          }
        });
      }
    },
    submit: async function () {
      window.scrollTo(0, 0);
      const updatedValues = this.prepareUpdatedValues();

      this.saving = true;
      return await this.$apollo
        .mutate({
          mutation: MutateQuote,
          variables: {
            params: {
              ...this.saveQuoteFields,
              erpQuoteData: {
                ...this.currentErpQuote,
                ...updatedValues,
                ...{
                  price: {
                    onRoadCost: null,
                    monthlyRentalNet: null,
                    weeklyRentalNet: null
                  }
                },
                ...(!arguments[0]?.isCopyingQuote && { saveMilesLease: true }),
                shouldCreateDocument: true
              }
            }
          },
          update: (store, response) => {
            if (response.data.saveQuote) {
              this.saveQuoteDetails({
                ...this.currentErpQuote,
                ...updatedValues,
                ...response.data.saveQuote.response,
                itemisedPricing: {
                  ...updatedValues.itemisedPricing,
                  ...response.data.saveQuote.response.itemisedPricing
                }
              });

              if (!arguments[0]?.isCopyingQuote) {
                this.goToNext();
              }
            }
          }
        })
        .catch(({ graphQLErrors }) => {
          let errMessage = graphQLErrors.map((err) => {
            return err.message;
          });
          /*Extract error message */
          let message = errMessage.join().replace(/Error:|{}/gi, '');
          if (message && message.startsWith('{"extensions":') && message.includes('"message"') && message.endsWith('}}}}}')) {
            message = message
              .substring(message.indexOf('"message'), message.indexOf('"}}}}}'))
              .replace(/message|:|"/gi, '')
              .trim();
          }
          if (message && message.trim().endsWith('.')) {
            message = message.slice(0, -1);
          }
          if (/:|{|}|"|\?|%/.test(message)) {
            message = '';
          }
          this.$euiNotifications.emit({
            notification: GLOBAL_ERROR,
            action: () => {
              return this.submit();
            },
            message
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    handleLeaseTermChanged(value) {
      this.leaseTerm = parseInt(value);
      this.checkFormValuesChanges();
    },
    handleKmsPerYearChanged(value) {
      this.kmsPerYear = parseInt(value);
      this.checkFormValuesChanges();
    },
    goToNext() {
      this.$router.push({
        name: 'quoteReview',
        params: { quoteId: this.$route.params.quoteId }
      });
    },
    bindItemisedPricing() {
      // RRP
      this.vehicleRows1[0].value = this.currentErpQuote?.itemisedPricing?.catalogPrice
        ? this.formatCurrency(this.currentErpQuote?.itemisedPricing?.catalogPrice)
        : this.vehicleRows1[0].value;
      // Options
      this.vehicleRows1[1].value = this.currentErpQuote?.itemisedPricing?.optionsPrice
        ? this.formatCurrency(this.currentErpQuote?.itemisedPricing?.optionsPrice)
        : this.vehicleRows1[1].value;
      // Accessories
      this.vehicleRows1[2].value = this.currentErpQuote?.itemisedPricing?.accessoriesPrice
        ? this.formatCurrency(this.currentErpQuote?.itemisedPricing?.accessoriesPrice)
        : this.vehicleRows1[2].value;

      // Total Discount
      this.vehicleRows1[3].value = this.formatCurrency(this.currentErpQuote?.itemisedPricing?.defaultDiscountAmount);

      // Delivery Fee
      this.vehicleRows1[4].value = this.currentErpQuote?.itemisedPricing?.dealerDeliveryCost
        ? this.formatCurrency(this.currentErpQuote?.itemisedPricing?.dealerDeliveryCost)
        : this.vehicleRows1[4].value;

      // Subtotal
      this.vehicleRows1[5].value = this.totalVehiclePrice > 0 ? this.formatCurrency(this.totalVehiclePrice) : this.vehicleRows1[5].value;

      // Total GST
      this.vehicleRows2[0].value = this.totalGstCost > 0 ? this.formatCurrency(this.totalGstCost) : this.vehicleRows2[0].value;
      // Registration
      this.vehicleRows2[1].value = this.currentErpQuote?.itemisedPricing?.registrationPrice
        ? this.formatCurrency(this.currentErpQuote?.itemisedPricing?.registrationPrice)
        : this.vehicleRows2[1].value;
      // Non Reclaimable GST
      this.vehicleRows2[2].value = this.currentErpQuote?.itemisedPricing?.nonReclaimableGst
        ? this.formatCurrency(this.currentErpQuote?.itemisedPricing?.nonReclaimableGst)
        : this.vehicleRows2[2].value;
      // Luxury Car Tax
      this.vehicleRows2[3].value = this.currentErpQuote?.itemisedPricing?.luxuryCarTax
        ? this.formatCurrency(this.currentErpQuote?.itemisedPricing?.luxuryCarTax)
        : this.vehicleRows2[3].value;
      // CTP Premium inc GST
      this.vehicleRows2[4].value = this.currentErpQuote?.itemisedPricing?.initialCtpPremium
        ? this.formatCurrency(this.currentErpQuote?.itemisedPricing?.initialCtpPremium)
        : this.vehicleRows2[4].value;
      // CTP Other
      this.vehicleRows2[5].value = this.currentErpQuote?.itemisedPricing?.initialCtpStampDuty
        ? this.formatCurrency(this.currentErpQuote?.itemisedPricing?.initialCtpStampDuty)
        : this.vehicleRows2[5].value;
      // Plates
      this.vehicleRows2[6].value = this.currentErpQuote?.itemisedPricing?.plateFee
        ? this.formatCurrency(this.currentErpQuote?.itemisedPricing?.plateFee)
        : this.vehicleRows2[6].value;
      // Stamp Duty
      this.vehicleRows2[7].value = this.currentErpQuote?.itemisedPricing?.purchaseStampDuty
        ? this.formatCurrency(this.currentErpQuote?.itemisedPricing?.purchaseStampDuty)
        : this.vehicleRows2[7].value;

      // Subtotal
      this.vehicleRows2[8].value =
        this.totalStatutoryOnRoadCost > 0 ? this.formatCurrency(this.totalStatutoryOnRoadCost) : this.vehicleRows2[8].value;

      this.commission = this.formatCurrency(this.currentErpQuote?.itemisedPricing?.commission) || this.commission;
      this.overrideCommission = this.currentErpQuote?.itemisedPricing?.overrideCommission || this.overrideCommission;
      if (!this.overrideCommission && this.previousErpQuote && this.previousErpQuote.itemisedPricing) {
        this.previousErpQuote.itemisedPricing.commission =
          this.formatCurrency(this.currentErpQuote?.itemisedPricing?.commission) || this.commission;
      }
      this.dealerDeliveryCost = this.formatCurrency(this.currentErpQuote?.itemisedPricing?.dealerDeliveryCost) || this.dealerDeliveryCost;
      this.fleetDiscount = this.formatCurrency(this.currentErpQuote?.itemisedPricing?.fleetDiscount) || this.fleetDiscount;
      this.fleetDiscountPercentage = this.currentErpQuote?.itemisedPricing?.fleetDiscount
        ? this.formatPercentage(this.calculateDiscountPercentageInput(this.currentErpQuote?.itemisedPricing?.fleetDiscount))
        : this.fleetDiscountPercentage;
      this.currentErpQuote?.itemisedPricing?.fleetDiscountPercentage || this.fleetDiscountPercentage;
      this.dealerDiscount = this.formatCurrency(this.currentErpQuote?.itemisedPricing?.dealerDiscount) || this.dealerDiscount;
      this.dealerDiscountPercentage = this.currentErpQuote?.itemisedPricing?.dealerDiscount
        ? this.formatPercentage(this.calculateDiscountPercentageInput(this.currentErpQuote?.itemisedPricing?.dealerDiscount))
        : this.dealerDiscountPercentage;
      this.fleetBonus = this.formatCurrency(this.currentErpQuote?.itemisedPricing?.fleetBonus) || this.fleetBonus;
      this.fleetBonusPercentage = this.currentErpQuote?.itemisedPricing?.fleetBonus
        ? this.formatPercentage(this.calculateDiscountPercentageInput(this.currentErpQuote?.itemisedPricing?.fleetBonus))
        : this.fleetBonusPercentage;

      const totalDiscount = this.calculateTotalDiscount();
      const totalDiscountPercentage = this.calculateTotalDiscountPercentage();
      this.totalDiscount = totalDiscount > 0 ? this.formatCurrency(totalDiscount) : this.totalDiscount;
      this.totalDiscountPercentage = totalDiscountPercentage > 0 ? totalDiscountPercentage : this.totalDiscountPercentage;
    },
    bindStateValues() {
      this.previousErpQuote = this.erpquote;
      this.currentErpQuote = this.erpquote;
      this.leaseTerm = parseInt(this.erpquote.leaseTerm) || this.leaseTerm;
      this.kmsPerYear = parseInt(this.erpquote.kmsPerYear) || this.kmsPerYear;
      this.tyres = this.erpquote.availableOptions.tyres || this.tyres;
      this.invalidCommission = this.currentErpQuote?.itemisedPricing?.commission === INVALID_COMMISSION;

      // Bind Itemised Pricing
      this.bindItemisedPricing();
    },
    initializeIcons() {
      this.informationIcon = Icons.evaInfoOutline;
      this.undoIcon = Icons.evaUndoOutline;
      this.editIcon = Icons.evaEditOutline;
    },
    checkItemisedPricingOptionsEnabled() {
      const { dealerDiscounts, fleetDiscounts } = this.pricingConfiguration;
      this.isDealerDiscountsEnabled =
        dealerDiscounts.isDealerDiscountsEnabled &&
        dealerDiscounts.manufacturersEnabled.some((manufacturer) => manufacturer === this.erpquote.make.name.toUpperCase());

      this.isFleetDiscountsEnabled =
        fleetDiscounts.isFleetDiscountsEnabled &&
        fleetDiscounts.manufacturersEnabled.some((manufacturer) => manufacturer === this.erpquote.make.name.toUpperCase());

      this.isCommissionEnabled =
        this.pricingConfiguration?.isItemisedPricingEnabled && this.user.pricingConfiguration?.isItemisedPricingEnabled
          ? this.user.pricingConfiguration?.commissions?.isCommissionsEnabled
          : false;
      this.commissionStatus = this.user.pricingConfiguration?.commissions?.status || '';

      this.isItemisedDriveawayPriceEnabled =
        this.pricingConfiguration?.isItemisedDriveawayPriceEnabled && (this.isDealerDiscountsEnabled || this.isFleetDiscountsEnabled);
    },

    checkFormValuesChanges() {
      const { itemisedPricing, availableOptions, leaseTerm, kmsPerYear } = this.previousErpQuote;

      const hasCommissionChanges =
        (this.isCommissionEnabled || this.showToEclipxAdmin) &&
        this.formatIntoFloat(this.commission) != this.formatIntoFloat(itemisedPricing.commission);
      const hasChanges =
        this.formatIntoFloat(this.tyres) != this.formatIntoFloat(availableOptions.tyres) ||
        this.leaseTerm != leaseTerm ||
        this.formatIntoFloat(this.kmsPerYear) != this.formatIntoFloat(kmsPerYear) ||
        ((this.isDealerDiscountsEnabled || this.showToEclipxAdmin) &&
          this.dealerDiscount != this.formatIntoFloat(itemisedPricing.dealerDiscount)) ||
        ((this.isFleetDiscountsEnabled || this.showToEclipxAdmin) &&
          this.formatIntoFloat(this.fleetDiscount) != this.formatIntoFloat(itemisedPricing.fleetDiscount)) ||
        this.formatIntoFloat(this.dealerDeliveryCost) != this.formatIntoFloat(itemisedPricing.dealerDeliveryCost) ||
        this.formatIntoFloat(this.currentErpQuote.price.monthlyRentalNet) !=
          this.formatIntoFloat(this.previousErpQuote.price.monthlyRentalNet) ||
        this.formatIntoFloat(this.currentErpQuote.price.weeklyRentalNet) !=
          this.formatIntoFloat(this.previousErpQuote.price.weeklyRentalNet);

      this.overrideCommission = hasCommissionChanges;

      this.disableSaveButton = hasChanges || hasCommissionChanges;
      this.showDiscardAndRecalculateButton = hasChanges || hasCommissionChanges;
    },
    validateTotalDistance() {
      const isDistanceOverMax = validateTotalDistance(this.kmsPerYear, this.leaseTerm);

      if (isDistanceOverMax) {
        const message =
          'The maximum distance for a quote for this vehicle  is 200,000kms. Reduce the Duration of Lease and/or Estimated kms/year to generate a quote.';

        this.$euiNotifications.emit({
          notification: GlobalDialog,
          noEscDismiss: true,
          persistent: true,
          body:
            '<div class="col-12" style="text-align: center">' +
            '<img src="/assets/images/alert.png" class="header-logo" />' +
            '</div>' +
            `<p>${message}</p>`,
          qCardStyle: {
            padding: '0',
            width: '400px',
            maxWidth: '80vw'
          },
          closeBtnStyle: {
            display: 'none'
          },
          btnOne: {
            label: 'OK',
            outline: true,
            color: 'primary',
            action: () => {}
          }
        });
      }

      return isDistanceOverMax;
    }
  },
  watch: {
    enableEditDealerDiscount(value) {
      if (!value) {
        this.dealerDiscount = this.formatIntoFloat(this.dealerDiscount) ? this.dealerDiscount : '';
        this.dealerDiscountPercentage = this.formatIntoFloat(this.dealerDiscountPercentage) ? this.dealerDiscountPercentage : '';
      } else {
        this.dealerDiscount = this.formatIntoFloat(this.dealerDiscount) ? this.dealerDiscount : DEFAULT_ZERO_VALUE;
        this.dealerDiscountPercentage = this.formatIntoFloat(this.dealerDiscountPercentage)
          ? this.dealerDiscountPercentage
          : DEFAULT_ZERO_PERCENTAGE_VALUE;
      }
    },
    enableEditCommission(value) {
      if (!value) {
        this.commission = this.formatIntoFloat(this.commission) ? this.commission : '';
      } else {
        this.commission = this.formatIntoFloat(this.commission) ? this.commission : DEFAULT_ZERO_VALUE;
      }
    },
    enableEditDeliveryCost(value) {
      if (!value) {
        this.dealerDeliveryCost = this.formatIntoFloat(this.dealerDeliveryCost) ? this.dealerDeliveryCost : '';
      } else {
        this.dealerDeliveryCost = this.formatIntoFloat(this.dealerDeliveryCost) ? this.dealerDeliveryCost : DEFAULT_ZERO_VALUE;
      }
    }
  },
  created() {
    this.initializeIcons();
    this.checkItemisedPricingOptionsEnabled();
    this.bindStateValues();
  },
  components: {
    QBtn,
    QCard,
    QForm,
    QInput,
    QItem,
    QItemLabel,
    QItemSection,
    QSelect,
    QSeparator,
    QTable,
    FormError,
    LeaseDetailsSlider,
    LoadingVehicle,
    MilesQuoteNavigationActions,
    CurrencyInputField,
    ErrorCaption
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass">
.vehicle-table
  th
    font-size: 1rem !important
    font-weight: 700 !important

  td:first-child
    color: #000
    font-weight: 700 !important
</style>
