<template>
  <MilesLeaseDetails v-if="isMilesNZ" />
  <DriveLeaseDetails v-else />
</template>

<script>
import { mapState } from 'vuex';
import MilesLeaseDetails from '@/components/MilesQuote/MilesLeaseDetails/MilesLeaseDetails';
import DriveLeaseDetails from '@/components/DriveQuote/DriveLeaseDetails/DriveLeaseDetails';
import { isMilesNZ } from '@/utils/is-miles';

export default {
  name: 'QuoteLeaseDetails',
  computed: {
    ...mapState({
      selectedOrg: (state) => state.org.selectedOrg,
      user: (state) => state.auth.user
    }),
    isMilesNZ() {
      const erpCode = this.selectedOrg?.erps[0]?.code || this.user.organisation.erps[0].code;
      return isMilesNZ(erpCode);
    }
  },
  components: {
    MilesLeaseDetails,
    DriveLeaseDetails
  }
};
</script>
