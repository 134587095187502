var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"q-page column q-pa-lg"},[_c('h6',{staticClass:"q-mt-none q-mb-md text-weight-bold text-grey-9"},[_vm._v("Edit Organisation")]),_c('OrganisationTabs',{attrs:{"orgId":_vm.routeId}}),(this.$apollo.queries.manufacturers.loading || this.$apollo.queries.getOrgProfileDetails.loading)?_c('div',{staticClass:"flex justify-center q-pa-md"},[_c('QSpinner',{attrs:{"size":"md","color":"grey-7"}})],1):_c('div',{staticClass:"row q-gutter-md"},[_c('div',{staticClass:"col q-pa-lg self-start q-card q-card--bordered"},[_c('QForm',{on:{"submit":_vm.submit}},[_c('div',{staticClass:"row q-mt-none q-mb-md"},[_c('h6',{staticClass:"col-12 text-grey-8 q-mb-xs"},[_vm._v("Pricing Configuration")]),_c('p',{staticClass:"col-12 text-grey-8"},[_vm._v(" You may choose to enable quote pricing OR itemised pricing. Please note both options cannot be at the same time ")])]),_c('FormError',{staticClass:"col-12 q-mt-md",attrs:{"show":_vm.$v.$error && _vm.$v.$dirty && !(_vm.$v.isRRPEnabled.$error || _vm.$v.isItemisedPricingEnabled.$error || _vm.$v.isDriveawayPriceEnabled.$error)}}),(_vm.$v.isRRPEnabled.$error || _vm.$v.isItemisedPricingEnabled.$error || _vm.$v.isDriveawayPriceEnabled.$error)?_c('div',{staticClass:"text-red text-body3"},[_vm._v(" At lease one pricing option must be selected. ")]):_vm._e(),_c('div',{staticClass:"row q-mt-lg q-mb-lg"},[_c('h6',{staticClass:"q-mt-none q-mb-md text-grey-8 text-subtitle2",staticStyle:{"font-size":"1rem","font-weight":"600"}},[_vm._v("Quote Pricing")]),_c('div',{staticClass:"col-12"},[_c('QToggle',{class:_vm.$v.isRRPEnabled.$error || _vm.$v.isItemisedPricingEnabled.$error || _vm.$v.isDriveawayPriceEnabled.$error
                  ? 'text-red'
                  : 'text-grey-8',attrs:{"size":"lg","color":"secondary","label":"Estimated Driveaway Price"},model:{value:(_vm.$v.isRRPEnabled.$model),callback:function ($$v) {_vm.$set(_vm.$v.isRRPEnabled, "$model", $$v)},expression:"$v.isRRPEnabled.$model"}}),_c('QIcon',{staticClass:"q-ml-sm",attrs:{"color":"grey-7","name":_vm.informationIcon,"size":"xs"}},[_c('QTooltip',{attrs:{"offset":[0, 10],"max-width":"280px","anchor":"bottom right","self":"top left"}},[_vm._v("The estimated Driveaway Price uses the RRP of the vehicle & does not include additional vehicle accessories. It can be used if you don't have a Dealer quote & would like to quickly calculate a lease quote estimate.")])],1)],1),_c('div',{staticClass:"col-12"},[_c('QToggle',{class:_vm.$v.isRRPEnabled.$error || _vm.$v.isItemisedPricingEnabled.$error || _vm.$v.isDriveawayPriceEnabled.$error
                  ? 'text-red'
                  : 'text-grey-8',attrs:{"size":"lg","color":"secondary","label":"Dealer Driveaway Price"},model:{value:(_vm.$v.isDriveawayPriceEnabled.$model),callback:function ($$v) {_vm.$set(_vm.$v.isDriveawayPriceEnabled, "$model", $$v)},expression:"$v.isDriveawayPriceEnabled.$model"}}),_c('QIcon',{staticClass:"q-ml-sm",attrs:{"color":"grey-7","name":_vm.informationIcon,"size":"xs"}},[_c('QTooltip',{attrs:{"offset":[0, 10],"max-width":"280px","anchor":"bottom right","self":"top left"}},[_vm._v("If you have a Dealer quote please enter the total Driveaway Price including discounts, fees, on roads & GST. This is the total amount payable to the Dealer.")])],1)],1)]),(_vm.showItemisedPricing)?_c('QSeparator',{staticClass:"q-my-md"}):_vm._e(),(_vm.showItemisedPricing)?_c('div',{staticClass:"row q-mt-md q-mb-xl"},[_c('h6',{staticClass:"col-12 q-mt-lg text-grey-8 text-subtitle2",staticStyle:{"font-size":"1rem","font-weight":"600"}},[_vm._v("Itemised Pricing")]),_c('QToggle',{class:("col-12 q-mt-lg q-mb-lg " + (_vm.$v.isRRPEnabled.$error || _vm.$v.isItemisedPricingEnabled.$error || _vm.$v.isDriveawayPriceEnabled.$error
                ? 'text-red'
                : 'text-grey-8')),attrs:{"size":"lg","color":"secondary","label":"Itemised Pricing"},model:{value:(_vm.$v.isItemisedPricingEnabled.$model),callback:function ($$v) {_vm.$set(_vm.$v.isItemisedPricingEnabled, "$model", $$v)},expression:"$v.isItemisedPricingEnabled.$model"}}),_c('h6',{class:("q-mt-none q-mb-md text-subtitle2 " + (_vm.isItemisedPricingEnabled ? 'text-grey-8' : 'text-grey-6')),staticStyle:{"font-size":"0.75rem"}},[_vm._v(" Activate the visibility of Commission & Dealers in the itimised pricing quote. These can also be removed from certain manufacturers by removing from drop down lists below. ")]),_c('QToggle',{staticClass:"col-12 text-grey-8",attrs:{"size":"lg","color":"secondary","label":"Commission","disable":!_vm.isItemisedPricingEnabled},model:{value:(_vm.isCommissionsEnabled),callback:function ($$v) {_vm.isCommissionsEnabled=$$v},expression:"isCommissionsEnabled"}}),_c('QToggle',{staticClass:"col-12 text-grey-8",attrs:{"size":"lg","color":"secondary","label":"Fleet Discount","disable":!_vm.isItemisedPricingEnabled},model:{value:(_vm.isFleetDiscountsEnabled),callback:function ($$v) {_vm.isFleetDiscountsEnabled=$$v},expression:"isFleetDiscountsEnabled"}}),_c('QSelect',{staticClass:"col-12 col-md-4 col-lg-3 col-xl-2 text-grey-8",attrs:{"dense":"","filled":"","multiple":"","options":_vm.formatManufacturers,"display-value":_vm.fleetDiscountsManufacturers.length == _vm.formatManufacturers.length
                ? 'All Manufacturers Selected'
                : _vm.fleetDiscountsManufacturers.map(function (value) { return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase(); }).join(', '),"color":"secondary","label":!_vm.fleetDiscountsManufacturers.length ? 'Select Manufacturers' : void 0,"disable":!_vm.isFleetDiscountsEnabled,"loading":this.$apollo.queries.manufacturers.loading,"error":_vm.$v.fleetDiscountsManufacturers.$error},scopedSlots:_vm._u([{key:"before-options",fn:function(){return [_c('div',{staticClass:"row"},[_c('QItem',{staticClass:"col-12 items-center"},[_c('QCheckbox',{staticStyle:{"width":"100%"},attrs:{"color":"secondary"},on:{"input":function (value) { return _vm.toggleSelectAll('fleetDiscountsManufacturers', _vm.formatManufacturers, value); }},model:{value:(_vm.selectAllFleetDiscountsManufacturers),callback:function ($$v) {_vm.selectAllFleetDiscountsManufacturers=$$v},expression:"selectAllFleetDiscountsManufacturers"}},[_c('QItemLabel',[_vm._v(" "+_vm._s(_vm.selectAllFleetDiscountsManufacturers ? 'Unselect All' : 'Select All')+" ")])],1)],1),_c('QSeparator',{staticClass:"q-my-xs col-12",staticStyle:{"height":"1px !important"}})],1)]},proxy:true},{key:"option",fn:function(ref){
                var itemProps = ref.itemProps;
                var itemEvents = ref.itemEvents;
                var opt = ref.opt;
                var selected = ref.selected;
                var toggleOption = ref.toggleOption;
return [_c('QItem',_vm._g(_vm._b({staticClass:"items-center"},'QItem',itemProps,false),itemEvents),[_c('QCheckbox',{attrs:{"color":"secondary","value":selected},on:{"input":function($event){return toggleOption(opt)}}}),_c('QItemLabel',[_vm._v(" "+_vm._s(opt.charAt(0).toUpperCase() + opt.slice(1).toLowerCase())+" ")])],1)]}}],null,false,3562714252),model:{value:(_vm.fleetDiscountsManufacturers),callback:function ($$v) {_vm.fleetDiscountsManufacturers=$$v},expression:"fleetDiscountsManufacturers"}}),_c('QToggle',{staticClass:"col-12 text-grey-8",attrs:{"size":"lg","color":"secondary","label":"Dealer Discount","disable":!_vm.isItemisedPricingEnabled},model:{value:(_vm.isDealerDiscountsEnabled),callback:function ($$v) {_vm.isDealerDiscountsEnabled=$$v},expression:"isDealerDiscountsEnabled"}}),_c('QSelect',{staticClass:"col-12 col-md-4 col-lg-3 col-xl-2 text-grey-8",attrs:{"dense":"","filled":"","multiple":"","options":_vm.formatManufacturers,"display-value":_vm.dealerDiscountsManufacturers.length == _vm.formatManufacturers.length
                ? 'All Manufacturers Selected'
                : _vm.dealerDiscountsManufacturers.map(function (value) { return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase(); }).join(', '),"color":"secondary","label":!_vm.dealerDiscountsManufacturers.length ? 'Select Manufacturers' : void 0,"disable":!_vm.isDealerDiscountsEnabled,"loading":this.$apollo.queries.manufacturers.loading,"error":_vm.$v.dealerDiscountsManufacturers.$error},scopedSlots:_vm._u([{key:"before-options",fn:function(){return [_c('div',{staticClass:"row"},[_c('QItem',{staticClass:"col-12 items-center"},[_c('QCheckbox',{staticStyle:{"width":"100%"},attrs:{"color":"secondary"},on:{"input":function (value) { return _vm.toggleSelectAll('dealerDiscountsManufacturers', _vm.formatManufacturers, value); }},model:{value:(_vm.selectAllDealerDiscountsManufacturers),callback:function ($$v) {_vm.selectAllDealerDiscountsManufacturers=$$v},expression:"selectAllDealerDiscountsManufacturers"}},[_c('QItemLabel',[_vm._v(" "+_vm._s(_vm.selectAllDealerDiscountsManufacturers ? 'Unselect All' : 'Select All')+" ")])],1)],1),_c('QSeparator',{staticClass:"q-my-xs col-12",staticStyle:{"height":"1px !important"}})],1)]},proxy:true},{key:"option",fn:function(ref){
                var itemProps = ref.itemProps;
                var itemEvents = ref.itemEvents;
                var opt = ref.opt;
                var selected = ref.selected;
                var toggleOption = ref.toggleOption;
return [_c('QItem',_vm._g(_vm._b({staticClass:"items-center"},'QItem',itemProps,false),itemEvents),[_c('QCheckbox',{attrs:{"color":"secondary","value":selected},on:{"input":function($event){return toggleOption(opt)}}}),_c('QItemLabel',[_vm._v(" "+_vm._s(opt.charAt(0).toUpperCase() + opt.slice(1).toLowerCase())+" ")])],1)]}}],null,false,368793705),model:{value:(_vm.dealerDiscountsManufacturers),callback:function ($$v) {_vm.dealerDiscountsManufacturers=$$v},expression:"dealerDiscountsManufacturers"}}),_c('QToggle',{staticClass:"col-12 text-grey-8",attrs:{"size":"lg","color":"secondary","label":"Itemised Driveaway Price","disable":!_vm.isItemisedPricingEnabled},model:{value:(_vm.isItemisedDriveawayPriceEnabled),callback:function ($$v) {_vm.isItemisedDriveawayPriceEnabled=$$v},expression:"isItemisedDriveawayPriceEnabled"}})],1):_vm._e(),_c('QSeparator',{staticClass:"q-my-md"}),_c('div',{staticClass:"row q-mt-md q-mb-xl"},[_c('QToggle',{staticClass:"col-12 text-grey-8",attrs:{"size":"lg","color":"secondary","label":"Calculator","disable":!_vm.isPublicCalculatorEnabled},model:{value:(_vm.isCalculatorEnabled),callback:function ($$v) {_vm.isCalculatorEnabled=$$v},expression:"isCalculatorEnabled"}}),_c('QSelect',{staticClass:"col-12 col-md-4 col-lg-3 col-xl-2 text-grey-8",attrs:{"dense":"","filled":"","multiple":"","options":_vm.formatManufacturers,"display-value":_vm.calculatorManufacturers.length == _vm.formatManufacturers.length
                ? 'All Manufacturers Selected'
                : _vm.calculatorManufacturers.map(function (value) { return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase(); }).join(', '),"color":"secondary","label":!_vm.calculatorManufacturers.length ? 'Select Manufacturers' : void 0,"disable":!_vm.isCalculatorEnabled,"loading":this.$apollo.queries.manufacturers.loading,"error":_vm.$v.calculatorManufacturers.$error},scopedSlots:_vm._u([{key:"before-options",fn:function(){return [_c('div',{staticClass:"row"},[_c('QItem',{staticClass:"col-12 items-center"},[_c('QCheckbox',{staticStyle:{"width":"100%"},attrs:{"color":"secondary"},on:{"input":function (value) { return _vm.toggleSelectAll('calculatorManufacturers', _vm.formatManufacturers, value); }},model:{value:(_vm.selectAllCalculatorManufacturers),callback:function ($$v) {_vm.selectAllCalculatorManufacturers=$$v},expression:"selectAllCalculatorManufacturers"}},[_c('QItemLabel',[_vm._v(" "+_vm._s(_vm.selectAllCalculatorManufacturers ? 'Unselect All' : 'Select All')+" ")])],1)],1),_c('QSeparator',{staticClass:"q-my-xs col-12",staticStyle:{"height":"1px !important"}})],1)]},proxy:true},{key:"option",fn:function(ref){
                var itemProps = ref.itemProps;
                var itemEvents = ref.itemEvents;
                var opt = ref.opt;
                var selected = ref.selected;
                var toggleOption = ref.toggleOption;
return [_c('QItem',_vm._g(_vm._b({staticClass:"items-center"},'QItem',itemProps,false),itemEvents),[_c('QCheckbox',{attrs:{"color":"secondary","value":selected},on:{"input":function($event){return toggleOption(opt)}}}),_c('QItemLabel',[_vm._v(" "+_vm._s(opt.charAt(0).toUpperCase() + opt.slice(1).toLowerCase())+" ")])],1)]}}]),model:{value:(_vm.calculatorManufacturers),callback:function ($$v) {_vm.calculatorManufacturers=$$v},expression:"calculatorManufacturers"}})],1),_c('QSeparator',{staticClass:"q-my-md col-12"}),_c('div',{staticClass:"q-mt-lg flex"},[_c('QBtn',{staticClass:"q-mr-sm",attrs:{"label":'Cancel',"color":"secondary","disable":_vm.isSaving},on:{"click":_vm.cancel}}),_c('QBtn',{staticClass:"q-mr-sm",attrs:{"type":"submit","label":_vm.isSaved ? 'Saved' : 'Save',"color":"primary","loading":_vm.isSaving,"disable":this.$apollo.queries.manufacturers.loading || _vm.disableSaveButton}})],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }