var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('QDialog',{staticStyle:{"max-width":"800px","width":"100%"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('div',{staticClass:"container full-width row",staticStyle:{"background":"white"}},[_c('div',{staticClass:"col-12 q-py-md q-pa-sm"},[_c('p',{staticClass:"q-pa-none q-mb-sm"},[_vm._v("Popup hyperlink")]),_c('QInput',{attrs:{"outlined":"","dense":"","label":"Insert label for popup hyperlink"},model:{value:(_vm.popupLabel),callback:function ($$v) {_vm.popupLabel=$$v},expression:"popupLabel"}})],1),_c('div',{staticClass:"col-12"},[_c('QEditor',{ref:"editorRef",attrs:{"placeholder":"Popup content","min-height":"200px","content-style":{ backgroundColor: '#0000000d' },"toolbar":[
          ['bold', 'italic', 'strike', 'underline', 'subscript', 'superscript'],
          ['hr', 'link'],
          [
            {
              label: _vm.$q.lang.editor.formatting,
              icon: _vm.$q.iconSet.editor.formatting,
              list: 'no-icons',
              options: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'code']
            },
            {
              label: _vm.$q.lang.editor.fontSize,
              icon: _vm.$q.iconSet.editor.fontSize,
              fixedLabel: true,
              fixedIcon: true,
              list: 'no-icons',
              options: ['size-1', 'size-2', 'size-3', 'size-4', 'size-5', 'size-6', 'size-7']
            },
            'removeFormat'
          ],
          ['quote', 'unordered', 'ordered', 'outdent', 'indent'],
          ['undo', 'redo']
        ]},model:{value:(_vm.popupText),callback:function ($$v) {_vm.popupText=$$v},expression:"popupText"}})],1),_c('div',{staticClass:"col-12 q-pa-sm",staticStyle:{"display":"flex","justify-content":"end"}},[_c('QBtn',{on:{"click":_vm.handleSubmit}},[_vm._v("Submit")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }