import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export const initialQuote = {
  dbquote: {
    id: null,
    docusign_ref: null,
    quote_status_code: 'DRAFT',
    is_request_pricing: false
  },
  erpquote: {
    erpQuoteRef: null,
    status: null,
    // Vehicle details
    product: {},
    make: {},
    bodyType: {},
    model: {},
    variant: {},
    // Lease Details
    leaseStart: null,
    leaseEnd: null,
    availableOptions: {},
    leaseTerm: null,
    kmsPerYear: null,
    // Colour and extras
    exteriorColour: {},
    interiorColour: {},
    optionalEquipment: [],
    adhocEquipment: [],

    // for miles
    quoteRef: {},

    // Order Details
    customer: {
      companyName: null,
      companyNumber: null,
      companyStateCode: null,
      companyPostCode: null,
      companyAbn: null,
      customerName: null,
      customerContactNumber: null,
      customerContactEmail: null,
      deliveryAddressLine1: null,
      deliveryAddressLine2: null,
      deliverySuburb: null,
      deliveryState: null,
      deliveryPostcode: null,
      deliveryDate: null,
      dealership: null,
      registrationPostcode: null,
      registrationState: null,
      haveNZBN: null,
      soleTrader: null,
      salesforce: {}
    },
    price: {
      onRoadCost: null,
      monthlyRentalNet: null,
      weeklyRentalNet: null
    },

    //Itemised Pricing
    itemisedPricing: {
      optionsPrice: null,
      accessoriesPrice: null,
      registrationPrice: null,
      initialLicenseCost: null,
      cleanCarFee: null,
      cleanCarDiscount: null,
      commission: null,
      fleetDiscount: null,
      fleetDiscountPercentage: null,
      dealerDiscount: null,
      dealerDiscountPercentage: null,
      totalDiscount: null,
      dealerDeliveryCost: null
    },
    inclusions: [],
    exclusions: []
  }
};

export default {
  namespaced: true,
  state: {
    quote: { ...initialQuote }
  },
  getters: { ...getters },
  mutations: mutations,
  actions: actions
};
