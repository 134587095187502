<template>
  <div>
    <LoadingVehicle v-if="saving" />
    <QForm v-else @submit="saveQuoteLeaseDetails">
      <h6 class="q-mt-none q-mb-none text-grey-8">Lease Details</h6>
      <div class="flex justify-center q-mt-md" v-if="$apollo.queries.leaseOptions.loading">
        <QSpinner size="md" color="grey-7" />
      </div>
      <div v-else>
        <FormError :show="$v.$invalid && $v.$dirty" class="q-mt-md" />
        <div class="row q-col-gutter-md q-mt-sm">
          <div class="col-6">
            <p class="text-body3 text-grey-7 q-mb-xs text-weight-medium">Select lease terms*</p>
            <QSelect
              color="secondary"
              v-model="$v.leaseTerm.$model"
              dense
              hide-bottom-space
              filled
              :clearable="true"
              emit-value
              :disable="isDisabled"
              :display-value="leaseTerm ? `${leaseTerm} months` : 'Select'"
              :options="leaseOptions.leaseTerms"
              :option-label="(item) => `${item.months} months`"
              option-value="months"
              :error="$v.leaseTerm.$error"
              @input="selectTerm"
            />
          </div>
          <div class="col-6">
            <p class="text-body3 text-grey-7 q-mb-xs text-weight-medium">Estimated kms/year*</p>
            <QSelect
              color="secondary"
              v-model="$v.kmsPerYear.$model"
              dense
              hide-bottom-space
              filled
              clearable
              :display-value="kmsPerYear ? kmsPerYear : 'Select'"
              :disable="!kmsPerYearOptions.length || isDisabled"
              :readonly="!kmsPerYearOptions.length"
              :options="kmsPerYearOptions"
              :option-label="(item) => `${item}km`"
              :error="$v.kmsPerYear.$error"
            />
          </div>
        </div>

        <div class="row q-col-gutter-md">
          <div class="col-6 q-mt-md">
            <p class="text-body3 text-grey-7 q-mb-xs text-weight-medium">Lease start date*</p>
            <EUIDateInput
              filled
              dense
              dateMask="DD/MM/YYYY"
              :disable="isDisabled"
              hide-bottom-space
              color="secondary"
              v-model="$v.leaseStart.$model"
              placeholder="----/--/--"
              :error="$v.leaseStart.$error"
            />
          </div>
          <div class="col-6 q-mt-md">
            <p class="text-body3 text-grey-7 q-mb-xs text-weight-medium">Lease end date*</p>
            <QInput v-model="leaseEnd" color="secondary" filled hide-bottom-space dense readonly disable :icon-right="calendarIcon">
              <template slot="append">
                <QIcon :name="calendarIcon" size="20px" />
              </template>
            </QInput>
          </div>
        </div>

        <div class="row q-col-gutter-x-md">
          <div class="col-6 q-mt-md" v-for="option in mappedAvailableOptions" :key="option.id">
            <EUIFormField
              :value="availableOptions[option.id]"
              :type="option.type"
              :disable="isDisabled"
              :required="option.required"
              :attributes="option.attributes"
              :error="$v.availableOptions[option.id].$error"
              @input="(value) => setAvailableField(option.id, value)"
            />
          </div>
        </div>

        <div class="row q-col-gutter-md q-mt-md">
          <div class="col-6" v-if="getInclusionsExclusions.inclusions.length">
            <p class="q-mb-md text-weight-bold text-grey-9">Inclusions</p>
            <div class="row no-wrap" v-for="inclusion in getInclusionsExclusions.inclusions" :key="inclusion">
              <QIcon class="q-mr-sm icon-spacer" :name="checkmark" size="1rem" color="green-8" />
              <span class="text-grey-7">{{ inclusion }}</span>
            </div>
          </div>
          <div class="col-6" v-if="getInclusionsExclusions.exclusions.length">
            <p class="q-mb-md text-weight-bold text-grey-9">Exclusions</p>
            <div class="row no-wrap" v-for="exclusion in getInclusionsExclusions.exclusions" :key="exclusion">
              <QIcon class="q-mr-sm icon-spacer" :name="close" size="1rem" color="grey-7" />
              <span class="text-grey-7">{{ exclusion }}</span>
            </div>
          </div>
        </div>
      </div>
      <DriveQuoteNavigationActions back-to="quoteCarVariants">
        <template slot="next" v-if="!$apollo.queries.leaseOptions.loading">
          <QBtn
            type="submit"
            :label="isDisabled ? 'Next' : 'Save and Next'"
            :icon-right="nextIcon"
            color="secondary"
            :ripple="false"
            :loading="saving"
            :disable="saving"
          />
        </template>
      </DriveQuoteNavigationActions>
    </QForm>
  </div>
</template>

<script>
import { QBtn, Icons, QForm, QInput, QSelect, QIcon, date, EnGbLang, QSpinner } from 'eclipx.ui';
import EUIDateInput from '@/components/EuiDateInput';
import EUIFormField from '@/components/EuiFormField';
import { required } from 'vuelidate/lib/validators';
import { mapActions, mapState, mapGetters } from 'vuex';
import DriveQuoteNavigationActions from '../DriveQuoteNavigationActions';
import FormError from '@/components/FormError/FormError';
import QueryLeaseOptions from '@/graphql/queries/QueryLeaseOptions';
import { GLOBAL_ERROR } from '@/components/GlobalError/GlobalError';
import LoadingVehicle from '@/components/LoadingVehicle/LoadingVehicle';

export default {
  name: 'DriveLeaseDetails',
  data() {
    return {
      leaseOptions: {},
      leaseTerm: null,
      kmsPerYear: null,
      leaseStart: null,
      availableOptions: {},
      saving: false
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  validations() {
    return {
      leaseTerm: { required },
      kmsPerYear: { required },
      leaseStart: { required },
      availableOptions: {
        ...this.mapOptionsToValue(this.leaseOptions.availableOptions, 'id', (option) => (option.required ? { required } : {}))
      }
    };
  },
  computed: {
    ...mapState({
      erpquote: (state) => state.quote.quote.erpquote,
      dbquote: (state) => state.quote.quote.dbquote
    }),
    ...mapGetters({
      isExpired: 'quote/isExpired',
      hasRequestedPricing: 'quote/hasRequestedPricing',
      saveQuoteFields: 'quote/saveQuoteFields',
      getInclusionsExclusions: 'quote/getInclusionsExclusions'
    }),
    isDisabled: function () {
      return this.isExpired || this.hasRequestedPricing;
    },
    mappedAvailableOptions: function () {
      let options = {};
      this.leaseOptions.availableOptions.forEach((option) => {
        options[option.id] = {
          id: option.id,
          type: option.type,
          required: option.required,
          attributes: {
            'title': option.name,
            'label': option.name,
            'options': option.options,
            'option-label': 'name',
            'option-value': 'id',
            'disable': this.isDisabled
          }
        };
        // Set the correct select display value
        if (option.type === 'select') {
          // Do loose equality since server value (eg. tyres) can be number but options are string.
          let selectedOption = option.options.find((rowOption) => rowOption.id == this.availableOptions[option.id]) || {};
          options[option.id].attributes['display-value'] = selectedOption.name || this.availableOptions[option.id] || 'Select';
        }
      });
      return options;
    },
    selectedTerm: function () {
      let terms = this.leaseOptions.leaseTerms || [];
      if (!terms.length || !this.leaseTerm) {
        return {};
      }
      return terms.find((term) => term.months === this.leaseTerm) || {};
    },
    kmsPerYearOptions: function () {
      return this.selectedTerm.kmsPerYear || [];
    },
    leaseEnd: function () {
      if (!this.leaseTerm || !this.kmsPerYear || !this.leaseStart) {
        return null;
      }
      let startDate = this.extractFormattedDate(this.leaseStart);
      let endDate = date.addToDate(startDate, { month: this.leaseTerm });
      return date.formatDate(endDate, 'DD/MM/YYYY', this.$q.lang);
    }
  },
  apollo: {
    leaseOptions: {
      query: QueryLeaseOptions,
      variables() {
        return {
          productId: this.erpquote.product.id.toString()
        };
      },
      skip() {
        return !this.$store.getters['quote/quoteVariantValid'];
      },
      error: function ({ graphQLErrors }) {
        const errMessage = graphQLErrors.map((err) => {
          return err.message;
        });
        /*Extract error message */
        let message = errMessage.join().replace(/Error:|{}/gi, '');
        if (message && message.startsWith('{"extensions":') && message.includes('"message"') && message.endsWith('}}}}}')) {
          message = message
            .substring(message.indexOf('"message'), message.indexOf('"}}}}}'))
            .replace(/message|:|"/gi, '')
            .trim();
        }
        if (message && message.trim().endsWith('.')) {
          message = message.slice(0, -1);
        }
        if (/:|{|}|"|\?|%/.test(message)) {
          message = '';
        }
        this.$euiNotifications.emit({
          notification: GLOBAL_ERROR,
          action: () => {
            return this.$apollo.queries.leaseOptions.refresh();
          },
          message //:errMessage.join().replace(/Error:|{}/gi, '')
        });
      }
    }
  },
  watch: {
    'leaseOptions.availableOptions': function () {
      // Set available options from the store quote with defaults from the api available options
      this.availableOptions = {
        ...this.mapOptionsToValue(this.leaseOptions.availableOptions, 'id', (option) => {
          // Default is always returned as a string, however, checkbox and radio will need it cast to a boolean
          let isCheckboxOrRadio = ['radio', 'checkbox'].includes(option.type);
          return isCheckboxOrRadio ? JSON.parse(option.default) : option.default;
        }),
        ...this.erpquote.availableOptions
      };
    }
  },
  methods: {
    ...mapActions({ saveLeaseDetails: 'quote/saveLeaseDetails' }),
    extractFormattedDate(formattedDate) {
      return date.extractDate(formattedDate, 'DD/MM/YYYY', EnGbLang.date);
    },
    selectTerm() {
      let kmsPerYear = this.selectedTerm.kmsPerYear;
      if (kmsPerYear && kmsPerYear.indexOf(this.kmsPerYear) < 0) {
        this.kmsPerYear = null;
      }
    },
    // Reduce the given options into an object of an option id and value of a given function
    mapOptionsToValue(options, id, valueDefinition) {
      return options.reduce((acc, option) => {
        acc[option[id]] = valueDefinition(option);
        return acc;
      }, {});
    },
    setAvailableField(id, value) {
      this.availableOptions[id] = value;
    },
    goToNext() {
      this.$router.push({
        name: 'quoteColourAndExtras'
        // params: { quoteId: this.dbquote.id }
      });
    },
    saveQuoteLeaseDetails: async function () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      if (!this.isDisabled) {
        let updatedValues = {
          leaseTerm: this.leaseTerm,
          kmsPerYear: this.kmsPerYear,
          availableOptions: {
            ...this.availableOptions,
            ...(this.availableOptions?.tyres && {
              tyres: this.availableOptions?.tyres === 'NONE' ? 0 : this.availableOptions.tyres
            })
          },
          leaseStart: date.formatDate(this.extractFormattedDate(this.leaseStart), 'YYYY-MM-DD', EnGbLang.date),
          leaseEnd: date.formatDate(this.extractFormattedDate(this.leaseEnd), 'YYYY-MM-DD', EnGbLang.date)
        };

        this.saveLeaseDetails({
          ...this.erpquote,
          ...updatedValues,
          ...(this.dbquote?.id && {
            dbQuoteId: this.dbquote?.id,
            dbStatus: this.dbquote?.quote_status_code
          })
        });
        this.goToNext();
      } else {
        return this.goToNext();
      }
    }
  },
  created() {
    // If the prior page is invalid, go to the first incomplete page
    if (!this.$store.getters['quote/quoteVariantValid']) {
      this.$router.replace({
        name: this.$store.getters['quote/firstIncompletePage'],
        params: { quoteId: this.$route.params.quoteId }
      });
    }

    this.leaseTerm = parseInt(this.erpquote.leaseTerm) || null;
    this.kmsPerYear = parseInt(this.erpquote.kmsPerYear) || null;
    let startDate = this.erpquote.leaseStart
      ? date.extractDate(this.erpquote.leaseStart, 'YYYY-MM-DD', EnGbLang.date)
      : new Date(Date.now());
    this.leaseStart = date.formatDate(startDate, 'DD/MM/YYYY', EnGbLang.date);
    this.checkmark = Icons.evaCheckmark;
    this.close = Icons.evaClose;
    this.nextIcon = Icons.evaArrowForward;
    this.calendarIcon = Icons.evaCalendar;
  },
  components: {
    FormError,
    QBtn,
    QSelect,
    QForm,
    EUIDateInput,
    QInput,
    QIcon,
    DriveQuoteNavigationActions,
    EUIFormField,
    QSpinner,
    LoadingVehicle
  }
};
</script>

<style lang="sass" scoped>
.icon-spacer
    margin-top: 2px
</style>
