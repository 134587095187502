// define page validators and routes to go to when valid ordered from the first to last page in the quote flow
const initialQuotePageFlow = {
  quoteDetailsValid: 'quoteCarVariants',
  quoteVariantValid: 'quoteLeaseDetails',
  quoteLeaseValid: 'quoteColourAndExtras',
  quoteColourAndExtrasValid: 'quoteCustomerDetails',
  quoteCustomerDetailsValid: 'quoteReview',
  quoteMilesCustomerDetailsValid: 'quoteDetails',
  quoteItemisedPricingValid: 'quoteReview'
};

import { isMiles } from '@/utils/is-miles';

export default {
  quote(state) {
    return state.quote;
  },
  saveQuoteFields(state, getters, rootState) {
    return {
      dbQuoteId: state.quote.dbquote.id,
      erpQuoteRef: state.quote.erpquote.erpQuoteRef,
      haveNZBN: state.quote.dbquote.have_n_z_b_n,
      soleTrader: state.quote.dbquote.sole_trader
    };
  },
  isAwaitingPrice(state) {
    return state.quote.dbquote.quote_status_code === 'AWAITINGPRICING';
  },
  hasRequestedPricing(state) {
    let pricingStatuses = ['AWAITINGPRICING', 'PRICINGRECEIVED'];
    return pricingStatuses.includes(state.quote.dbquote.quote_status_code);
  },
  hasPrice(state) {
    const { quote } = state;
    const { erpquote } = quote;

    const isHasPrice = erpquote.price !== null && erpquote.price.monthlyRentalNet > 0;

    return isHasPrice;
  },
  isRequestPricing(state) {
    return state.quote.dbquote.is_request_pricing;
  },
  isSubmitting(state) {
    return state.quote.dbquote.quote_status_code === 'SUBMITTED';
  },
  isAccepted(state) {
    return state.quote.dbquote.quote_status_code === 'ACCEPTED';
  },
  isExpired(state) {
    return state.quote.dbquote.quote_status_code === 'EXPIRED';
  },
  // Authentications
  canRead(state, getters, rootState) {
    const canManage = rootState.auth.feature.find((code) => code === 'DB::ManageOrganisations');

    if (
      !canManage &&
      (!rootState.auth.user || rootState.auth.user.isActive === false || rootState.auth.user.organisation.isActive === false)
    ) {
      return false;
    }
    return rootState.auth.feature.find((code) => code === 'DB::ReadQuote');
  },
  canCreate(state, getters, rootState) {
    const canManage = rootState.auth.feature.find((code) => code === 'DB::ManageOrganisations');

    if (
      !canManage &&
      (!rootState.auth.user || rootState.auth.user.isActive === false || rootState.auth.user.organisation.isActive === false)
    ) {
      return false;
    }
    return rootState.auth.feature.find((code) => code === 'DB::CreateQuote');
  },
  // Validations
  // Return true if the quote flow passes all pages up to the given point in the flow
  flowValidUntil: (state, getters, rootState) => (flowStop) => {
    const quotePageFlow = initialQuotePageFlow;

    // if (rootState.orgProfile.pricingConfiguration?.isItemisedPricingEnabled) {
    //   delete quotePageFlow.quoteCustomerDetailsValid;
    // } else {
    //   delete quotePageFlow.quoteCustomerDetailsItemisedPricingValid;
    //   delete quotePageFlow.quoteItemisedPricingValid;
    // }

    let flow = Object.keys(quotePageFlow);
    for (let i = 0; i < flow.length; i++) {
      if (flow[i] === flowStop) {
        return true;
      }

      if (!getters[flow[i]]) {
        return false;
      }
    }
  },
  // Validation States
  quoteDetailsValid: (state) => {
    return Boolean(state.quote.erpquote.product.id && state.quote.erpquote.make.id && state.quote.erpquote.bodyType.id);
  },
  quoteVariantValid: (state, getters) => {
    return Boolean(getters.flowValidUntil('quoteVariantValid') && state.quote.erpquote.model.id && state.quote.erpquote.variant.id);
  },
  quoteLeaseValid: (state, getters, rootState) => {
    if (isMiles(rootState.org.selectedOrg?.erps[0]?.code) || isMiles(rootState.auth.user.organisation.erps[0].code)) {
      return Boolean(
        getters.flowValidUntil('quoteLeaseValid') && state.quote.erpquote.leaseTerm !== null && state.quote.erpquote.kmsPerYear !== null
      );
    }

    return Boolean(
      getters.flowValidUntil('quoteLeaseValid') &&
        state.quote.erpquote.leaseTerm !== null &&
        state.quote.erpquote.kmsPerYear !== null &&
        state.quote.erpquote.leaseStart &&
        state.quote.erpquote.leaseEnd
    );
  },
  quoteColourAndExtrasValid: (state, getters) => {
    return Boolean(
      getters.flowValidUntil('quoteColourAndExtrasValid') &&
        (state.quote.erpquote.erpQuoteRef ? true : state.quote.erpquote.exteriorColour.id && state.quote.erpquote.interiorColour.id)
    );
  },
  quoteMilesCustomerDetailsValid: (state, getters, rootState) => {
    const hasAddress = state.quote.erpquote.customer?.deliveryAddressLine1
      ? state.quote.erpquote.customer.deliveryAddressLine1 &&
        state.quote.erpquote.customer.deliveryState &&
        state.quote.erpquote.customer.deliveryPostcode
      : true;

    return Boolean(
      getters.flowValidUntil('quoteMilesCustomerDetailsValid') &&
        state.quote.erpquote.customer.companyAbn &&
        state.quote.erpquote.customer.customerName &&
        state.quote.erpquote.customer.customerContactNumber &&
        state.quote.erpquote.customer.customerContactEmail &&
        hasAddress &&
        state.quote.erpquote.customer.deliveryDate
    );
  },
  quoteItemisedPricingValid: (state, getters, rootState) => {
    return Boolean(
      getters.flowValidUntil('quoteItemisedPricingValid') &&
        state.quote.erpquote.leaseTerm &&
        state.quote.erpquote.kmsPerYear &&
        (rootState.orgProfile.pricingConfiguration?.dealerDiscounts.isDealerDiscountsEnabled
          ? state.quote.erpquote.itemisedPricing.dealerDiscount !== null
          : true) &&
        (rootState.orgProfile.pricingConfiguration?.fleetDiscounts.isFleetDiscountsEnabled
          ? state.quote.erpquote.itemisedPricing.fleetDiscount !== null
          : true)
    );
  },
  quoteCustomerDetailsValid: (state, getters, rootState) => {
    return Boolean(
      getters.flowValidUntil('quoteCustomerDetailsValid') &&
        state.quote.erpquote.customer.companyName &&
        (rootState.org.selectedOrg?.erps[0].code === 'DRIVE'
          ? state.quote.erpquote.customer.companyAbn
          : rootState.auth.user.organisation.erps[0].code == 'DRIVE'
          ? state.quote.erpquote.customer.companyAbn
          : true),
      state.quote.erpquote.customer.customerName &&
        state.quote.erpquote.customer.customerContactNumber &&
        state.quote.erpquote.customer.customerContactEmail &&
        state.quote.erpquote.customer.deliveryAddressLine1 &&
        (rootState.org.selectedOrg?.erps[0].code === 'DRIVE'
          ? state.quote.erpquote.customer.deliverySuburb
          : rootState.auth.user.organisation.erps[0].code == 'DRIVE'
          ? state.quote.erpquote.customer.deliverySuburb
          : true) &&
        state.quote.erpquote.customer.deliveryState &&
        state.quote.erpquote.customer.deliveryPostcode &&
        state.quote.erpquote.customer.deliveryDate &&
        state.quote.erpquote.customer.dealership &&
        state.quote.erpquote.customer.registrationPostcode
    );
  },
  // Return the first page in the quote flow that is incomplete
  firstIncompletePage: (state, getters, rootState) => {
    const quotePageFlow = initialQuotePageFlow;

    // if (rootState.orgProfile.pricingConfiguration?.isItemisedPricingEnabled) {
    //   delete quotePageFlow.quoteCustomerDetailsValid;
    // } else {
    //   delete quotePageFlow.quoteCustomerDetailsItemisedPricingValid;
    //   delete quotePageFlow.quoteItemisedPricingValid;
    // }

    let flow = Object.keys(quotePageFlow).reverse();

    for (let i = 0; i < flow.length; i++) {
      if (getters[flow[i]]) {
        return quotePageFlow[flow[i]];
      }
    }

    return 'quoteCarDetails';
  },
  getInclusionsExclusions: (state) => {
    return {
      inclusions: state.quote.erpquote.inclusions,
      exclusions: state.quote.erpquote.exclusions
    };
  }
};
