<template>
  <QForm @submit.prevent="requestModels">
    <div>
      <div class="row">
        <div class="col-xs-12 col-md-6">
          <span class="text-weight-bold text-grey-9">Select a product*</span>
          <QSelect
            class="q-mt-md"
            color="secondary"
            filled
            dense
            clearable
            emit-value
            v-model="$v.product.$model"
            :display-value="selectedProductObj ? selectedProductObj.name : null"
            :options="products"
            :disable="isDisabled"
            option-label="name"
            option-value="id"
            :loading="$apollo.queries.products.loading"
            :error="$v.product.$error"
            @input="selectProduct"
          />
        </div>
      </div>
      <QSeparator class="bg-grey-4" />
      <div class="row q-pt-md">
        <div class="col-12">
          <span class="text-weight-bold text-grey-9"> Choose from popular makes* </span>
          <div class="q-mt-sm row q-gutter-md" v-if="popularMakes.length && !$apollo.queries.makes.loading">
            <QRadio
              class="make-logo q-pa-xs rounded-borders"
              v-for="popularMake in filteredPopularMakes"
              :key="popularMake.id"
              v-model="$v.make.$model"
              :disable="!product || isDisabled"
              :val="popularMake.id"
              color="secondary"
              @input="selectMake"
              size="0px"
            >
              <img
                class="text-grey-9 text-weight-medium"
                :src="getMakeLogo(popularMake.description)"
                :alt="` ${popularMake.description}`"
              />
            </QRadio>
          </div>

          <div class="row">
            <div class="col-xs-12 col-md-6">
              <QSelect
                class="q-mt-md"
                color="secondary"
                v-model="$v.make.$model"
                clearable
                filled
                dense
                emit-value
                :options="makes"
                option-label="description"
                option-value="id"
                :display-value="selectedMakeObj ? selectedMakeObj.description : null"
                :disable="!product || isDisabled"
                :loading="$apollo.queries.makes.loading"
                :error="$v.make.$error"
                @input="selectMake"
              />
            </div>
          </div>
        </div>
      </div>
      <QSeparator class="bg-grey-4" v-if="bodyTypes.length" />
      <div class="q-pt-md" v-if="bodyTypes.length">
        <span class="text-weight-bold text-grey-9">Select body type*</span>
        <QField class="self-start" :error="$v.bodyType.$error" borderless color="dark">
          <div class="row q-pt-sm">
            <QRadio
              v-for="bodyTypeIterate in bodyTypes"
              :key="bodyTypeIterate.id"
              v-model="$v.bodyType.$model"
              :disable="!make || isDisabled"
              color="secondary"
              :val="bodyTypeIterate.id"
              :label="bodyTypeIterate.name"
            />
          </div>
        </QField>
      </div>
    </div>

    <DriveQuoteNavigationActions :hide-back="true">
      <template slot="next">
        <QBtn type="submit" label="Search" color="secondary" :ripple="false" :disabled="$v.$invalid" />
      </template>
    </DriveQuoteNavigationActions>
  </QForm>
</template>

<script>
import { QSelect, QSeparator, QBtn, QForm, QRadio, QField } from 'eclipx.ui';
import { required, requiredIf } from 'vuelidate/lib/validators';
import DriveQuoteNavigationActions from '../DriveQuoteNavigationActions';
import { mapState, mapActions } from 'vuex';
import QueryMakes from '@/graphql/queries/QueryMakes';
import QueryProducts from '@/graphql/queries/QueryProducts';
import { GLOBAL_ERROR } from '@/components/GlobalError/GlobalError';

export default {
  name: 'DriveCarDetails',
  data() {
    return {
      product: null,
      make: null,
      bodyType: null,
      VALID_MAKES_ID_WHICH_HAS_PNG: [
        '1000024',
        '1000055',
        '1000029',
        '1000081',
        '1000086',
        '1000044',
        '1000003',
        '1000085',
        '1000010',
        '1000048',
        '1000043',
        '1000012',
        '1000030',
        '1000051',
        '1000053',
        '1000032',
        '1000041',
        '1000046',
        '1000038',
        '1000025',
        '1000031',
        '1000056',
        '1000088',
        '1000075',
        '1000020',
        '1000045',
        '1000002',
        '1000015',
        '1000076',
        '1000001',
        '1000026',
        '1000013'
      ]
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapState({ erpquote: (state) => state.quote.quote.erpquote }),
    popularMakes() {
      return this.makes ? this.makes.filter((make) => make.popular) : [];
    },
    filteredPopularMakes() {
      return this.popularMakes.filter((make) => this.VALID_MAKES_ID_WHICH_HAS_PNG.includes(make.id));
    },
    selectedProductObj() {
      return this.products && this.product ? this.products.find((product) => product.id === this.product.toString()) : null;
    },
    selectedMakeObj() {
      return this.makes && this.product ? this.makes.find((make) => make.id === this.make) : null;
    },
    bodyTypes() {
      return this.selectedMakeObj ? this.selectedMakeObj.bodyTypes : [];
    },
    isDisabled() {
      return Boolean(this.erpquote.erpQuoteRef);
    }
  },
  apollo: {
    products: {
      query: QueryProducts,
      error: function ({ graphQLErrors }) {
        const errMessage = graphQLErrors.map((err) => {
          return err.message;
        });
        /*Extract error message */
        let message = errMessage.join().replace(/Error:|{}/gi, '');
        if (message && message.startsWith('{"extensions":') && message.includes('"message"') && message.endsWith('}}}}}')) {
          message = message
            .substring(message.indexOf('"message'), message.indexOf('"}}}}}'))
            .replace(/message|:|"/gi, '')
            .trim();
        }
        if (message && message.trim().endsWith('.')) {
          message = message.slice(0, -1);
        }
        if (/:|{|}|"|\?|%/.test(message)) {
          message = '';
        }
        this.$euiNotifications.emit({
          notification: GLOBAL_ERROR,
          action: () => {
            return this.$apollo.queries.products.refresh();
          },
          message
        });
      }
    },
    makes: {
      query: QueryMakes,
      fetchPolicy: 'network-only',
      variables() {
        return {
          params: {
            productId: this.product.toString()
          }
        };
      },
      skip() {
        return !this.product;
      },
      error: function ({ graphQLErrors }) {
        const errMessage = graphQLErrors.map((err) => {
          return err.message;
        });
        /*Extract error message */
        let message = errMessage.join().replace(/Error:|{}/gi, '');
        if (message && message.startsWith('{"extensions":') && message.includes('"message"') && message.endsWith('}}}}}')) {
          message = message.substring(message.indexOf('"message'), message.indexOf('"}}}}}')).replace(/message|:|"/gi, '');
        }
        if (message && message.trim().endsWith('.')) {
          message = message.slice(0, -1);
        }
        if (/:|{|}|"|\?|%/.test(message)) {
          message = '';
        }
        this.$euiNotifications.emit({
          notification: GLOBAL_ERROR,
          action: () => {
            return this.$apollo.queries.makes.refresh();
          },
          message
        });
      }
    }
  },
  validations: {
    product: { required },
    make: { required },
    bodyType: {
      required: requiredIf(
        (component) => component.selectedMakeObj && component.selectedMakeObj.bodyTypes && component.selectedMakeObj.bodyTypes.length
      )
    }
  },
  methods: {
    /**
     * Ensure all fields are filled and if valid, save the data and request for matching models.
     */
    requestModels() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      // Store the car details
      if (!this.isDisabled) {
        this.saveCarDetails({
          product: {
            id: this.product,
            name: this.selectedProductObj.name
          },
          make: {
            id: this.make,
            name: this.selectedMakeObj.name
          },
          bodyType: { id: this.bodyType }
        });
      }

      // Go to the next page
      this.$router.push({
        name: 'quoteCarVariants',
        params: { quoteId: this.$route.params.quoteId }
      });
    },
    selectProduct() {
      this.$v.$reset();
      this.make = null;
      this.bodyType = null;
    },
    selectMake() {
      this.$v.$reset();
      this.bodyType = null;
    },
    getMakeLogo(make) {
      //filter
      return `${process.env.VUE_APP_GCS_BUCKET}/manufacturer-logos-sme/${make.toLowerCase()}.png`;
    },
    ...mapActions({ saveCarDetails: 'quote/saveCarDetails' })
  },
  created() {
    this.product = this.erpquote.product.id;
    this.make = this.erpquote.make.id;
    this.bodyType = this.erpquote.bodyType.id;
  },
  components: {
    QSelect,
    QSeparator,
    QBtn,
    QForm,
    QRadio,
    QField,
    DriveQuoteNavigationActions
  }
};
</script>

<style lang="sass">
.make-logo
  border: 2px solid transparent

  &:hover
    border-color: var(--q-color-secondary);

  &[aria-checked="true"]
    border-color: $secondary

  .q-radio__label
    display: flex

  & img
    width: 60px
</style>
