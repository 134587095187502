<template>
  <MilesCarDetails v-if="isMiles" />
  <DriveCarDetails v-else />
</template>

<script>
import { mapState } from 'vuex';
import MilesCarDetails from '@/components/MilesQuote/MilesCar/MilesCarDetails';
import DriveCarDetails from '@/components/DriveQuote/DriveCar/DriveCarDetails';
import { isMiles } from '@/utils/is-miles';

export default {
  name: 'CarDetails',
  computed: {
    ...mapState({
      selectedOrg: (state) => state.org.selectedOrg,
      user: (state) => state.auth.user
    }),
    isMiles() {
      const erpCode = this.selectedOrg?.erps[0]?.code || this.user.organisation.erps[0].code;
      return isMiles(erpCode);
    }
  },
  components: {
    MilesCarDetails,
    DriveCarDetails
  }
};
</script>
