<template>
  <div class="bg-white q-pa-lg" v-if="$store.getters['auth/whiteLabelEnabled']">
    <DialogEditor v-model="showDialogEditor" :popupValue="form.disclaimerPopup" @onSubmit="handleSubmitDisclaimer" />
    <div class="bg-white q-pa-lg">
      <div class="row">
        <div class="col-xs-12 col-sm-3 col-md-3" style="padding: 25px">
          <h5>Global Styles</h5>
          <br />
          <p>Select your company's colours and logos to reflect your brand's online experience.</p>
          <img src="/assets/images/whitelabel/calculator.png" width="100%" />
        </div>
        <div class="col-xs-12 col-sm-9 col-md-9" style="padding: 25px">
          <strong>Global Colours</strong>
          <p>Select your company's colours or enter the HEX/ RGB codes. This will be used for links and buttons.</p>
          <br />
          <div class="row" style="width: 100%">
            <ColourPicker
              caption="Primary (Dark)"
              field="primary"
              info="This colour is used for the main buttons across the platform."
              :colourProp="form.primary"
              @onColourChangeHandler="onColourChangeHandler"
            />

            <ColourPicker
              caption="Secondary (Dark)"
              field="secondary"
              info="This colour is used to focus on a secondary option such as tabs or text."
              :colourProp="form.secondary"
              @onColourChangeHandler="onColourChangeHandler"
            />

            <ColourPicker
              caption="Tertiary"
              field="tertiary"
              info="This colour is used for the background and footer."
              :colourProp="form.tertiary"
              @onColourChangeHandler="onColourChangeHandler"
            />
          </div>

          <hr style="border-top: 0.5px solid lightgray; margin: 30px 0px" />

          <strong>Header Information</strong>
          <br />

          <div class="row" style="width: 100%">
            <ColourPicker
              caption="Header Colour"
              field="headerColour"
              info="This colour is used for the main buttons across the platform."
              :colourProp="form.headerColour"
              @onColourChangeHandler="onColourChangeHandler"
            />
            <ImagePicker
              caption="Header Image"
              field="headerImage"
              info="Recommended size px<br/>File Format: PNG, JPG, GIF<br/>Max file size: 160KB"
              :disableMarginCenter="true"
              :imageProp="form.headerImage"
              @onImageChangeHandler="onImageChangeHandler"
            />
            <ImagePicker
              caption="Header Logo"
              field="headerLogo"
              info="Recommended size 310 (w) 50 (h) px<br/>File Formant: PNG, JPG, GIF<br/>Max file size: 160KB"
              :disableMarginCenter="true"
              :imageProp="form.headerLogo"
              @onImageChangeHandler="onImageChangeHandler"
            />
          </div>

          <hr style="border-top: 0.5px solid lightgray; margin: 30px 0px" />
          <div class="q-pa-xs col-12 col-sm-12">
            <template v-for="index in 4">
              <Menu :menu="form.headerLinks['menu_' + index]" @menuInput="(value) => onMenuUpdateHandler(value, index)" :key="index" />
            </template>
          </div>

          <hr style="border-top: 0.5px solid lightgray; margin: 30px 0px" />
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-3 col-md-3" style="padding: 25px">
          <h5>Footer Information</h5>
          <br />
          <p>Customise your footer with your company's information and logos.</p>
        </div>
        <div class="col-xs-12 col-sm-9 col-md-9" style="padding: 25px">
          <strong>Disclaimer Content</strong>
          <p>You can edit the text within your disclaimer content.</p>
          <br />
          <div class="row" style="width: 100%">
            <div class="q-pa-xs col-12 col-sm-12">
              <QEditor
                ref="disclaimerEditor"
                v-model.lazy="form.disclaimerText"
                placeholder="Footer text"
                min-height="200px"
                :content-style="{ backgroundColor: '#0000000d' }"
                :toolbar="[['bold', 'italic', 'underline', 'link', 'popupDialog']]"
                @input="onInputChangeHandler('disclaimerText')"
                :disable="!hasFeature('DB::ManageAdmins')"
                :definitions="{
                  popupDialog: {
                    tip: 'Show popup message',
                    label: 'popup',
                    handler: handlePopup
                  }
                }"
              />
            </div>
          </div>
          <br />
          <strong>Footer Content</strong>
          <p>You can edit the text within your footer to include privacy policy, contact details etc.</p>
          <br />
          <div class="row" style="width: 100%">
            <div class="q-pa-xs col-12 col-sm-12">
              <QEditor
                v-model.lazy="form.footerText"
                placeholder="Footer text"
                min-height="200px"
                :content-style="{ backgroundColor: '#0000000d' }"
                :toolbar="[['bold', 'italic', 'underline', 'link']]"
                @input="onInputChangeHandler('footerText')"
                :disable="!hasFeature('DB::ManageAdmins')"
              />
            </div>
          </div>
          <br />
          <div class="row" style="width: 100%">
            <ColourPicker
              caption="Footer Colour"
              field="footerColour"
              info="This colour is used for the footer colour."
              :colourProp="form.footerColour"
              @onColourChangeHandler="onColourChangeHandler"
            />
          </div>
          <hr style="border-top: 0.5px solid lightgray; margin: 30px 0px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QEditor, Icons } from 'eclipx.ui';
import { mapGetters, mapMutations } from 'vuex';
import ColourPicker from './Modules/ColourPicker';
import ImagePicker from './Modules/ImagePicker';
import Menu from '../../components/MenuField/Menu';
import DialogEditor from '@/components/WhiteLabel/DialogEditor';

export default {
  name: 'CalculatorContent',
  data() {
    return {
      form: {
        primary: '',
        secondary: '',
        tertiary: '',
        headerColour: '',
        headerLogo: '',
        headerLinks: {},
        headerImage: '',
        footerText: '',
        footerColour: '',
        disclaimerText: '',
        disclaimerPopup: ''
      },
      showDialogEditor: false,
      closeIcon: Icons.evaCloseOutline
    };
  },
  computed: {
    ...mapGetters({
      calculator: 'whiteLabel/calculator',
      whiteLabel: 'whiteLabel/whiteLabel',
      hasFeature: 'auth/hasFeature'
    })
  },
  created() {
    this.form.primary = this.calculator.primary;
    this.form.secondary = this.calculator.secondary;
    this.form.tertiary = this.calculator.tertiary;
    this.form.headerColour = this.calculator.headerColour;
    this.form.headerLogo = this.calculator.headerLogo;
    this.form.headerLinks = this.calculator.headerLinks || {};
    this.form.headerImage = this.calculator.headerImage || '';
    this.form.footerText = this.calculator.footerText || '';
    this.form.footerColour = this.calculator.footerColour;
    this.form.disclaimerText = this.calculator.disclaimerText || '';
    this.form.disclaimerPopup = this.calculator.disclaimerPopup || '';
  },
  mounted() {},
  methods: {
    async handlePopup() {
      if (this.showDialogEditor) {
        await new Promise((resolve) => {
          setTimeout(() => {
            this.showDialogEditor = false;
            resolve();
          }, 10);
        });
      }

      this.showDialogEditor = true;
    },
    handleSubmitDisclaimer(value) {
      const { popupText, popupLabel } = value;
      this.form.disclaimerPopup = popupText;
      this.onInputChangeHandler('disclaimerPopup');

      const disclaimerEditor = this.$refs.disclaimerEditor;
      disclaimerEditor.caret.restore();
      disclaimerEditor.runCmd(
        'insertHTML',
        `<span class="calculatorPopup cursor-pointer" contenteditable="false">${popupLabel}
          <i class="q-icon material-icons cursor-pointer" onclick="this.parentNode.parentNode.removeChild(this.parentNode)">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path d="${this.closeIcon}"/>
            </svg>
          </i>
        </span>`
      );

      disclaimerEditor.focus();
    },
    ...mapMutations({ updateValueStatus: 'whiteLabel/updateValueStatus' }),
    onColourChangeHandler: function (colour, field) {
      this.form[field] = colour;
      this.updateValueStatus({
        object: 'calculator',
        variable: field,
        value: colour
      });
    },
    onImageChangeHandler: function (image, field) {
      this.form[field] = image;
      this.updateValueStatus({
        object: 'calculator',
        variable: field,
        value: image
      });
    },
    onInputChangeHandler: function (field) {
      this.updateValueStatus({
        object: 'calculator',
        variable: field,
        value: this.form[field]
      });
    },
    onMenuUpdateHandler: function (value, index) {
      this.form.headerLinks['menu_' + index] = value;
      this.updateValueStatus({
        object: 'calculator',
        variable: 'headerLinks',
        value: this.form.headerLinks
      });
    }
  },
  components: {
    QEditor,
    ColourPicker,
    ImagePicker,
    Menu,
    DialogEditor
  }
};
</script>
<style lang="scss">
.calculatorPopup {
  border: 1px solid $primary;
  padding: 0 3px;
}
</style>
