<template>
  <QInput
    :class="className"
    input-class="hide-input-arrows"
    filled
    :color="color"
    dense
    clearable
    :prefix="prefix"
    v-model="inputValue"
    @focus="onFocus"
    @blur="onBlur"
    :disable="isDisabled"
    :error="hasError"
    @input="onInput"
  >
  </QInput>
</template>

<script>
import { QInput } from 'quasar';

export default {
  name: 'CurrencyInputField',
  props: {
    value: {
      type: [Number, String],
      default: 0
    },
    className: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'secondary'
    },
    prefix: {
      type: String,
      default: '$'
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    enableEdit: {
      type: Boolean,
      default: true
    },
    hasError: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inputValue: this.formatCurrency(this.value),
      isFocused: false
    };
  },
  computed: {
    formattedValue() {
      if (this.isFocused) {
        return this.inputValue == '0.00' || parseInt(this.inputValue) == 0 ? '' : this.inputValue;
      } else {
        return this.formatCurrency(this.inputValue);
      }
    }
  },
  watch: {
    value(newVal) {
      if (!this.isFocused) {
        this.inputValue = this.formatCurrency(newVal);
      }
    },
    enableEdit(value) {
      if (!value && this.inputValue == '0.00') {
        this.inputValue = '';
      } else if (value && this.inputValue == '') {
        this.inputValue = '0.00';
      }
    }
  },
  methods: {
    formatCurrency(value) {
      const getDecimalCount = (value) => {
        if (!value) {
          return 0;
        }

        if (Math.floor(value) === value) {
          return 0;
        }

        if (value) {
          if (typeof value === 'string') {
            const [, decimal] = value.split('.');
            return decimal ? decimal.length : 0;
          } else {
            return value.toString().split('.')[1].length;
          }
        }

        return 0;
      };

      const options = {
        symbol: '',
        thousandsSeparator: ',',
        fractionCount: Math.max(2, getDecimalCount(value)),
        fractionSeparator: '.',
        symbolPosition: '',
        symbolSpacing: false
      };

      if (value == '') {
        return this.$options.filters.currency(0, options);
      }

      return this.$options.filters.currency(value, options);
    },
    onFocus() {
      this.isFocused = true;

      if (!parseInt(this.inputValue)) {
        this.inputValue = '';
      }

      this.inputValue = this.inputValue.toString().replace(/,/g, '');
      this.$emit('input', this.inputValue);
    },
    onBlur() {
      this.isFocused = false;

      this.inputValue = this.formatCurrency(this.inputValue);
      this.$emit('input', this.inputValue);
    },
    onInput() {
      this.$emit('input', this.inputValue);
    }
  },
  components: {
    QInput
  }
};
</script>
