import gql from 'graphql-tag';
export default gql`
  query getOrgByAppCode($params: OrgQuery, $hasOrgProfile: Boolean) {
    getOrgByAppCode(params: $params, hasOrgProfile: $hasOrgProfile) {
      count
      rows {
        id
        name
        abn
        isActive
        salesForceAccountId
        erps {
          code
        }
        pricingConfiguration {
          isItemisedPricingEnabled
          isRRPEnabled
          isDriveawayPriceEnabled
          calculator {
            isCalculatorEnabled
          }
        }
      }
    }
  }
`;
