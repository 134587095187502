export const TERMS_PRIVACY_LINKS = {
  default: {
    terms: 'https://www.lipsum.com/feed/html',
    policy: 'https://www.lipsum.com/feed/html'
  },
  au: {
    terms: `${process.env.BASE_URL}assets/legal/TermsAndConditions.pdf`,
    policy: 'http://eclipxgroup.com/privacy-policy'
  },
  nz: {
    terms: 'https://www.lipsum.com/feed/html',
    policy: 'https://www.lipsum.com/feed/html'
  }
};

export const STATE_OPTIONS = ['ACT', 'NSW', 'NT', 'VIC', 'QLD', 'SA', 'TAS', 'WA'];
