<template>
  <main class="q-page column q-pa-lg">
    <h6 class="q-mt-none q-mb-md text-weight-bold text-grey-9">Edit Organisation</h6>
    <OrganisationTabs :orgId="routeId" />
    <div
      v-if="this.$apollo.queries.manufacturers.loading || this.$apollo.queries.getOrgProfileDetails.loading"
      class="flex justify-center q-pa-md"
    >
      <QSpinner size="md" color="grey-7" />
    </div>
    <div v-else class="row q-gutter-md">
      <div class="col q-pa-lg self-start q-card q-card--bordered">
        <QForm @submit="submit">
          <div class="row q-mt-none q-mb-md">
            <h6 class="col-12 text-grey-8 q-mb-xs">Pricing Configuration</h6>
            <p class="col-12 text-grey-8">
              You may choose to enable quote pricing OR itemised pricing. Please note both options cannot be at the same time
            </p>
          </div>
          <FormError
            :show="
              $v.$error && $v.$dirty && !($v.isRRPEnabled.$error || $v.isItemisedPricingEnabled.$error || $v.isDriveawayPriceEnabled.$error)
            "
            class="col-12 q-mt-md"
          />

          <div
            v-if="$v.isRRPEnabled.$error || $v.isItemisedPricingEnabled.$error || $v.isDriveawayPriceEnabled.$error"
            class="text-red text-body3"
          >
            At lease one pricing option must be selected.
          </div>

          <div class="row q-mt-lg q-mb-lg">
            <h6 class="q-mt-none q-mb-md text-grey-8 text-subtitle2" style="font-size: 1rem; font-weight: 600">Quote Pricing</h6>
            <div class="col-12">
              <QToggle
                :class="
                  $v.isRRPEnabled.$error || $v.isItemisedPricingEnabled.$error || $v.isDriveawayPriceEnabled.$error
                    ? 'text-red'
                    : 'text-grey-8'
                "
                v-model="$v.isRRPEnabled.$model"
                size="lg"
                color="secondary"
                label="Estimated Driveaway Price"
              />
              <QIcon color="grey-7" class="q-ml-sm" :name="informationIcon" size="xs">
                <QTooltip :offset="[0, 10]" max-width="280px" anchor="bottom right" self="top left"
                  >The estimated Driveaway Price uses the RRP of the vehicle & does not include additional vehicle accessories. It can be
                  used if you don't have a Dealer quote & would like to quickly calculate a lease quote estimate.</QTooltip
                >
              </QIcon>
            </div>
            <div class="col-12">
              <QToggle
                :class="
                  $v.isRRPEnabled.$error || $v.isItemisedPricingEnabled.$error || $v.isDriveawayPriceEnabled.$error
                    ? 'text-red'
                    : 'text-grey-8'
                "
                v-model="$v.isDriveawayPriceEnabled.$model"
                size="lg"
                color="secondary"
                label="Dealer Driveaway Price"
              />
              <QIcon color="grey-7" class="q-ml-sm" :name="informationIcon" size="xs">
                <QTooltip :offset="[0, 10]" max-width="280px" anchor="bottom right" self="top left"
                  >If you have a Dealer quote please enter the total Driveaway Price including discounts, fees, on roads & GST. This is the
                  total amount payable to the Dealer.</QTooltip
                >
              </QIcon>
            </div>
          </div>
          <QSeparator class="q-my-md" v-if="showItemisedPricing" />
          <div v-if="showItemisedPricing" class="row q-mt-md q-mb-xl">
            <h6 class="col-12 q-mt-lg text-grey-8 text-subtitle2" style="font-size: 1rem; font-weight: 600">Itemised Pricing</h6>
            <QToggle
              :class="`col-12 q-mt-lg q-mb-lg ${
                $v.isRRPEnabled.$error || $v.isItemisedPricingEnabled.$error || $v.isDriveawayPriceEnabled.$error
                  ? 'text-red'
                  : 'text-grey-8'
              }`"
              v-model="$v.isItemisedPricingEnabled.$model"
              size="lg"
              color="secondary"
              label="Itemised Pricing"
            />
            <h6
              :class="`q-mt-none q-mb-md text-subtitle2 ${isItemisedPricingEnabled ? 'text-grey-8' : 'text-grey-6'}`"
              style="font-size: 0.75rem"
            >
              Activate the visibility of Commission & Dealers in the itimised pricing quote. These can also be removed from certain
              manufacturers by removing from drop down lists below.
            </h6>
            <QToggle
              class="col-12 text-grey-8"
              v-model="isCommissionsEnabled"
              size="lg"
              color="secondary"
              label="Commission"
              :disable="!isItemisedPricingEnabled"
            />
            <QToggle
              class="col-12 text-grey-8"
              v-model="isFleetDiscountsEnabled"
              size="lg"
              color="secondary"
              label="Fleet Discount"
              :disable="!isItemisedPricingEnabled"
            />
            <QSelect
              class="col-12 col-md-4 col-lg-3 col-xl-2 text-grey-8"
              dense
              filled
              v-model="fleetDiscountsManufacturers"
              multiple
              :options="formatManufacturers"
              :display-value="
                fleetDiscountsManufacturers.length == formatManufacturers.length
                  ? 'All Manufacturers Selected'
                  : fleetDiscountsManufacturers.map((value) => value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()).join(', ')
              "
              color="secondary"
              :label="!fleetDiscountsManufacturers.length ? 'Select Manufacturers' : void 0"
              :disable="!isFleetDiscountsEnabled"
              :loading="this.$apollo.queries.manufacturers.loading"
              :error="$v.fleetDiscountsManufacturers.$error"
            >
              <template #before-options>
                <div class="row">
                  <QItem class="col-12 items-center">
                    <QCheckbox
                      color="secondary"
                      style="width: 100%"
                      v-model="selectAllFleetDiscountsManufacturers"
                      @input="(value) => toggleSelectAll('fleetDiscountsManufacturers', formatManufacturers, value)"
                    >
                      <QItemLabel> {{ selectAllFleetDiscountsManufacturers ? 'Unselect All' : 'Select All' }} </QItemLabel>
                    </QCheckbox>
                  </QItem>
                  <QSeparator style="height: 1px !important" class="q-my-xs col-12" />
                </div>
              </template>
              <template v-slot:option="{ itemProps, itemEvents, opt, selected, toggleOption }">
                <QItem class="items-center" v-bind="itemProps" v-on="itemEvents">
                  <QCheckbox color="secondary" :value="selected" @input="toggleOption(opt)" />
                  <QItemLabel>
                    {{ opt.charAt(0).toUpperCase() + opt.slice(1).toLowerCase() }}
                  </QItemLabel>
                </QItem>
              </template>
            </QSelect>
            <QToggle
              class="col-12 text-grey-8"
              v-model="isDealerDiscountsEnabled"
              size="lg"
              color="secondary"
              label="Dealer Discount"
              :disable="!isItemisedPricingEnabled"
            />
            <QSelect
              class="col-12 col-md-4 col-lg-3 col-xl-2 text-grey-8"
              dense
              filled
              v-model="dealerDiscountsManufacturers"
              multiple
              :options="formatManufacturers"
              :display-value="
                dealerDiscountsManufacturers.length == formatManufacturers.length
                  ? 'All Manufacturers Selected'
                  : dealerDiscountsManufacturers.map((value) => value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()).join(', ')
              "
              color="secondary"
              :label="!dealerDiscountsManufacturers.length ? 'Select Manufacturers' : void 0"
              :disable="!isDealerDiscountsEnabled"
              :loading="this.$apollo.queries.manufacturers.loading"
              :error="$v.dealerDiscountsManufacturers.$error"
            >
              <template #before-options>
                <div class="row">
                  <QItem class="col-12 items-center">
                    <QCheckbox
                      color="secondary"
                      style="width: 100%"
                      v-model="selectAllDealerDiscountsManufacturers"
                      @input="(value) => toggleSelectAll('dealerDiscountsManufacturers', formatManufacturers, value)"
                    >
                      <QItemLabel> {{ selectAllDealerDiscountsManufacturers ? 'Unselect All' : 'Select All' }} </QItemLabel>
                    </QCheckbox>
                  </QItem>
                  <QSeparator style="height: 1px !important" class="q-my-xs col-12" />
                </div>
              </template>
              <template v-slot:option="{ itemProps, itemEvents, opt, selected, toggleOption }">
                <QItem class="items-center" v-bind="itemProps" v-on="itemEvents">
                  <QCheckbox color="secondary" :value="selected" @input="toggleOption(opt)" />
                  <QItemLabel>
                    {{ opt.charAt(0).toUpperCase() + opt.slice(1).toLowerCase() }}
                  </QItemLabel>
                </QItem>
              </template>
            </QSelect>
            <QToggle
              class="col-12 text-grey-8"
              v-model="isItemisedDriveawayPriceEnabled"
              size="lg"
              color="secondary"
              label="Itemised Driveaway Price"
              :disable="!isItemisedPricingEnabled"
            />
          </div>
          <QSeparator class="q-my-md" />
          <div class="row q-mt-md q-mb-xl">
            <QToggle
              class="col-12 text-grey-8"
              v-model="isCalculatorEnabled"
              size="lg"
              color="secondary"
              label="Calculator"
              :disable="!isPublicCalculatorEnabled"
            />
            <QSelect
              class="col-12 col-md-4 col-lg-3 col-xl-2 text-grey-8"
              dense
              filled
              v-model="calculatorManufacturers"
              multiple
              :options="formatManufacturers"
              :display-value="
                calculatorManufacturers.length == formatManufacturers.length
                  ? 'All Manufacturers Selected'
                  : calculatorManufacturers.map((value) => value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()).join(', ')
              "
              color="secondary"
              :label="!calculatorManufacturers.length ? 'Select Manufacturers' : void 0"
              :disable="!isCalculatorEnabled"
              :loading="this.$apollo.queries.manufacturers.loading"
              :error="$v.calculatorManufacturers.$error"
            >
              <template #before-options>
                <div class="row">
                  <QItem class="col-12 items-center">
                    <QCheckbox
                      color="secondary"
                      style="width: 100%"
                      v-model="selectAllCalculatorManufacturers"
                      @input="(value) => toggleSelectAll('calculatorManufacturers', formatManufacturers, value)"
                    >
                      <QItemLabel> {{ selectAllCalculatorManufacturers ? 'Unselect All' : 'Select All' }} </QItemLabel>
                    </QCheckbox>
                  </QItem>
                  <QSeparator style="height: 1px !important" class="q-my-xs col-12" />
                </div>
              </template>
              <template v-slot:option="{ itemProps, itemEvents, opt, selected, toggleOption }">
                <QItem class="items-center" v-bind="itemProps" v-on="itemEvents">
                  <QCheckbox color="secondary" :value="selected" @input="toggleOption(opt)" />
                  <QItemLabel>
                    {{ opt.charAt(0).toUpperCase() + opt.slice(1).toLowerCase() }}
                  </QItemLabel>
                </QItem>
              </template>
            </QSelect>
          </div>
          <QSeparator class="q-my-md col-12" />
          <div class="q-mt-lg flex">
            <QBtn class="q-mr-sm" :label="'Cancel'" color="secondary" :disable="isSaving" @click="cancel" />
            <QBtn
              type="submit"
              class="q-mr-sm"
              :label="isSaved ? 'Saved' : 'Save'"
              color="primary"
              :loading="isSaving"
              :disable="this.$apollo.queries.manufacturers.loading || disableSaveButton"
            />
          </div>
        </QForm>
      </div>
    </div>
  </main>
</template>

<script>
import { Icons, QBtn, QForm, QIcon, QSelect, QSpinner, QToggle, QCheckbox, QSeparator, QItem, QItemLabel, QTooltip } from 'eclipx.ui';
import { requiredIf } from 'vuelidate/lib/validators';
import FormError from '../../components/FormError/FormError';
import { GLOBAL_ERROR } from '@/components/GlobalError/GlobalError';
import QueryGetOrgProfileDetails from '@/graphql/queries/QueryGetOrgProfileDetails';
import SavePricingConfiguration from '@/graphql/mutations/SavePricingConfiguration';
import { mapState } from 'vuex';
import OrganisationTabs from './OrganisationTabs';
import QueryManufacturers from '@/graphql/queries/QueryManufacturers';

export default {
  name: 'PricingConfiguration',
  data() {
    return {
      manufacturers: [],
      getOrgById: {},
      isRRPEnabled: false,
      isDriveawayPriceEnabled: false,
      isItemisedPricingEnabled: false,
      isCommissionsEnabled: false,
      isDealerDiscountsEnabled: false,
      dealerDiscountsManufacturers: [],
      isFleetDiscountsEnabled: false,
      isItemisedDriveawayPriceEnabled: false,
      fleetDiscountsManufacturers: [],
      selectAllDealerDiscountsManufacturers: false,
      selectAllFleetDiscountsManufacturers: false,
      isCalculatorEnabled: false,
      calculatorManufacturers: [],
      selectAllCalculatorManufacturers: false,
      isSaving: false,
      hasFetchedProfileDetails: false,
      disableSaveButton: false,
      isSaved: false,
      isPublicCalculatorEnabled: false
    };
  },
  created() {
    this.informationIcon = Icons.evaInfoOutline;
    this.handleDisableSaveButton();
  },
  computed: {
    ...mapState({
      user: (state) => {
        return state.auth.user;
      }
    }),
    routeId() {
      return this.$route.params.orgId;
    },
    erpCode() {
      if (!this.selected) {
        return this.user.organisation.erps[0].code;
      }

      const { erps } = this.selected;
      const { code } = erps[0];

      return code;
    },
    showItemisedPricing() {
      return this.erpCode === 'MILESNZ' || (this.isDBEclipxAdmin && !this.selected);
    },
    formatManufacturers() {
      return this.manufacturers?.map(({ name }) => name);
    },
    shouldFetchOrgProfileDetails() {
      // Return true only when manufacturers query has completed and data is available
      return !this.$apollo.queries.manufacturers.loading && this.manufacturers.length > 0;
    },
    hasRole() {
      return this.$store.getters['auth/hasRole'];
    },
    isDBEclipxAdmin() {
      return this.hasRole('DB Eclipx Admin') || this.hasRole('DB Eclipx User');
    }
  },
  apollo: {
    manufacturers: {
      query: QueryManufacturers,
      fetchPolicy: 'network-only',
      variables() {
        if (this.isDBEclipxAdmin) {
          return {
            orgId: this.$route.params.orgId
          };
        }
      },
      error(error) {
        if (error) {
          this.$apollo.queries.getOrgProfileDetails.skip = false;
          // eslint-disable-next-line
          console.log(error);
          this.$euiNotifications.emit({
            notification: GLOBAL_ERROR,
            action: () => {
              return this.$apollo.queries.manufacturers.refresh();
            }
          });
        }
      }
    },
    getOrgProfileDetails: {
      query: QueryGetOrgProfileDetails,
      fetchPolicy: 'network-only',
      variables() {
        if (this.isDBEclipxAdmin) {
          return {
            orgId: this.$route.params.orgId
          };
        }
      },
      skip() {
        return !this.shouldFetchOrgProfileDetails;
      },
      result({ data }) {
        if (!this.hasFetchedProfileDetails) {
          const {
            pricingConfiguration: {
              isRRPEnabled = false,
              isDriveawayPriceEnabled = false,
              isItemisedPricingEnabled = false,
              commissions = { isCommissionsEnabled: false },
              dealerDiscounts = { isDealerDiscountsEnabled: false, manufacturersEnabled: [] },
              fleetDiscounts = { isFleetDiscountsEnabled: false, manufacturersEnabled: [] },
              isItemisedDriveawayPriceEnabled = false,
              calculator = { isCalculatorEnabled: false, manufacturersEnabled: [] }
            } = {},
            whiteLabel: { isPublicCalculatorEnabled = false } = {}
          } = data.getOrgProfileDetails;

          this.isPublicCalculatorEnabled = isPublicCalculatorEnabled;

          this.isRRPEnabled = isRRPEnabled;
          this.isDriveawayPriceEnabled = isDriveawayPriceEnabled;

          this.isItemisedPricingEnabled = isItemisedPricingEnabled;

          this.isCommissionsEnabled = commissions.isCommissionsEnabled;
          this.isDealerDiscountsEnabled = dealerDiscounts.isDealerDiscountsEnabled;
          this.isFleetDiscountsEnabled = fleetDiscounts.isFleetDiscountsEnabled;
          this.isCalculatorEnabled = calculator?.isCalculatorEnabled;

          this.dealerDiscountsManufacturers = dealerDiscounts.manufacturersEnabled;
          this.fleetDiscountsManufacturers = fleetDiscounts.manufacturersEnabled;
          this.calculatorManufacturers = calculator?.manufacturersEnabled;

          this.selectAllDealerDiscountsManufacturers = dealerDiscounts?.manufacturersEnabled?.length === this.formatManufacturers?.length;
          this.selectAllFleetDiscountsManufacturers = fleetDiscounts?.manufacturersEnabled?.length === this.formatManufacturers?.length;
          this.selectAllCalculatorManufacturers = calculator?.manufacturersEnabled?.length === this.formatManufacturers?.length;

          this.isItemisedDriveawayPriceEnabled = isItemisedDriveawayPriceEnabled;
          this.$nextTick(() => {
            this.hasFetchedProfileDetails = true;
          });
        }
      },
      error(error) {
        if (error) {
          // eslint-disable-next-line
          console.log(error);
          this.$euiNotifications.emit({
            notification: GLOBAL_ERROR,
            action: () => {
              return this.$apollo.queries.getOrgProfileDetails.refresh();
            }
          });
        }
      }
    }
  },
  watch: {
    isSaved(value) {
      if (value) {
        setTimeout(() => {
          this.isSaved = false;
        }, 2000);
      }
    },
    isItemisedPricingEnabled(value) {
      this.handleDisableSaveButton();
      if (!this.hasFetchedProfileDetails) {
        return;
      }

      if (value) {
        this.isRRPEnabled = false;
        this.isDriveawayPriceEnabled = false;
      }

      this.handleItemisedPricingOptions();
    },
    isRRPEnabled(value) {
      this.handleDisableSaveButton();
      if (!this.hasFetchedProfileDetails) {
        return;
      }

      if (value) {
        this.isItemisedPricingEnabled = false;
        this.handleItemisedPricingOptions();
      }
    },
    isDriveawayPriceEnabled(value) {
      this.handleDisableSaveButton();
      if (!this.hasFetchedProfileDetails) {
        return;
      }

      if (value) {
        this.isItemisedPricingEnabled = false;
        this.handleItemisedPricingOptions();
      }
    },
    isDealerDiscountsEnabled(value) {
      if (!this.hasFetchedProfileDetails) {
        return;
      }

      if (value) {
        this.dealerDiscountsManufacturers = this.formatManufacturers;
      } else {
        this.dealerDiscountsManufacturers = [];
      }
    },
    isFleetDiscountsEnabled(value) {
      if (!this.hasFetchedProfileDetails) {
        return;
      }

      if (value) {
        this.fleetDiscountsManufacturers = this.formatManufacturers;
        this.isItemisedDriveawayPriceEnabled = true;
      } else {
        this.fleetDiscountsManufacturers = [];
        this.isItemisedDriveawayPriceEnabled = false;
      }
    },
    isCalculatorEnabled(value) {
      if (!this.hasFetchedProfileDetails) {
        return;
      }

      if (value) {
        this.calculatorManufacturers = this.formatManufacturers;
      } else {
        this.calculatorManufacturers = [];
      }
    },
    dealerDiscountsManufacturers(value) {
      if (!this.hasFetchedProfileDetails) {
        return;
      }

      this.selectAllDealerDiscountsManufacturers = value.length && value.length === this.formatManufacturers.length;
    },
    fleetDiscountsManufacturers(value) {
      if (!this.hasFetchedProfileDetails) {
        return;
      }

      this.selectAllFleetDiscountsManufacturers = value.length && value.length === this.formatManufacturers.length;
    },
    calculatorManufacturers(value) {
      if (!this.hasFetchedProfileDetails) {
        return;
      }

      this.selectAllFleetDiscountsManufacturers = value.length && value.length === this.formatManufacturers.length;
    }
  },
  validations() {
    return {
      isRRPEnabled: {
        selectedOnePricingOption: () => this.hasSelectedOnePricingOption()
      },
      isDriveawayPriceEnabled: {
        selectedOnePricingOption: () => this.hasSelectedOnePricingOption()
      },
      isItemisedPricingEnabled: {
        selectedOnePricingOption: () => this.hasSelectedOnePricingOption()
      },
      fleetDiscountsManufacturers: {
        required: requiredIf((component) => component.isFleetDiscountsEnabled)
      },
      dealerDiscountsManufacturers: {
        required: requiredIf((component) => component.isDealerDiscountsEnabled)
      },
      calculatorManufacturers: {
        required: requiredIf((component) => component.isCalculatorEnabled)
      }
    };
  },
  methods: {
    hasSelectedOnePricingOption: function () {
      return this.isRRPEnabled || this.isDriveawayPriceEnabled || this.isItemisedPricingEnabled;
    },
    handleDisableSaveButton: function () {
      this.disableSaveButton = !this.hasSelectedOnePricingOption();
    },
    handleItemisedPricingOptions: function () {
      if (this.isItemisedPricingEnabled) {
        this.isCommissionsEnabled = true;
        this.isDealerDiscountsEnabled = true;
        this.isFleetDiscountsEnabled = true;
        this.selectAllDealerDiscountsManufacturers = true;
        this.selectAllFleetDiscountsManufacturers = true;
        this.isItemisedDriveawayPriceEnabled = true;
        this.dealerDiscountsManufacturers = this.formatManufacturers;
        this.fleetDiscountsManufacturers = this.formatManufacturers;
      } else {
        this.isCommissionsEnabled = false;
        this.isDealerDiscountsEnabled = false;
        this.isFleetDiscountsEnabled = false;
        this.isItemisedDriveawayPriceEnabled = false;
        this.selectAllDealerDiscountsManufacturers = false;
        this.selectAllFleetDiscountsManufacturers = false;
        this.dealerDiscountsManufacturers = [];
        this.fleetDiscountsManufacturers = [];
      }
    },
    toggleSelectAll(model, options, value) {
      if (value) {
        this[model] = [...options];
      } else {
        this[model] = [];
      }
    },
    submit: async function () {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.isSaving = true;
      try {
        await this.$apollo.mutate({
          mutation: SavePricingConfiguration,
          variables: {
            orgId: this.$route.params.orgId,
            params: {
              isRRPEnabled: this.isRRPEnabled,
              isDriveawayPriceEnabled: this.isDriveawayPriceEnabled,
              isItemisedPricingEnabled: this.isItemisedPricingEnabled,
              commissions: {
                isCommissionsEnabled: this.isItemisedPricingEnabled ? this.isCommissionsEnabled : false
              },
              dealerDiscounts: {
                isDealerDiscountsEnabled: this.isItemisedPricingEnabled ? this.isDealerDiscountsEnabled : false,
                manufacturersEnabled:
                  this.isItemisedPricingEnabled && this.isDealerDiscountsEnabled ? this.dealerDiscountsManufacturers : []
              },
              fleetDiscounts: {
                isFleetDiscountsEnabled: this.isItemisedPricingEnabled ? this.isFleetDiscountsEnabled : false,
                manufacturersEnabled: this.isItemisedPricingEnabled && this.isFleetDiscountsEnabled ? this.fleetDiscountsManufacturers : []
              },
              calculator: {
                isCalculatorEnabled: this.isCalculatorEnabled ? this.isCalculatorEnabled : false,
                manufacturersEnabled: this.calculatorManufacturers || []
              },
              isItemisedDriveawayPriceEnabled: this.isItemisedDriveawayPriceEnabled
            }
          }
        });

        this.isSaved = true;
      } catch (error) {
        let gqlError = error.graphQLErrors[0];
        this.error = gqlError ? gqlError.message : 'Error';
      } finally {
        this.isSaving = false;
      }
    },
    cancel: function () {
      this.$router.push({ name: 'organisations' });
    }
  },
  components: {
    OrganisationTabs,
    QBtn,
    QForm,
    QSpinner,
    QToggle,
    QIcon,
    QSelect,
    QItem,
    QCheckbox,
    QItemLabel,
    QSeparator,
    FormError,
    QTooltip
  }
};
</script>
